import React from "react"
import {ChevronDownIcon} from "@heroicons/react/16/solid"
import {twMerge} from "tailwind-merge"

import {IconButton} from "../../../Button.tsx"
import {DropdownContext} from "../context.ts"

export const DefaultCaret: React.FC = () => {
  const {
    isOpen,
    classNames: {caret: className},
  } = DropdownContext.useContext()

  return (
    <IconButton noEdges tabIndex={-1} className={className}>
      <ChevronDownIcon className={twMerge("size-5 transition-all", isOpen && "rotate-180")} />
    </IconButton>
  )
}
