import React from "react"
import {FieldPath, FieldValues, useFormContext} from "react-hook-form"
import {UseFormRegisterReturn} from "react-hook-form/dist/types/form"
import {twMerge} from "tailwind-merge"

import CheckmarkIcon from "../../assets/checkmarkCurrentColor.svg?react"
import {pickProps} from "../../utils/types"
import {TBaseSharedProps, TConnectedField, useGetFieldVisibleError} from "./components"
import {FieldLabel, pickFieldLabelProps, TFieldLabelProps} from "./FieldLabel"

type TCheckboxRenderComponentProps = Omit<TCheckboxSharedProps, "toggle"> & {checked?: boolean | null}

type TCheckboxSharedProps = TBaseSharedProps & {
  toggle?: boolean
  children?: React.ReactNode
}

type TCheckboxBaseProps = TCheckboxSharedProps &
  Partial<Omit<UseFormRegisterReturn, "onChange">> & {
    onChange: React.ChangeEventHandler<HTMLInputElement>
    checked?: boolean | null
  }

type TCheckboxConnectedProps<T extends FieldValues, N extends FieldPath<T>> = TCheckboxSharedProps &
  TConnectedField<T, N>
type TCheckboxFieldProps<T extends FieldValues, N extends FieldPath<T>> = TFieldLabelProps &
  TCheckboxConnectedProps<T, N>

export const CheckboxBase = React.forwardRef<HTMLInputElement, TCheckboxBaseProps>(
  ({toggle, checked, onChange, readOnly, disabled, hasError, children, ...props}, ref) => {
    const RenderComponent = toggle ? ToggleRenderComponent : CheckboxRenderComponent

    const handleChange = React.useCallback<typeof onChange>(
      e => {
        if (readOnly || disabled) {
          return
        }

        onChange(e)
      },
      [disabled, onChange, readOnly]
    )

    return (
      <label
        className={twMerge([
          "group/checkbox relative cursor-pointer",
          readOnly && "cursor-default",
          disabled && "cursor-not-allowed",
        ])}
      >
        <input
          type={"checkbox"}
          className={"peer sr-only"}
          checked={!!checked}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={disabled}
          id={props.name}
          {...props}
          ref={ref}
        />
        <RenderComponent checked={checked} disabled={disabled} hasError={hasError} readOnly={readOnly}>
          {children}
        </RenderComponent>
      </label>
    )
  }
)
CheckboxBase.displayName = "CheckboxBase"

export const ToggleRenderComponent: React.FC<TCheckboxRenderComponentProps> = ({
  checked,
  hasError,
  disabled,
  children,
}) => {
  return (
    <div className={"flex select-none items-center gap-3"}>
      <div
        className={twMerge([
          "relative flex transition-all ease-in",
          "aspect-[2/1] h-5 rounded-full",
          "ring-transparent ring-offset-2 group-focus-within/checkbox:ring-2 group-focus-within/checkbox:ring-cr-blue",
          "border-2",
          checked ? "border-cr-blue bg-cr-blue" : "border-cr-grey-15 bg-cr-grey-15",
          hasError && "ring-1 ring-cr-red group-focus-within/checkbox:ring-cr-red",
          disabled && "contrast-50 grayscale",
        ])}
      >
        <div className={twMerge(["transition-all ease-in", checked && "grow"])}></div>
        <div className={twMerge(["aspect-square h-full rounded-full bg-cr-white shadow-md"])} />
      </div>
      <div className={"text-sm text-cr-grey-80"}>{children}</div>
    </div>
  )
}

export const CheckboxRenderComponent: React.FC<TCheckboxRenderComponentProps> = ({
  checked,
  hasError,
  disabled,
  children,
}) => {
  return (
    <div className={"flex select-none items-start gap-3"}>
      <div
        className={twMerge([
          "relative flex select-none transition-all",
          "aspect-square h-4 rounded",
          "ring-transparent ring-offset-2 group-focus-within/checkbox:ring-2 group-focus-within/checkbox:ring-cr-blue",
          "border",
          checked ? "border-cr-blue bg-cr-blue" : "border-cr-grey-15 bg-cr-white",
          hasError && "ring-1 ring-cr-red group-focus-within/checkbox:ring-cr-red",
          disabled && "contrast-50 grayscale",
        ])}
      >
        <CheckmarkIcon
          className={twMerge([
            "flex aspect-square h-full items-center justify-center p-0.5 text-xs text-cr-white transition-all",
            checked ? "scale-100" : "scale-0",
          ])}
        />
      </div>
      {children && <div className={"text-sm text-cr-grey-80"}>{children}</div>}
    </div>
  )
}

export function CheckboxConnected<T extends FieldValues, N extends FieldPath<T>>({
  name,
  options,
  ...props
}: TCheckboxConnectedProps<T, N>) {
  const {register, watch} = useFormContext<T>()
  const hasError = !!useGetFieldVisibleError(name)
  const checked = watch(name)

  return <CheckboxBase {...register(name, options)} hasError={hasError} {...props} checked={!!checked} />
}

export function CheckboxField<T extends FieldValues, N extends FieldPath<T>>(props: TCheckboxFieldProps<T, N>) {
  const fieldLabelProps = pickFieldLabelProps(props)
  const toggleConnectedProps = pickCheckboxConnectedProps(props)

  return (
    <FieldLabel {...fieldLabelProps}>
      <CheckboxConnected {...toggleConnectedProps} />
    </FieldLabel>
  )
}

function pickCheckboxConnectedProps<T extends FieldValues, N extends FieldPath<T>>(
  props: React.ComponentProps<typeof CheckboxField<T, N>>
) {
  return pickProps<TCheckboxConnectedProps<T, N>>({
    name: true,
    hasError: true,
    options: true,
    readOnly: true,
    disabled: true,
    toggle: true,
    children: true,
  })(props)
}
