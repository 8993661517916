import React from "react"
import {useTranslation} from "react-i18next"

import {InputConnected} from "../../../components/fields/Input"
import {TextareaConnected} from "../../../components/fields/Textarea"
import {FlyoutGrid, FlyoutSectionRow} from "../../../components/Flyout"
import {DropdownConnected} from "../../../components/formElements/Dropdown/Dropdown.tsx"
import {TOption} from "../../../components/formElements/Dropdown/types"
import {ASalesCycleSegment} from "../../../services/types.generated"
import {useCountryOptions} from "../../../utils/hooks.tsx"
import {enumToOptions} from "../../../utils/i18n"
import {DataRow} from "../../Prospects/shared/EditingFlyout.tsx"
import {TTabComponentProps} from "./EditingFlyout"

export const LeadInfo: React.FC<TTabComponentProps> = ({lead}) => {
  const {t} = useTranslation()
  const countryOptions = useCountryOptions()

  const segmentOptions = React.useMemo(() => {
    const unknownSegments =
      lead?.segment?.filter(segment => !(Object.values(ASalesCycleSegment) as string[]).includes(segment)) ?? []

    const unknownSegmentOptions: Array<TOption<string>> = unknownSegments.map(segment => ({
      value: segment,
      title: segment,
    }))

    return [...enumToOptions("Segment"), ...unknownSegmentOptions]
  }, [lead?.segment])

  return (
    <FlyoutGrid>
      <FlyoutSectionRow>{t("Leads_EditingFlyout_Company")}</FlyoutSectionRow>

      <DataRow legend={t("Leads_EditingFlyout_Name")} name={"organization_name"}>
        <InputConnected name={"organization_name"} ghost={"hover"} />
      </DataRow>
      <DataRow legend={t("Leads_EditingFlyout_CompanyID")} name={"organization_number"}>
        <InputConnected name={"organization_number"} ghost={"hover"} />
      </DataRow>
      <DataRow legend={t("Leads_Table_Segment")} name={"segment"}>
        <DropdownConnected
          options={segmentOptions}
          name={"segment"}
          multiple
          mainButtonClassName={"input-border-ghost-hover"}
        />
      </DataRow>
      <DataRow legend={t("Leads_Table_City")} name={"city"}>
        <InputConnected name={"city"} ghost={"hover"} />
      </DataRow>
      <DataRow legend={t("Leads_EditingFlyout_Country")} name={"country"}>
        <DropdownConnected
          name={"country.id"}
          options={countryOptions}
          mainButtonClassName={"input-border-ghost-hover"}
        />
      </DataRow>
      <DataRow legend={t("Leads_EditingFlyout_Website")} name={"website"}>
        <InputConnected name={"website"} ghost={"hover"} />
      </DataRow>
      <DataRow legend={t("Leads_Table_Phone")} name={"organization_phone_number"}>
        <InputConnected name={"organization_phone_number"} ghost={"hover"} />
      </DataRow>

      <FlyoutSectionRow>{t("Leads_EditingFlyout_MainContact")}</FlyoutSectionRow>

      <DataRow legend={t("Leads_EditingFlyout_FirstName")} name={"contact_person_first_name"}>
        <InputConnected name={"contact_person_first_name"} ghost={"hover"} />
      </DataRow>
      <DataRow legend={t("Leads_EditingFlyout_LastName")} name={"contact_person_last_name"}>
        <InputConnected name={"contact_person_last_name"} ghost={"hover"} />
      </DataRow>
      <DataRow legend={t("Leads_Table_Position")} name={"position"}>
        <InputConnected name={"position"} ghost={"hover"} />
      </DataRow>
      <DataRow legend={t("Leads_Table_Phone")} name={"phone_number"}>
        <InputConnected name={"phone_number"} ghost={"hover"} />
      </DataRow>
      <DataRow legend={t("Leads_Table_Email")} name={"email"}>
        <InputConnected name={"email"} ghost={"hover"} />
      </DataRow>

      <FlyoutSectionRow>{t("Leads_EditingFlyout_Other")}</FlyoutSectionRow>

      <DataRow legend={t("Leads_EditingFlyout_AdditionalPhone")} name={"additional_phone_number"}>
        <InputConnected name={"additional_phone_number"} ghost={"hover"} />
      </DataRow>
      <DataRow legend={t("Leads_EditingFlyout_Note")} name={"notes"}>
        <TextareaConnected name={"notes"} ghost={"hover"} />
      </DataRow>
    </FlyoutGrid>
  )
}
