import React from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {PlusCircleIcon} from "@heroicons/react/24/outline"

import {Badge} from "../../components/Badge.tsx"
import Button from "../../components/Button.tsx"
import {LayoutBlock} from "../../components/Layout/LayoutBlock.tsx"
import {TabItem, TabsContainer} from "../../components/Tabs.tsx"
import {useSaasUserManagementMetaQuery} from "../../queries/saasUserManagement.ts"
import {useDocumentTitle, useParam} from "../../utils/hooks.tsx"
import {InviteUsersModal} from "./modals/InviteUsersModal.tsx"
import {SaaSUserManagementTable} from "./table/SaaSUserManagementTable.tsx"
import {InviteUsersModalContext} from "./context.ts"
import {tabDefinitions, tabsOrder, TTab} from "./utils.ts"

export const SaaSUserManagement: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("SaaSUserManagement_DocumentTitle"))

  const navigate = useNavigate()

  const tabParam = useParam("tab", true) ?? "all"
  const activeTab: TTab = tabParam in tabDefinitions ? (tabParam as TTab) : "all"

  const metaQuery = useSaasUserManagementMetaQuery()

  const inviteUsersModalContextValue = InviteUsersModalContext.useProviderValue(false)

  return (
    <InviteUsersModalContext.Provider value={inviteUsersModalContextValue}>
      <LayoutBlock outerClassName={"py-16 grow"} innerClassName={"flex flex-col gap-9"}>
        <h1 className={"text-3xl font-bold"}>{t("SaaSUserManagement_Title")}</h1>

        <div className={"flex items-center gap-8 border-b border-b-cr-blue-light"}>
          <TabsContainer className={"border-none"} size={"sm"}>
            {tabsOrder.map(tab => {
              const isActive = activeTab === tab
              const definition = tabDefinitions[tab]
              const count = metaQuery.data?.[definition.metaQueryKey]

              return (
                <TabItem
                  key={tab}
                  isActive={isActive}
                  onClick={() => navigate(`/user-management/${tab}`)}
                  className={"flex gap-2"}
                >
                  {definition.title}
                  {count !== undefined && (
                    <Badge color={isActive ? "light-blue" : "grey"} className={"self-center px-2.5 py-0.5 text-xs"}>
                      {count}
                    </Badge>
                  )}
                </TabItem>
              )
            })}
          </TabsContainer>
          <Button
            onClick={() => inviteUsersModalContextValue.setValue(true)}
            iconLeft={<PlusCircleIcon />}
            className={"whitespace-nowrap"}
          >
            {t("SaaSUserManagement_InviteModal_Button")}
          </Button>
        </div>

        <SaaSUserManagementTable key={tabDefinitions[activeTab].role} role={tabDefinitions[activeTab].role} />
      </LayoutBlock>
      <InviteUsersModal />
    </InviteUsersModalContext.Provider>
  )
}
