import React from "react"
import {useTranslation} from "react-i18next"
import {FolderPlusIcon} from "@heroicons/react/24/outline"

import {UploadButton} from "./UploadButton.tsx"

export const NoProspects: React.FC = () => {
  const {t} = useTranslation()

  return (
    <div className={"my-14 flex flex-col items-center gap-8"}>
      <FolderPlusIcon className={"w-12 text-cr-grey-30"} />
      <div className={"flex flex-col items-center gap-4"}>
        <h1 className={"text-2xl font-semibold"}>{t("Prospects_NoProspects_Title")}</h1>
        <h2 className={"text-cr-grey-50"}>{t("Prospects_NoProspects_Subtitle")}</h2>
      </div>
      <UploadButton />
    </div>
  )
}
