import {useTranslation} from "react-i18next"

import {TextareaField} from "../../components/fields/Textarea.tsx"
import {MultiStringInputField, TagsOnBottomRenderer} from "../../components/formElements/MultiStringInput.tsx"
import {stepReferences} from "./stepsConfig.ts"

const References = () => {
  const {t} = useTranslation()

  return (
    <>
      <MultiStringInputField
        name={"references"}
        label={stepReferences.name}
        legend={t("SalesStrategy_List companies you worked with")}
        Renderer={TagsOnBottomRenderer}
      />
      <TextareaField rows={4} label={t("SalesStrategy_LinksToArticles")} name={"articles_links"} />
    </>
  )
}

export default References
