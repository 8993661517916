import React from "react"
import {useTranslation} from "react-i18next"
import {isRouteErrorResponse, useRouteError} from "react-router"
import {ArrowLeftIcon} from "@heroicons/react/20/solid"
import {useRollbar} from "@rollbar/react"

import {ButtonLink} from "./components/Button"
import {Footer} from "./components/Layout/Footer"
import {useDocumentTitle, useTimeout} from "./utils/hooks"

export const RouterError: React.FC<{force404?: boolean; maintenance?: boolean}> = ({force404, maintenance}) => {
  const {t} = useTranslation()

  const error = useRouteError()
  const rollbar = useRollbar()
  const [copy, setCopy] = React.useState({title: "", header: "", text: "" as React.ReactNode})

  const startTimeout = useTimeout()

  React.useEffect(() => {
    setCopy({
      title: t("RouterError_Error"),
      header: t("RouterError_An unexpected error has occurred."),
      text: t("T_Try refreshing the page or contact support if the problem persists."),
    })

    if (force404 || (isRouteErrorResponse(error) && error.status === 404)) {
      setCopy({
        title: t("RouterError_Page not found"),
        header: t("RouterError_Page not found."),
        text: (
          <div className={"flex flex-col items-center gap-16"}>
            {t(
              "RouterError_The page you are trying to reach does not exist, or you might have insufficient permissions to view it."
            )}
            <ButtonLink to={"/"} iconLeft={<ArrowLeftIcon />}>
              {t("RouterError_Return home")}
            </ButtonLink>
          </div>
        ),
      })

      rollbar.info(`${force404 ? "Forced " : ""}Error 404`)
      return
    }

    if (maintenance) {
      setCopy({
        title: t("RouterError_Maintenance"),
        header: t("RouterError_We are undergoing maintenance."),
        text: t("RouterError_This page will disappear once the maintenance is done."),
      })
      return
    }

    rollbar.error(
      "Router-caught error",
      error as Error,
      error instanceof Object && error !== null ? {...error} : undefined
    )
  }, [t, maintenance, force404, error, rollbar, startTimeout])

  useDocumentTitle(copy.title)

  return (
    <div className={"fixed inset-0 z-50 flex flex-col justify-between bg-cr-grey-5"}>
      <div className={"flex grow flex-col items-center justify-center gap-16 text-center"}>
        <h1 className={"text-6xl font-semibold"}>{copy.header}</h1>
        <div className={"text-cr-grey-50"}>{copy.text}</div>
      </div>

      <Footer />
    </div>
  )
}
