import React from "react"
import {twMerge} from "tailwind-merge"

import {DropdownContext} from "../context.ts"
import {MainButtonWrapper} from "../Wrappers.tsx"

export const DefaultMain = React.forwardRef<HTMLElement>((_, ref) => {
  const {
    components: {List},
  } = DropdownContext.useContext()

  return (
    <div className={twMerge("relative block select-none")}>
      <MainButtonWrapper ref={ref} />
      <List />
    </div>
  )
})
