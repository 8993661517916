import React from "react"
import {twMerge} from "tailwind-merge"

import {TIconComponent} from "../../../types.ts"

export const SectionCard: React.FC<{
  headline: string
  Icon: TIconComponent
  isDark?: boolean
  children: React.ReactNode
  testId?: string
}> = ({headline, Icon, isDark, children, testId}) => (
  <div
    className={twMerge(
      "card-shadow flex flex-1 flex-col gap-6 bg-cr-blue-super-light p-6",
      isDark && "border-cr-blue-mid bg-cr-blue-mid text-cr-white"
    )}
    data-testid={testId}
  >
    <div className={twMerge("flex justify-between text-cr-blue-mid", isDark && "text-cr-white")}>
      <h2 className={"text-lg font-bold"}>{headline}</h2>
      <Icon className={"size-6"} />
    </div>
    <div className={"whitespace-pre-wrap"}>{children}</div>
  </div>
)

export const SectionCardSkeleton = () => (
  <div className={"card-shadow flex flex-1 flex-col gap-6 p-6"}>
    <div className={"w-1/3 rounded-full bg-cr-grey-15 text-2xl"}>&nbsp;</div>
    <div className={"flex flex-col gap-3"}>
      <div className={"rounded-full bg-cr-grey-15"}>&nbsp;</div>
      <div className={"w-3/4 rounded-full bg-cr-grey-15"}>&nbsp;</div>
    </div>
  </div>
)
