import {FC} from "react"
import {useTranslation} from "react-i18next"

import {Badge, TBadgeColor} from "../../../components/Badge"
import {assignmentToSalesperson, Salespeople} from "../../../components/Salespeople"
import {Table as LayoutTable} from "../../../components/Table"
import {getCurrentLanguage} from "../../../i18n.ts"
import {ASalesPlan, ASalesPlansListData, ASalesPlanStatusEnum} from "../../../services/types.generated"
import {renderPrice} from "../../../utils"
import {apiDateToJS, monthsAndYears} from "../../../utils/dateArithmetics"
import {enumTranslKey} from "../../../utils/i18n"
import {FilteringContext} from "./context.ts"
import {CountryFilter} from "./Filters.tsx"
import {TSalesPlansTableColumn} from "./utils.ts"

type TProps = {
  rows: ASalesPlansListData["sales_plans"]
  loading?: boolean
}

export const Table: FC<TProps> = ({rows = [], loading}) => {
  const {t} = useTranslation()

  const {orderBy, setOrderBy, countryId} = FilteringContext.useContext()

  return (
    <LayoutTable<TSalesPlansTableColumn, ASalesPlan>
      className={"my-8"}
      pinnedColumn={"name"}
      loading={loading}
      orderBy={orderBy}
      onOrder={setOrderBy}
      columnsMeta={[
        {
          column: "name",
          HeaderCellValue: () => t("T_Sales plan name"),
          CellValue: ({row}) => (
            <div title={row.name}>
              <p className={"overflow-hidden text-ellipsis font-medium"}>{row.name}</p>
              <p className={"overflow-hidden text-ellipsis"}>{row.company_name}</p>
            </div>
          ),
        },
        {
          column: "status",
          HeaderCellValue: () => t("T_Status"),
          CellValue: ({row}) => row.status && <SellingPlanBadge status={row.status} />,
        },
        {
          column: "created_at",
          sortFn: true,
          HeaderCellValue: () => t("T_Created"),
          CellValue: ({row}) =>
            row.created_at
              ? apiDateToJS(row.created_at).toLocaleString(getCurrentLanguage(), {dateStyle: "long"})
              : "-",
        },
        {
          column: "team",
          HeaderCellValue: () => t("T_Current sales team"),
          CellValue: ({row}) => (
            <Salespeople
              small
              max={10}
              showReaches
              salesPeople={(row.current_assignments ?? []).map(assignmentToSalesperson)}
            />
          ),
        },
        {
          column: "current_value",
          sortFn: true,
          align: "right",
          HeaderCellValue: () => t("T_Current value"),
          CellValue: ({row}) => renderPrice(row.current_value ?? 0, row.currency),
        },
        {
          column: "market",
          HeaderCellValue: () => t("T_Target market"),
          CellValue: ({row}) => row.country?.name,
          isFiltered: () => countryId.isActive,
          FilterContent: CountryFilter,
          clearFilter: countryId.clear,
        },
        {
          column: "duration",
          HeaderCellValue: () => t("T_Duration"),
          CellValue: ({row}) => !!row.duration_in_months && monthsAndYears(row.duration_in_months),
        },
      ]}
      data={rows}
    >
      {({data}) =>
        data.map(row => (
          <LayoutTable.Row key={row.id} row={row} onClick={() => window.open(`/sellings/${row.id}`, "_blank")} />
        ))
      }
    </LayoutTable>
  )
}

const statusBadgeVariantMap: Record<ASalesPlanStatusEnum, TBadgeColor> = {
  [ASalesPlanStatusEnum.Starting]: "grey",
  [ASalesPlanStatusEnum.Declined]: "red",
  [ASalesPlanStatusEnum.InProgress]: "green",
  [ASalesPlanStatusEnum.Stopped]: "light-blue",
}

const SellingPlanBadge: FC<{status: ASalesPlanStatusEnum}> = ({status}) => {
  const {t} = useTranslation()

  return <Badge color={statusBadgeVariantMap[status]}>{t(enumTranslKey("ASalesPlanStatus", status))}</Badge>
}
