import React from "react"

export const getNodeText = (node: React.ReactNode): string => {
  if (typeof node === "string" || typeof node === "number" || typeof node === "boolean" || node == null) {
    return String(node)
  }
  if (Array.isArray(node) || Symbol.iterator in node) {
    return [...node].map(getNodeText).join("")
  }
  if (typeof node === "object" && node && "props" in node) {
    return getNodeText(node.props.children)
  }
  return ""
}
