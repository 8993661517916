import React from "react"

import {createSimpleContext} from "../../utils/context.ts"
import {TColumnsMeta} from "./shared.ts"

type TColumnSizeContext = {
  getColumnX: (column: string) => number
  setColumnSize: (column: string, size: number) => void
  getColumnSize: (column: string) => number
}

export const ColumnSizeContext = createSimpleContext<TColumnSizeContext>("column size")

export function useColumnSizes(columnsMeta: TColumnsMeta<any, any>): TColumnSizeContext {
  const [columnSizes, setColumnSizes] = React.useState<{[column: string]: number}>({})

  const getColumnX = React.useCallback<TColumnSizeContext["getColumnX"]>(
    (column: string) => {
      let total = 0
      for (const columnMeta of columnsMeta) {
        if (columnMeta.column === column) {
          break
        }

        total += columnSizes[columnMeta.column] ?? 0
      }

      return total
    },
    [columnSizes, columnsMeta]
  )

  const setColumnSize = React.useCallback<TColumnSizeContext["setColumnSize"]>((column, size) => {
    setColumnSizes(sizes => {
      if (sizes[column] === size) {
        return sizes
      }
      return {...sizes, [column]: size}
    })
  }, [])

  const getColumnSize = React.useCallback((column: string) => columnSizes[column], [columnSizes])

  return React.useMemo(() => ({setColumnSize, getColumnX, getColumnSize}), [getColumnSize, getColumnX, setColumnSize])
}
