import React from "react"
import {useTranslation} from "react-i18next"

import {Accordion} from "../../../../components/Accordion.tsx"
import {CheckboxBase} from "../../../../components/fields/Checkbox.tsx"
import {getOptionKey, TOption} from "../../../../components/formElements/Dropdown/types.ts"
import {DefaultToken} from "../../../../components/formElements/MultiStringInput.tsx"
import {ACompanySizesFilters} from "../../../../services/types.generated.ts"
import {getNodeText} from "../../../../utils/getNodeText.ts"
import {enumToOptions} from "../../../../utils/i18n.tsx"
import {FilteringContext} from "../../../Leads/context.ts"
import {TCompanySizeOption} from "../../../Leads/useLeadsFiltering.ts"
import {FilterTitle} from "./utilityComponents/FilterTitle.tsx"

const options = enumToOptions("CompanySizeFilter") as Array<TOption<ACompanySizesFilters>>

export const CompanySize: React.FC = () => {
  const {t} = useTranslation()

  const {companySizes} = FilteringContext.useContext()

  const setCompanySizes = companySizes.setValue
  const handleToggleOption = React.useCallback(
    (valueOption: TCompanySizeOption) => {
      const index = companySizes.value.findIndex(opt => opt.value === valueOption.value)

      if (index === -1) {
        setCompanySizes([...companySizes.value, valueOption])
        return
      }

      setCompanySizes(companySizes.value.toSpliced(index, 1))
    },
    [companySizes.value, setCompanySizes]
  )

  return (
    <Accordion
      title={
        <FilterTitle property={companySizes}>{t("Prospects_ImportModal_LeadsStep_Filter_CompanySize")}</FilterTitle>
      }
      defaultOpen={false}
      listClassName={"flex flex-col gap-4"}
    >
      {companySizes.value.length > 0 && (
        <div className={"flex flex-wrap gap-2"}>
          {companySizes.value.map(valueOption => (
            <DefaultToken
              key={valueOption.value}
              value={getNodeText(valueOption.title)}
              onRemove={() => handleToggleOption(valueOption)}
            />
          ))}
        </div>
      )}
      <div className={"flex flex-col gap-2"}>
        {options.map(opt => (
          <CheckboxBase
            key={getOptionKey(opt)}
            onChange={() => handleToggleOption(opt)}
            checked={!!companySizes.value.find(sizeOpt => sizeOpt.value === opt.value)}
          >
            {opt.title}
          </CheckboxBase>
        ))}
      </div>
    </Accordion>
  )
}
