import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {XMarkIcon} from "@heroicons/react/20/solid"

import {Link} from "../../../components/Link.tsx"
import {ConfirmModal} from "../../../components/Modal.tsx"

export const FinishLater: React.FC = () => {
  const {t} = useTranslation()

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const navigate = useNavigate()

  return (
    <>
      <Link
        variant={"neutral"}
        flipUnderline
        onClick={() => setIsModalOpen(true)}
        className={"inline-flex items-center gap-1"}
      >
        <XMarkIcon className={"size-4"} />
        {t("CreateSalesCycle_FinishLater_Link")}
      </Link>
      <ConfirmModal
        onConfirm={() => navigate("/")}
        title={t("CreateSalesCycle_FinishLater_Title")}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        variant={"warning"}
        confirmButtonText={t("CreateSalesCycle_FinishLater_ConfirmButton")}
        cancelButtonText={t("CreateSalesCycle_FinishLater_CancelButton")}
        flipButtons
      >
        <Trans i18nKey={"CreateSalesCycle_FinishLater_Text"} />
      </ConfirmModal>
    </>
  )
}
