import React from "react"

export const AnonymizedEmail: React.FC<{email: string}> = ({email}) => {
  const split = email.split("@")
  const anonymized = email.includes("@") && split[0] !== "info" ? split[0] : ""
  const notAnonymized = email.substring(anonymized.length)

  return (
    <>
      {anonymized.length > 0 && <span className={"text-cr-grey-50"}>{anonymized}</span>}
      {notAnonymized.length > 0 && <span>{notAnonymized}</span>}
    </>
  )
}

export const AnonymizedPhone: React.FC<{phone: string}> = ({phone}) => {
  const notAnonymized = phone.substring(0, 4)
  const anonymized = phone.substring(notAnonymized.length)

  return (
    <>
      {notAnonymized.length > 0 && <span>{notAnonymized}</span>}
      {anonymized.length > 0 && <span className={"text-cr-grey-50"}>{anonymized}</span>}
    </>
  )
}

export const PhoneNumberCell = ({row}: {row: {phone_number?: string | null; anonymized?: boolean}}) => {
  if (!row.phone_number) {
    return <span>-</span>
  }

  if (isAnonymized(row)) {
    return (
      <span>
        <AnonymizedPhone phone={row.phone_number} />
      </span>
    )
  }

  return <span>{row.phone_number}</span>
}

export const EmailCell = ({row}: {row: {email?: string | null; anonymized?: boolean; info_contact?: boolean}}) => {
  if (!row.email) {
    return <span>-</span>
  }

  if (isAnonymized(row) && !isInfoContact(row)) {
    return (
      <span>
        <AnonymizedEmail email={row.email} />
      </span>
    )
  }

  return <span>{row.email}</span>
}

export function isAnonymized<T extends Record<any, any>>(obj: T): obj is T & {anonymized: true} {
  return "anonymized" in obj && obj.anonymized === true
}

export function isInfoContact<T extends Record<any, any>>(obj: T): obj is T & {info_contact: true} {
  return "info_contact" in obj && obj.info_contact === true
}
