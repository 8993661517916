import React from "react"
import {useTranslation} from "react-i18next"
import {EnvelopeIcon, PhoneIcon, StarIcon, UserGroupIcon} from "@heroicons/react/24/outline"
import {CheckIcon} from "@heroicons/react/24/solid"
import {twMerge} from "tailwind-merge"

import {CircularProgress} from "../../../components/CircularProgress.tsx"
import {getCurrentLanguage} from "../../../i18n.ts"
import {ACompanyUserDashboardActiveSalesCycle, ADashboardStages} from "../../../services/types.generated.ts"
import {TIconComponent} from "../../../types.ts"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {tailwindColors} from "../../../utils/tailwind.ts"
import {getCurrentStage, getStageIndex, getStageTimings, titleByStage} from "../tabs/utils.ts"

export const Stages: React.FC<{salesCycle: ACompanyUserDashboardActiveSalesCycle}> = ({salesCycle}) => {
  const startDate = apiDateToJS(salesCycle.current_selling_date)

  return (
    <div className={"flex flex-wrap justify-between gap-10 max-sm:flex-col"}>
      <Stage stage={ADashboardStages.FirstOutreach} Icon={EnvelopeIcon} startDate={startDate} />
      <Stage stage={ADashboardStages.SecondOutreach} Icon={PhoneIcon} startDate={startDate} />
      <Stage stage={ADashboardStages.PresentationDemo} Icon={UserGroupIcon} startDate={startDate} />
      <Stage stage={ADashboardStages.Closing} Icon={StarIcon} startDate={startDate} />
    </div>
  )
}

const Stage: React.FC<{
  stage: ADashboardStages
  startDate: Date
  Icon: TIconComponent
}> = ({Icon, stage, startDate}) => {
  const {t} = useTranslation()

  const currentStage = getCurrentStage(startDate)
  const thisStage = getStageTimings(startDate).find(s => s.stage === stage)
  const isCurrentStage = stage === currentStage?.stage
  const isPastStage = getStageIndex(stage) < getStageIndex(currentStage?.stage)
  const progress = isCurrentStage ? currentStage.progress : isPastStage ? 100 : 0

  if (!thisStage) {
    return null
  }

  const start = thisStage.start.toLocaleString(getCurrentLanguage(), {month: "short", day: "numeric"})
  const end = thisStage.end.toLocaleString(getCurrentLanguage(), {month: "short", day: "numeric"})

  return (
    <div className={"flex gap-4"}>
      <div className={"size-12"}>
        <CircularProgress active={isCurrentStage} progress={progress} textColor={tailwindColors["cr-white"]}>
          {progress === 100 ? <CheckIcon className={"size-6"} /> : <Icon className={"size-6"} />}
        </CircularProgress>
      </div>
      <div className={"flex flex-col"}>
        <h4
          className={twMerge(["text-sm font-semibold uppercase", isCurrentStage ? "text-cr-blue" : "text-cr-grey-50"])}
        >
          {titleByStage[stage]}
        </h4>
        <div className={"text-sm text-cr-grey-50"}>{t("Dashboard_Stages_DurationSpan", {start, end})}</div>
      </div>
    </div>
  )
}
