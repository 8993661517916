import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {toast} from "react-toastify"

import {GenericErrorAlert} from "../../../components/Alert"
import {RadioBase} from "../../../components/fields/RadioButton"
import {Link} from "../../../components/Link"
import {Loading} from "../../../components/Loading"
import {ConfirmModal} from "../../../components/Modal"
import {useBulkDeleteLeadsMutation, useDeleteLeadMutation, useLeadDetailQuery} from "../../../queries/leads"
import {ALead, ALeadDeleteStrategies, ALeadDetail} from "../../../services/types.generated"
import {usePrevious} from "../../../utils/hooks"
import {commonTransComponents, enumToOptions} from "../../../utils/i18n"
import {isAxiosValidationError} from "../../../utils/validation"
import {DeletingContext, EditingContext} from "../context"

export const DeleteModal: React.FC<{onDelete: () => void; removeReasonGDPROnly?: boolean}> = ({
  onDelete,
  removeReasonGDPROnly = false,
}) => {
  const {value: lead} = DeletingContext.useContext()

  return (
    <>
      <DeleteModalSingle
        lead={Array.isArray(lead) ? null : lead}
        onDelete={onDelete}
        removeReasonGDPROnly={removeReasonGDPROnly}
      />
      <DeleteModalBulk
        leadIds={Array.isArray(lead) ? lead : null}
        onDelete={onDelete}
        removeReasonGDPROnly={removeReasonGDPROnly}
      />
    </>
  )
}

const getDeleteStrategyOptions = (isGDPROnly: boolean) =>
  enumToOptions("DeleteStrategy").filter(option => !isGDPROnly || option.value === "gdpr")

export const DeleteModalSingle: React.FC<{lead: ALead | null; onDelete: () => void; removeReasonGDPROnly: boolean}> = ({
  lead: currentLead,
  onDelete,
  removeReasonGDPROnly,
}) => {
  const {t} = useTranslation()

  const deleteContext = DeletingContext.useContext()
  const editContext = EditingContext.useOptionalContext()
  const previousLead = usePrevious(currentLead)

  const lead = currentLead ?? previousLead

  const {data, isFetching, error, refetch} = useLeadDetailQuery(lead?.id)

  const [deleteStrategy, setDeleteStrategy] = React.useState<ALeadDeleteStrategies | undefined>(undefined)

  const deleteLeadMutation = useDeleteLeadMutation()

  const handleDeleteLead = React.useCallback(async () => {
    if (!currentLead || !deleteStrategy) {
      return
    }

    try {
      await deleteLeadMutation.mutateAsync({id: currentLead.id, strategy: deleteStrategy})
      editContext?.setValue(null)
      onDelete()
      toast.success(t("Leads_DeleteModal_Success_Single", {companyName: currentLead.organization_name}))
    } catch (e) {
      if (isAxiosValidationError(e) && Array.isArray(e.response.data.errors?.base)) {
        toast.error(e.response.data.errors?.base[0])
      } else {
        toast.error(t("Leads_DeleteModal_Error_Single", {companyName: currentLead.organization_name}))
      }

      return false
    }
  }, [currentLead, deleteLeadMutation, deleteStrategy, editContext, onDelete, t])

  React.useEffect(() => {
    if (!currentLead) {
      return
    }

    setDeleteStrategy(undefined)
  }, [currentLead])

  const deleteStrategyOptions = React.useMemo(() => {
    return getDeleteStrategyOptions(removeReasonGDPROnly)
  }, [removeReasonGDPROnly])

  return (
    <ConfirmModal
      variant={"error"}
      title={t("Leads_DeleteModal_Title")}
      isOpen={!!currentLead}
      onClose={() => {
        deleteContext.setValue(null)
      }}
      onConfirm={handleDeleteLead}
      confirmButtonText={t("Leads_DeleteModal_Remove")}
      isConfirmDisabled={!deleteStrategy}
    >
      {error ? (
        <GenericErrorAlert retry={refetch} />
      ) : isFetching ? (
        <Loading containerClassName={"my-10"} size={"md"} />
      ) : (data?.sales_cycles?.length ?? 0) > 0 ? (
        <LeadUsed lead={data!} />
      ) : (
        <div className={"flex flex-col gap-5 text-cr-black"}>
          <div>
            <Trans
              i18nKey={"Leads_DeleteModal_Text_Single"}
              components={commonTransComponents}
              values={{companyName: lead?.organization_name}}
            />
            <div className={"font-bold text-cr-red"}>{t("Leads_DeleteModal_Text_NoUndo")}</div>
          </div>
          <div className={"font-bold"}>{t("Leads_DeleteModal_Text_Reason")}</div>
          <div className={"flex flex-col gap-2"}>
            {deleteStrategyOptions.map(option => (
              <RadioBase
                key={option.value}
                onChange={e => setDeleteStrategy(e.target.value as ALeadDeleteStrategies)}
                value={option.value}
                checked={option.value === deleteStrategy}
              >
                <span className={"text-base"}>{option.title}</span>
              </RadioBase>
            ))}
          </div>
        </div>
      )}
    </ConfirmModal>
  )
}

export const LeadUsed: React.FC<{lead: ALeadDetail}> = ({lead}) => {
  const {t} = useTranslation()

  return (
    <div className={"flex flex-col gap-5 text-cr-black"}>
      <div>
        <Trans
          i18nKey={"Leads_DeleteModal_Used_Text"}
          components={commonTransComponents}
          values={{companyName: lead?.organization_name}}
        />
      </div>
      <div className={"font-bold"}>{t("Leads_DeleteModal_Used_ListHeading")}</div>
      <ul className={"list-inside list-disc"}>
        {(lead.sales_cycles ?? []).map(salesCycle => (
          <li key={salesCycle.id}>
            <Link variant={"neutral"} to={`/admin/prospects/${salesCycle.id}`} target={"_blank"}>
              {salesCycle.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export const DeleteModalBulk: React.FC<{
  leadIds: number[] | null
  onDelete: () => void
  removeReasonGDPROnly: boolean
}> = ({leadIds: currentLeadIds, onDelete, removeReasonGDPROnly}) => {
  const {t} = useTranslation()

  const deleteContext = DeletingContext.useContext()
  const previousLeadIds = usePrevious(currentLeadIds)

  const leadIds = currentLeadIds ?? previousLeadIds

  const [deleteStrategy, setDeleteStrategy] = React.useState<ALeadDeleteStrategies | undefined>(undefined)
  const [warnings, setWarnings] = React.useState<string[]>([])

  const bulkDeleteLeadsMutation = useBulkDeleteLeadsMutation()

  const handleDeleteLeads = React.useCallback(async () => {
    if (!currentLeadIds || !deleteStrategy) {
      return
    }

    setWarnings([])

    try {
      const response = await bulkDeleteLeadsMutation.mutateAsync({ids: currentLeadIds, strategy: deleteStrategy})
      onDelete()

      if (response.data.warnings?.length) {
        setWarnings(response.data.warnings)
        toast.success(t("Leads_DeleteModal_Success_Warnings"))
        return false
      }

      toast.success(t("Leads_DeleteModal_Success_Bulk", {count: currentLeadIds.length}))
    } catch {
      toast.error(t("Leads_DeleteModal_Error_Bulk", {count: currentLeadIds.length}))

      return false
    }
  }, [bulkDeleteLeadsMutation, currentLeadIds, deleteStrategy, onDelete, t])

  React.useEffect(() => {
    if (!currentLeadIds) {
      return
    }

    setDeleteStrategy(undefined)
    setWarnings([])
  }, [currentLeadIds])

  const deleteStrategyOptions = React.useMemo(() => {
    return getDeleteStrategyOptions(removeReasonGDPROnly)
  }, [removeReasonGDPROnly])

  return (
    <ConfirmModal
      variant={"error"}
      title={t("Leads_DeleteModal_Title")}
      isOpen={!!currentLeadIds}
      onClose={() => {
        deleteContext.setValue(null)
      }}
      onConfirm={handleDeleteLeads}
      confirmButtonText={t("Leads_DeleteModal_Remove")}
      isConfirmDisabled={!deleteStrategy}
    >
      <div className={"flex flex-col gap-5 text-cr-black"}>
        <div>
          {t("Leads_DeleteModal_Text_Bulk", {count: leadIds?.length})}
          <div className={"font-bold text-cr-red"}>{t("Leads_DeleteModal_Text_NoUndo")}</div>
        </div>
        <div className={"font-bold"}>{t("Leads_DeleteModal_Text_Reason")}</div>
        <div className={"flex flex-col gap-2"}>
          {deleteStrategyOptions.map(option => (
            <RadioBase
              key={option.value}
              onChange={e => setDeleteStrategy(e.target.value as ALeadDeleteStrategies)}
              value={option.value}
              checked={option.value === deleteStrategy}
            >
              <span className={"text-base"}>{option.title}</span>
            </RadioBase>
          ))}
        </div>
        {warnings.length > 0 && (
          <ul className={"list-outside list-disc space-y-2 text-cr-yellow"}>
            {warnings.map((warning, i) => (
              <li key={i}>{warning}</li>
            ))}
          </ul>
        )}
      </div>
    </ConfirmModal>
  )
}
