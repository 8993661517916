import React from "react"
import {useNavigate} from "react-router"

import {useNumParam, useParam} from "../../utils/hooks.tsx"
import {BenefitsPage} from "./pages/BenefitsPage.tsx"
import {CheckoutPage} from "./pages/CheckoutPage.tsx"
import {CountryPage} from "./pages/CountryPage.tsx"
import {SalespeoplePage} from "./pages/SalespeoplePage.tsx"
import {DEFAULT_PAGE, ECreateSalesCyclePage, getCreateSellingLink} from "./utils.ts"

export const CreateSalesCycle: React.FC = () => {
  const salesCycleId = useNumParam("salesCycleId", true)
  const pageParam = useParam("page", true)

  const page = Object.values(ECreateSalesCyclePage).includes(pageParam as ECreateSalesCyclePage)
    ? (pageParam as ECreateSalesCyclePage)
    : DEFAULT_PAGE

  const navigate = useNavigate()

  React.useEffect(() => {
    if (page !== pageParam || (salesCycleId === undefined && page !== DEFAULT_PAGE)) {
      navigate(getCreateSellingLink({page: DEFAULT_PAGE}), {replace: true})
    }
  }, [page, navigate, pageParam, salesCycleId])

  switch (page) {
    case ECreateSalesCyclePage.COUNTRY:
      return <CountryPage />
    case ECreateSalesCyclePage.SALESPEOPLE:
      return <SalespeoplePage />
    case ECreateSalesCyclePage.BENEFITS:
      return <BenefitsPage />
    case ECreateSalesCyclePage.CHECKOUT:
      return <CheckoutPage />
  }
}
