import React from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"

import {SalespersonRequestCard} from "../routes/CreateSalesCycle/components/SalespersonRequestCard.tsx"
import {getCreateSellingLink} from "../routes/CreateSalesCycle/utils.ts"
import {ACompanyUserSalesCycleSalesPerson} from "../services/types.generated.ts"
import {tailwindColors} from "../utils/tailwind.ts"
import {VideoModal} from "./VideoModal.tsx"

export const SalespeopleShowcase: React.FC<{
  salesPeople?: ACompanyUserSalesCycleSalesPerson[]
  salesCycleId?: number
}> = ({salesPeople = [], salesCycleId}) => {
  const {t} = useTranslation()

  const navigate = useNavigate()

  const [currentVideoId, setCurrentVideoId] = React.useState<string | null>()

  const handleClickSalesPerson = React.useCallback(async () => {
    navigate(getCreateSellingLink({salesCycleId}))
  }, [navigate, salesCycleId])

  return (
    <div className={"flex flex-col"}>
      <h2 className={"font-bold"}>{t("SalespeopleShowcase_Title")}</h2>
      <div
        className={"scroll-shadow-x scrollbar-slim overflow-x-auto py-8"}
        style={
          {
            "--bg": tailwindColors["cr-blue"]["super-light"],
            "--shadow": tailwindColors["cr-grey"]["30"],
          } as React.CSSProperties
        }
      >
        <div className={"flex gap-4 pr-8"}>
          {salesPeople.map(salesPerson => (
            <SalespersonRequestCard
              key={salesPerson.id}
              salesPerson={salesPerson}
              isRequested={false}
              onToggle={handleClickSalesPerson}
              onOpenVideo={() => setCurrentVideoId(salesPerson.embed_video_id ?? null)}
            />
          ))}
        </div>
      </div>
      <VideoModal onClose={() => setCurrentVideoId(null)} youtubeId={currentVideoId} />
    </div>
  )
}
