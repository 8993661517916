import React from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {twMerge} from "tailwind-merge"

import {TabItem, TabsContainer} from "../../../components/Tabs"
import {SearchBox} from "../Sellings/Filters.tsx"

export type TTab<T extends string> = {
  name: string
  href: T
}

const Tabs = <T extends string>({
  tabs,
  tabCurrentHref,
  counts,
}: {
  tabs: ReadonlyArray<TTab<T>>
  tabCurrentHref: T
  counts: Partial<Record<T, number>>
}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const isCurrentTab = (tab: TTab<T>) => tab.href === tabCurrentHref

  return (
    <div>
      <div className={"sm:hidden"}>
        <label htmlFor={"tabs"} className={"sr-only"}>
          {t("T_Select a tab")}
        </label>
        <select
          id={"tabs"}
          name={"tabs"}
          className={
            "block w-full rounded-md border-cr-grey-15 py-2 pl-3 pr-10 text-base focus:border-cr-blue focus:outline-none focus:ring-cr-blue sm:text-sm"
          }
          defaultValue={tabs.find(isCurrentTab)?.name}
          onChange={e => {
            navigate(`../${e.target.value}`)
          }}
        >
          {tabs.map(tab => (
            <option key={tab.name} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className={"hidden items-center justify-between border-b border-b-cr-grey-15 sm:flex"}>
        <TabsContainer size={"sm"} className={"grow border-b-0"}>
          {tabs.map(tab => (
            <TabItem key={tab.name} to={"../" + tab.href} isActive={isCurrentTab(tab)}>
              {tab.name}
              {counts[tab.href] == null ? null : (
                <span
                  className={twMerge(
                    "ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block",
                    isCurrentTab(tab) ? "bg-cr-blue-mid-light text-cr-blue" : "bg-cr-grey-15 text-cr-grey-80"
                  )}
                >
                  {counts[tab.href]}
                </span>
              )}
            </TabItem>
          ))}
        </TabsContainer>
        <div className={"block w-52 shrink-0 max-lg:hidden"}>
          <SearchBox />
        </div>
      </div>
      <div className={"my-8 flex justify-end lg:hidden"}>
        <div className={"w-full sm:w-52"}>
          <SearchBox />
        </div>
      </div>
    </div>
  )
}

export default Tabs
