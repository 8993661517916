import React from "react"
import {useTranslation} from "react-i18next"
import {StarIcon as StarIconOutline} from "@heroicons/react/24/outline"
import {StarIcon as StarIconSolid} from "@heroicons/react/24/solid"

import {Link} from "../../../../components/Link"
import {Table} from "../../../../components/Table"
import {ACompanyUserProspect} from "../../../../services/types.generated"
import {addHttpToURL} from "../../../../utils"
import {enumTranslKey} from "../../../../utils/i18n"
import {TCompanyTableColumn, TTableProps} from "../types"

export const FinishedTable: React.FC<TTableProps> = ({data, isLoading, setOrderBy, orderBy}) => {
  const {t} = useTranslation()

  return (
    <Table<TCompanyTableColumn, ACompanyUserProspect>
      pinnedColumn={"company"}
      data={data}
      loading={isLoading}
      orderBy={orderBy}
      onOrder={setOrderBy}
      columnsMeta={[
        {
          column: "priority",
          size: "min-content",
          sortFn: true,
          HeaderCellValue: () => t("Prospects_Table_Priority"),
          CellValue: ({row}) => {
            return row.prioritized ? (
              <StarIconSolid className={"h-4 w-4 text-cr-blue"} />
            ) : (
              <StarIconOutline className={"h-4 w-4"} />
            )
          },
        },
        {
          column: "company",
          sortFn: true,
          HeaderCellValue: () => t("Prospects_Table_Company"),
          CellValue: ({row}) => <span>{row.organization_name ?? "-"}</span>,
        },
        {
          column: "segment",
          HeaderCellValue: () => t("Prospects_Table_Segment"),
          CellValue: ({row}) => (
            <span>{row.segment?.map(segment => t(enumTranslKey("Segment", segment), segment)).join(", ") ?? "-"}</span>
          ),
        },
        {
          column: "city",
          sortFn: true,
          HeaderCellValue: () => t("Prospects_Table_City"),
          CellValue: ({row}) => <span>{[row.city, row.country?.name].filter(Boolean).join(", ") || "-"}</span>,
        },
        {
          column: "website",
          HeaderCellValue: () => t("Prospects_Table_Website"),
          CellValue: ({row}) =>
            row.website ? (
              <Link to={addHttpToURL(row.website)} variant={"neutral"} target={"_blank"} rel={"noreferrer"}>
                {row.website}
              </Link>
            ) : (
              "-"
            ),
        },
      ]}
    >
      {({data}) => (
        <>
          {data.length ? (
            data.map(row => <Table.Row key={row.id} row={row} />)
          ) : (
            <div className={"col-span-full py-7 text-center text-sm"}>{t("Prospects_NoProspects_TableCaption")}</div>
          )}
        </>
      )}
    </Table>
  )
}
