import React from "react"
import {FloatingFocusManager, FloatingPortal} from "@floating-ui/react"
import {twMerge} from "tailwind-merge"

import {getNodeText} from "../../../../utils/getNodeText.ts"
import {useAutoUpdateRef} from "../../../../utils/ref.ts"
import {DropdownContext} from "../context.ts"
import {getOptionKey, TOption} from "../types.ts"
import {OptionWrapper} from "../Wrappers.tsx"

export const DefaultList: React.FC = () => {
  const {
    name,
    onBlur,
    isOpen,
    options,
    onChange,
    activeOptionIndex,
    setActiveOptionIndex,
    floating: {context, floatingContainerStyles, floatingStyles, getFloatingProps, refs, transitionStatus},
    components: {AddCustom},
    classNames: {list: className},
  } = DropdownContext.useContext()

  const onListSearch = useListSearch()
  const handleKeyDown = React.useCallback<React.KeyboardEventHandler>(
    e => {
      onListSearch(e)
      if (["Enter", "Space"].includes(e.code)) {
        onChange(options[activeOptionIndex].value)
      }
      if (e.code === "ArrowUp") {
        e.preventDefault()
        if (activeOptionIndex === 0) {
          setActiveOptionIndex(options.length - 1)
          return
        }
        setActiveOptionIndex(current => current - 1)
      }
      if (e.code === "ArrowDown") {
        e.preventDefault()
        if (activeOptionIndex === options.length - 1) {
          setActiveOptionIndex(0)
          return
        }
        setActiveOptionIndex(current => current + 1)
      }
    },
    [activeOptionIndex, onChange, onListSearch, options, setActiveOptionIndex]
  )

  if (!isOpen) {
    return null
  }

  return (
    <div>
      <FloatingPortal>
        <FloatingFocusManager context={context} modal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={twMerge(
              "relative isolate z-[100] box-border",
              "flex flex-col",
              "cr-shadow overflow-hidden rounded-lg border border-cr-blue-light",
              transitionStatus.status === "unmounted" && "opacity-0",
              className
            )}
            onBlur={onBlur}
          >
            <div
              className={"flex h-full min-h-0 flex-col outline-none"}
              style={floatingContainerStyles}
              {...getFloatingProps()}
            >
              <div
                className={"flex h-full min-h-0 flex-col overflow-y-auto overflow-x-hidden"}
                data-testid={name ? `dropdown-options-${name}` : undefined}
                tabIndex={0}
                onKeyDown={handleKeyDown}
              >
                {options.map((option, index) => (
                  <OptionWrapper key={getOptionKey(option)} option={option} index={index} />
                ))}
              </div>
              <AddCustom />
            </div>
          </div>
        </FloatingFocusManager>
      </FloatingPortal>
    </div>
  )
}

const splitOptionsForSearch = (options: Array<TOption<unknown>>, activeOptionIndex: number) => {
  const optionsAfterActive = options.slice(activeOptionIndex + 1)
  const optionsBeforeActive = options.slice(0, activeOptionIndex + 1)

  return [
    ...optionsAfterActive.map((opt, index) => ({title: opt.title, originalIndex: activeOptionIndex + index + 1})),
    ...optionsBeforeActive.map((opt, index) => ({title: opt.title, originalIndex: index})),
  ]
}

const useListSearch = () => {
  const {options, activeOptionIndex, setActiveOptionIndex} = DropdownContext.useContext()

  const [searchString, setSearchString] = React.useState("")

  const handleKeyDown = React.useCallback<React.KeyboardEventHandler>(e => {
    if (e.key.length !== 1) {
      return
    }
    setSearchString(s => `${s}${e.key}`)
  }, [])

  const optionsForSearchRef = useAutoUpdateRef(splitOptionsForSearch(options, activeOptionIndex))

  React.useEffect(() => {
    if (searchString === "") {
      return
    }

    const option = optionsForSearchRef.current.find(({title}) =>
      getNodeText(title).toLocaleLowerCase().startsWith(searchString.toLocaleLowerCase())
    )
    if (!option) {
      setSearchString(s => s.at(-1) ?? "")
      return
    }

    setActiveOptionIndex(option.originalIndex)

    const timeout = window.setTimeout(() => setSearchString(""), 1000)
    return () => {
      window.clearTimeout(timeout)
    }
  }, [options, optionsForSearchRef, searchString, setActiveOptionIndex])

  return handleKeyDown
}
