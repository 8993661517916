import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {toast} from "react-toastify"
import {ChevronLeftIcon} from "@heroicons/react/20/solid"
import {zodResolver} from "@hookform/resolvers/zod"
import {z} from "zod"

import {ButtonForm} from "../../components/Button"
import {FieldErrorMessage} from "../../components/fields/components.tsx"
import {InputField} from "../../components/fields/Input"
import {HeroLayout} from "../../components/HeroLayout.tsx"
import {LayoutCard} from "../../components/LayoutCard.tsx"
import {Link} from "../../components/Link.tsx"
import {i18n} from "../../i18n"
import {useSetNewPasswordMutation} from "../../queries/user.ts"
import {useDocumentTitle, useParam} from "../../utils/hooks"
import {commonTransComponents} from "../../utils/i18n.tsx"
import {isAxiosValidationError, setFormErrorsFromAxios, validatePassword} from "../../utils/validation"

const validationSchema = z.object({
  password: validatePassword(),
})

export const PasswordResetVerification = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("PasswordReset_Verification_DocumentTitle"))

  const [isSent, setIsSent] = React.useState(false)

  return (
    <HeroLayout
      leftAction={
        <Link to={"/log-in"} variant={"neutral"} flipUnderline className={"inline-flex items-baseline gap-1"}>
          <ChevronLeftIcon className={"size-5 self-center"} />
          <span>{i18n.t("PasswordReset_Verification_BackLink")}</span>
        </Link>
      }
    >
      {isSent ? <VerificationSent /> : <VerificationForm onSent={() => setIsSent(true)} />}
    </HeroLayout>
  )
}

const VerificationSent: React.FC = () => {
  const {t} = useTranslation()

  return (
    <LayoutCard title={t("PasswordReset_Verification_Sent_Title")}>
      <div>
        <Trans
          i18nKey={"PasswordReset_Verification_Sent_Text"}
          components={{...commonTransComponents, login: <Link data-testid={"login-link"} to={"/log-in"} />}}
        />
      </div>
    </LayoutCard>
  )
}

const VerificationForm: React.FC<{onSent: () => void}> = ({onSent}) => {
  const {t} = useTranslation()

  const navigate = useNavigate()
  const methods = useForm<{password: string}>({mode: "onSubmit", resolver: zodResolver(validationSchema)})
  const {getValues, setError} = methods

  const token = useParam("reset_password_token", true)

  const setNewPasswordMutation = useSetNewPasswordMutation()

  const handleSubmit = React.useCallback(async () => {
    if (!token) {
      return
    }

    try {
      const {password} = getValues()
      await setNewPasswordMutation.mutateAsync({password, password_confirmation: password, reset_password_token: token})
      onSent()
    } catch (e) {
      if (isAxiosValidationError(e) && "reset_password_token" in e.response.data.errors) {
        toast.error(t("PasswordReset_Verification_InvalidTokenToast"))
        navigate("/forgotten-password")
        return
      }

      setFormErrorsFromAxios(e, setError)
    }
  }, [getValues, navigate, onSent, setError, setNewPasswordMutation, t, token])

  return token ? (
    <LayoutCard title={t("PasswordReset_Verification_Title")}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <InputField
            type={"password"}
            label={t("PasswordReset_Verification_NewPassword")}
            name={"password"}
            variant={"medium"}
            hideError
          />
          <ul className={"my-2 list-inside list-disc text-xs text-cr-grey-30"}>
            <Trans i18nKey={"Signup_Company_AccountCreatePage_PasswordRequirements"} components={{bullet: <li />}} />
          </ul>
          <FieldErrorMessage name={"password"} reserveSpace />

          <ButtonForm fullWidth>{t("PasswordReset_Verification_SubmitButton")}</ButtonForm>
        </form>
      </FormProvider>
    </LayoutCard>
  ) : (
    <LayoutCard title={t("PasswordReset_Verification_NoToken_Title")}>
      {t("PasswordReset_Verification_NoToken_Text")}
    </LayoutCard>
  )
}
