import React from "react"

import {useAutoUpdateRef} from "./ref.ts"

export function useResizeRef(callback: (element: HTMLElement) => void) {
  const [element, setElement] = React.useState<HTMLElement | null>()

  const callbackRef = useAutoUpdateRef(callback)

  const handleRef = React.useCallback((element: HTMLElement | null) => {
    setElement(element)
  }, [])

  const resizeObserver = React.useMemo(() => {
    return new ResizeObserver(() => {
      element && callbackRef.current(element)
    })
  }, [callbackRef, element])

  React.useEffect(() => {
    if (!element) {
      return
    }

    callbackRef.current(element)

    resizeObserver.observe(element, {box: "border-box"})
    return () => {
      resizeObserver.disconnect()
    }
  }, [resizeObserver, element, callbackRef])

  return handleRef
}
