import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {CheckIcon} from "@heroicons/react/24/solid"

import {OptionsToggleBase} from "../../../components/fields/OptionsToggle.tsx"
import {TOption} from "../../../components/formElements/Dropdown/types.ts"
import {APackageWithPrice} from "../../../services/types.generated.ts"
import {renderPrice} from "../../../utils"
import {BulletedTransList, commonTransComponents} from "../../../utils/i18n.tsx"
import {TSalesPerson} from "../utils.ts"
import {SalespersonCard} from "./SalespersonCard.tsx"

export const SalespersonPackagesCard: React.FC<
  {value: null | APackageWithPrice; onChange: (newValue: APackageWithPrice) => void} & Omit<
    React.ComponentProps<typeof SalespersonCard>,
    "children"
  >
> = ({value, onChange, ...cardProps}) => {
  return (
    <SalespersonCard
      {...cardProps}
      cyanRibbonText={
        value ? (
          <Trans
            i18nKey={"CreateSalesCycle_SalespersonCard_PriceRibbon_Current"}
            components={commonTransComponents}
            values={{
              price: renderPrice(value.monthly_price, value.currency, {
                noDecimal: true,
              }),
            }}
          />
        ) : undefined
      }
    >
      <SalespersonPackagesBody salesPerson={cardProps.salesPerson} value={value} onChange={onChange} />
    </SalespersonCard>
  )
}

export const SalespersonPackagesBody: React.FC<{
  salesPerson: TSalesPerson
  value: APackageWithPrice | null
  onChange: (newValue: APackageWithPrice) => void
}> = ({salesPerson, value, onChange}) => {
  const {t} = useTranslation()

  const options: Array<TOption<APackageWithPrice>> = React.useMemo(
    () =>
      salesPerson.packages?.map(pkg => ({
        title: (
          <div className={"whitespace-nowrap px-4 py-2 text-sm"}>
            <Trans
              i18nKey={"CreateSalesCycle_Salespeople_Packages_OptionText"}
              values={{count: pkg.reaches}}
              components={{...commonTransComponents, number: <span className={"text-base font-bold"} />}}
            />
          </div>
        ),
        value: pkg,
        key: pkg.id,
      })) ?? [],
    [salesPerson.packages]
  )

  return (
    <div className={"flex grow flex-col gap-6 text-sm"}>
      <div className={"flex flex-col gap-2"}>
        <div className={"font-bold"}>{t("CreateSalesCycle_Salespeople_Packages_Text_Title")}</div>
        <BulletedTransList>
          <Trans
            i18nKey={"CreateSalesCycle_Salespeople_Packages_Text_Bullets"}
            components={{
              ...commonTransComponents,
              bullet: <CheckIcon className={"size-5 translate-y-1 text-cr-blue"} />,
            }}
          />
        </BulletedTransList>
      </div>
      <div className={"flex flex-col items-start gap-2"}>
        <div className={"font-bold"}>{t("CreateSalesCycle_Salespeople_Packages_Text_CTA")}</div>
        <div className={"flex justify-center self-stretch"}>
          <OptionsToggleBase<APackageWithPrice> options={options} value={value} onChange={onChange} />
        </div>
      </div>
    </div>
  )
}
