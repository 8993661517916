import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useLocation, useNavigate} from "react-router"
import {toast} from "react-toastify"
import {zodResolver} from "@hookform/resolvers/zod"
import {isAxiosError} from "axios"
import {z} from "zod"

import {ButtonForm} from "../components/Button"
import {InputField} from "../components/fields/Input"
import {HeroLayout} from "../components/HeroLayout.tsx"
import {LayoutCard} from "../components/LayoutCard.tsx"
import {Link} from "../components/Link"
import {TLogInValues, useLoginMutation} from "../queries/user.ts"
import {LOGOUT_UNAUTHORIZED_TOAST_ID} from "../services/LogoutIfUnauthorized.tsx"
import {useDocumentTitle} from "../utils/hooks"
import {setFormErrorsFromAxios, validateNonemptyString} from "../utils/validation"

const validationSchema = z.object({
  email: validateNonemptyString(),
  password: validateNonemptyString(),
})

export const LogIn = () => {
  const {t} = useTranslation()
  const {state} = useLocation()
  const redirectTo: string | undefined = state?.redirectTo

  useDocumentTitle(t("LogIn_DocumentTitle"))

  const methods = useForm<TLogInValues>({mode: "onTouched", resolver: zodResolver(validationSchema)})
  const {setError} = methods
  const navigate = useNavigate()
  const logInMutation = useLoginMutation()

  const handleSend = async (user: TLogInValues) => {
    try {
      await logInMutation.mutateAsync(user)

      toast.dismiss(LOGOUT_UNAUTHORIZED_TOAST_ID)

      navigate(redirectTo ?? "/")
    } catch (e) {
      // In case of an invalid email / password combination, the response from BE is non-standard
      // This is a workaround to display the error.
      if (isAxiosError(e) && typeof e.response?.data.error === "string") {
        setError("root", {message: e.response.data.error})
        return
      }

      setFormErrorsFromAxios(e, setError)
    }
  }

  return (
    <HeroLayout>
      <FormProvider {...methods}>
        <LayoutCard title={t("LogIn_Title")}>
          <form onSubmit={methods.handleSubmit(handleSend)}>
            <InputField name={"email"} label={t("LogIn_Email")} type={"email"} />
            <InputField name={"password"} label={t("LogIn_Password")} type={"password"} />

            <Link to={"/forgotten-password"} className={"mb-4 inline-block"} flipUnderline>
              {t("LogIn_ForgotPassword")}
            </Link>

            <ButtonForm fullWidth>{t("LogIn_SubmitButton")}</ButtonForm>
          </form>
          <span className={"text-center text-sm"}>
            {t("LogIn_NoAccount")}{" "}
            <Link to={"/sign-up"} flipUnderline>
              {t("LogIn_SignUp")}
            </Link>
          </span>
        </LayoutCard>
      </FormProvider>
    </HeroLayout>
  )
}
