import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"

import {GenericErrorAlert} from "../../components/Alert"
import {InputField} from "../../components/fields/Input.tsx"
import {DropdownField} from "../../components/formElements/Dropdown/Dropdown.tsx"
import {Loading} from "../../components/Loading"
import {Section} from "../../components/Section.tsx"
import {useBusinessInfoQuery} from "../../queries/salespeople"
import {ABusinessData} from "../../services/types.generated.ts"
import {useCountryOptions} from "../../utils/hooks"

export const SalespersonBusinessInfo: React.FC = () => {
  const {data, error, refetch} = useBusinessInfoQuery()

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  if (!data) {
    return <Loading size={"xl"} />
  }

  return <SalespersonBusinessInfoLoaded data={data} />
}

export const SalespersonBusinessInfoLoaded: React.FC<{data: ABusinessData}> = ({data}) => {
  const {t} = useTranslation()

  const countryOptions = useCountryOptions()

  const methods = useForm({values: {...data, country_id: data.country?.id}})

  return (
    <div>
      <FormProvider {...methods}>
        <form className={"flex flex-col gap-8"}>
          <Section
            title={t("SalespersonProfile_BusinessInfo_Address_Title")}
            subtitle={t("SalespersonProfile_BusinessInfo_Address_Subtitle")}
          >
            <div className={"w-full"}>
              <InputField
                name={"street_address"}
                label={t("SalespersonProfile_BusinessInfo_Address_Address")}
                variant={"small"}
                disabled
              />
              <div className={"grid gap-x-8 lg:grid-cols-2"}>
                <DropdownField
                  name={"country_id"}
                  label={t("SalespersonProfile_BusinessInfo_Address_Country")}
                  variant={"small"}
                  options={countryOptions}
                  disabled
                />
                <InputField
                  name={"city"}
                  label={t("SalespersonProfile_BusinessInfo_Address_City")}
                  variant={"small"}
                  disabled
                />
                <InputField
                  name={"postal_code"}
                  label={t("SalespersonProfile_BusinessInfo_Address_ZIP")}
                  variant={"small"}
                  disabled
                />
                {data.state && (
                  <DropdownField
                    name={"state"}
                    label={t("SalespersonProfile_BusinessInfo_Address_State")}
                    variant={"small"}
                    options={data.country?.states ?? []}
                    disabled
                  />
                )}
              </div>
            </div>
          </Section>
          <Section
            title={t("SalespersonProfile_BusinessInfo_Company_Title")}
            subtitle={t("SalespersonProfile_BusinessInfo_Company_Subtitle")}
          >
            <div className={"grid w-full gap-x-8 lg:grid-cols-2"}>
              <InputField
                name={"company_name"}
                label={t("SalespersonProfile_BusinessInfo_Company_CompanyName")}
                variant={"small"}
                disabled
              />
              <InputField
                name={"company_id"}
                label={t("SalespersonProfile_BusinessInfo_Company_CompanyId")}
                variant={"small"}
                disabled
              />
            </div>
          </Section>
        </form>
      </FormProvider>
    </div>
  )
}
