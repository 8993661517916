import React from "react"
import {useTranslation} from "react-i18next"

import {Accordion} from "../../../../components/Accordion.tsx"
import {AutocompleteFilterContent} from "../../../../components/AutocompleteFilter.tsx"
import {getLeadsAutocompleteQuery} from "../../../../queries/leads.ts"
import {ALeadAutocompleteFields} from "../../../../services/types.generated.ts"
import {FilteringContext} from "../../../Leads/context.ts"
import {FilterTitle} from "./utilityComponents/FilterTitle.tsx"

export const City: React.FC = () => {
  const {t} = useTranslation()

  const {cities} = FilteringContext.useContext()

  return (
    <Accordion
      title={<FilterTitle property={cities}>{t("Prospects_ImportModal_LeadsStep_Filter_City")}</FilterTitle>}
      defaultOpen={false}
      listClassName={"flex flex-col gap-4"}
    >
      <AutocompleteFilterContent
        useAutocompleteQuery={getLeadsAutocompleteQuery(ALeadAutocompleteFields.City)}
        onChange={cities.setValue}
        value={cities.value}
        valueOnTop
      />
    </Accordion>
  )
}
