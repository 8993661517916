import React from "react"

import {useResizeRef} from "./useResizeRef.ts"

export const useIsScrollable = () => {
  const [isScrollableX, setIsScrollableX] = React.useState(false)
  const [isScrollableY, setIsScrollableY] = React.useState(false)

  const handler = React.useCallback((element: HTMLElement) => {
    const isScrollableX =
      ["auto", "scroll"].includes(window.getComputedStyle(element).overflowX) &&
      element.scrollWidth > element.clientWidth
    const isScrollableY =
      ["auto", "scroll"].includes(window.getComputedStyle(element).overflowY) &&
      element.scrollHeight > element.clientHeight

    setIsScrollableX(isScrollableX)
    setIsScrollableY(isScrollableY)
  }, [])

  const ref = useResizeRef(handler)

  return React.useMemo(() => ({isScrollableX, isScrollableY, ref}), [isScrollableX, isScrollableY, ref])
}
