import React, {useCallback} from "react"
import {useTranslation} from "react-i18next"
import {Outlet} from "react-router"
import {twMerge} from "tailwind-merge"

import {Link} from "../../components/Link"
import {Loading} from "../../components/Loading"
import {TabItem, TabsContainer} from "../../components/Tabs"
import {TUploadablePictureOnChange, UploadablePicture} from "../../components/UploadablePicture"
import {useCompanyQuery, useUpdateCompanyPictureMutation} from "../../queries/companies"
import requestError from "../../services/requestError.tsx"
import {addHttpToURL} from "../../utils"
import {useDocumentTitle, useNumParam} from "../../utils/hooks"
import {useCurrentStep} from "../../utils/steps"
import {getApiValidationMessages, isAxiosValidationError} from "../../utils/validation"
import {steps} from "./utils"

export const Company: React.FC = () => {
  const companyId = useNumParam("companyId")
  const {step} = useCurrentStep(steps)

  useDocumentTitle(step.name)

  return (
    <div className={"flex justify-center p-10"}>
      <div
        className={twMerge([
          "grid max-w-full grow gap-8 xl:max-w-screen-xl max-lg:grid-cols-1",
          step.isAvatarHidden ? "grid-cols-1" : "grid-cols-[auto,_fit-content(450px)]",
        ])}
      >
        <div className={"row-start-1 lg:col-span-2 max-lg:row-start-2"}>
          <Tabs />
        </div>

        <div className={"row-start-2 min-w-0 max-lg:row-start-3"}>
          <React.Suspense fallback={<Loading size={"xl"} />}>
            <Outlet />
          </React.Suspense>
        </div>

        <div className={twMerge(["row-start-2 max-lg:row-start-1", step.isAvatarHidden && "lg:hidden"])}>
          <ProfilePicture companyId={companyId} />
        </div>
      </div>
    </div>
  )
}

export const Tabs: React.FC = () => {
  const {step: currentStep} = useCurrentStep(steps)

  return (
    <TabsContainer size={"sm"}>
      {steps.map(step => {
        const isCurrentStep = step.href === currentStep.href
        return (
          <TabItem key={step.href} to={step.href} isActive={isCurrentStep}>
            {step.name}
          </TabItem>
        )
      })}
    </TabsContainer>
  )
}

export const ProfilePicture: React.FC<{companyId: number}> = ({companyId}) => {
  const {t} = useTranslation()

  const {data, status} = useCompanyQuery(companyId)
  const updateCompanyPictureMutation = useUpdateCompanyPictureMutation()

  const handleChange = useCallback<TUploadablePictureOnChange>(
    async (file: File) => {
      try {
        await updateCompanyPictureMutation.mutateAsync({companyId, file})
      } catch (e) {
        if (!isAxiosValidationError(e)) {
          requestError(e)
          return
        }

        return {message: getApiValidationMessages(e.response.data, "errors").join(", ")}
      }
    },
    [companyId, updateCompanyPictureMutation]
  )

  if (status === "error") {
    return null
  }

  if (!data) {
    return <Loading />
  }

  // URL constructor throws if it's missing the protocol
  const websiteWithProtocol = data.profile.website ? addHttpToURL(data.profile.website) : ""
  const websiteWithoutProtocol = data.profile.website ? new URL(websiteWithProtocol).hostname : ""

  return (
    <div className={"card flex flex-col items-center gap-6 break-all"}>
      <UploadablePicture
        url={data.profile.profile_picture_url ?? undefined}
        pencilPosition={"top-right"}
        onChange={handleChange}
        maxSize={10_000_000}
        maxSizeMessage={t("T_The file you have picked is too large. Maximum size 10MB.")}
        rounded
      />

      <div className={"flex w-full flex-col gap-2 text-center"}>
        <span className={"text-2xl font-semibold"}>{data.profile.name}</span>
        {data.profile.headline ? (
          <span className={"whitespace-pre-wrap text-cr-grey-50"}>{data.profile.headline}</span>
        ) : null}
      </div>
      {data.profile.website ? (
        <Link variant={"neutral"} flipUnderline to={websiteWithProtocol} target={"_blank"} rel={"noreferrer"}>
          {websiteWithoutProtocol}
        </Link>
      ) : null}
    </div>
  )
}
