import React from "react"
import {useTranslation} from "react-i18next"
import {MagnifyingGlassIcon} from "@heroicons/react/16/solid"

import {TFilterProperty} from "../../utils/filters.ts"
import {TInputBaseProps} from "../fields/Input.tsx"
import {InputFilter} from "./InputFilter.tsx"

export const SearchBoxFilter: React.FC<{property: TFilterProperty<string>} & Omit<TInputBaseProps, "property">> = ({
  property,
  ...inputProps
}) => {
  const {t} = useTranslation()

  return (
    <InputFilter property={property} placeholder={t("SearchPlaceholder")} Icon={MagnifyingGlassIcon} {...inputProps} />
  )
}
