import React from "react"
import {useTranslation} from "react-i18next"

import {Accordion} from "../../../../components/Accordion.tsx"
import {RadioBase} from "../../../../components/fields/RadioButton.tsx"
import {FilteringContext} from "../../../Leads/context.ts"
import {FilterTitle} from "./utilityComponents/FilterTitle.tsx"

export const ContactedByCompany: React.FC = () => {
  const {t} = useTranslation()

  const {contactedByCompany} = FilteringContext.useContext()

  return (
    <Accordion
      title={
        <FilterTitle property={contactedByCompany}>
          {t("Prospects_ImportModal_LeadsStep_Filter_ContactedByCompany_Title")}
        </FilterTitle>
      }
      defaultOpen={false}
      listClassName={"flex gap-4"}
    >
      <RadioBase onChange={() => contactedByCompany.setValue(true)} checked={contactedByCompany.value}>
        {t("Prospects_ImportModal_LeadsStep_Filter_ContactedByCompany_Yes")}
      </RadioBase>
      <RadioBase onChange={() => contactedByCompany.setValue(false)} checked={!contactedByCompany.value}>
        {t("Prospects_ImportModal_LeadsStep_Filter_ContactedByCompany_No")}
      </RadioBase>
    </Accordion>
  )
}
