import {EStatus} from "../routes/SaaSUserManagement/fakeTypes.ts"
import {
  ACompanySizesFilters,
  ALeadCompanySizes,
  AProspectLastChangeRanges,
  ASalesCycleFilterStages,
  ASalesCycleIdealCustomerProfiles,
  ASalesCycleYearsOfMarkets,
} from "../services/types.generated.ts"
import {TTranslatedEnums} from "./i18n.tsx"

export const enumOrders: {
  [key in keyof TTranslatedEnums]?: {[k in keyof TTranslatedEnums[key]]: number}
} = {
  ProspectStage: enumOrder(
    ASalesCycleFilterStages.FirstOutreach,
    ASalesCycleFilterStages.EmailingStage,
    ASalesCycleFilterStages.SecondOutreach,
    ASalesCycleFilterStages.CallingStage,
    ASalesCycleFilterStages.Followup1,
    ASalesCycleFilterStages.Followup2,
    ASalesCycleFilterStages.Followup3,
    ASalesCycleFilterStages.PresentationDemo,
    ASalesCycleFilterStages.MeetingStage,
    ASalesCycleFilterStages.FutureContact,
    ASalesCycleFilterStages.Future,
    ASalesCycleFilterStages.PriceOffer
  ),
  LastUpdate: enumOrder(
    AProspectLastChangeRanges.TwentyFourHours,
    AProspectLastChangeRanges.ThreeDays,
    AProspectLastChangeRanges.SevenDays,
    AProspectLastChangeRanges.FourteenDays,
    AProspectLastChangeRanges.TwentyOneDays
  ),
  CompanySize: enumOrder(
    ALeadCompanySizes.Small,
    ALeadCompanySizes.Medium,
    ALeadCompanySizes.Big,
    ALeadCompanySizes.Corporation,
    ALeadCompanySizes.NotSpecified
  ),
  Profile: enumOrder(
    ASalesCycleIdealCustomerProfiles.Small,
    ASalesCycleIdealCustomerProfiles.Middle,
    ASalesCycleIdealCustomerProfiles.Corporation
  ),
  YearsOfMarket: enumOrder(
    ASalesCycleYearsOfMarkets.Zero,
    ASalesCycleYearsOfMarkets.One,
    ASalesCycleYearsOfMarkets.Two,
    ASalesCycleYearsOfMarkets.ThreeAndMore
  ),
  SaaSUserStatus: enumOrder(EStatus.active, EStatus.invited, EStatus.deactivated),
  CompanySizeFilter: enumOrder(
    ACompanySizesFilters.Value110,
    ACompanySizesFilters.Value1120,
    ACompanySizesFilters.Value2150,
    ACompanySizesFilters.Value51100,
    ACompanySizesFilters.Value101200,
    ACompanySizesFilters.Value201500,
    ACompanySizesFilters.Value5011000,
    ACompanySizesFilters.Value10001000000
  ),
}

/**
 * A helper function ensuring all enum keys are given an order number.
 * Used inside enumOrders().
 *
 * Made because TS does not support exclusive tuples (an array containing all union members exactly once)
 * This allows foreign values and duplicates, but we can live with that.
 */
function enumOrder<T extends string[]>(...members: T): {[k in T[number]]: number} {
  return members.reduce(
    (acc, member, index) => {
      return {...acc, [member]: index}
    },
    {} as ReturnType<typeof enumOrder<T>>
  )
}
