import React from "react"
import {useTranslation} from "react-i18next"
import {ChevronLeftIcon} from "@heroicons/react/20/solid"

import {GenericErrorAlert} from "../../../components/Alert.tsx"
import {ButtonLink} from "../../../components/Button.tsx"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock.tsx"
import {Link} from "../../../components/Link.tsx"
import {Loading} from "../../../components/Loading.tsx"
import {useCheckoutPreviewQuery} from "../../../queries/checkout.ts"
import {useIsWelcomeScreenEnabled} from "../../../utils"
import {EGTMEvent, emitGTMEvent} from "../../../utils/gtm.ts"
import {useDocumentTitle, useNumParam} from "../../../utils/hooks.tsx"
import {BenefitsCloseRocket} from "../components/BenefitsCloseRocket.tsx"
import {BenefitsInternal} from "../components/BenefitsInternal.tsx"
import {OnboardingLayout} from "../components/OnboardingLayout.tsx"
import {ECreateSalesCyclePage, getCreateSellingLink} from "../utils.ts"

export const BenefitsPage: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("CreateSalesCycle_Benefits_DocumentTitle"))

  const isWelcomeScreenEnabled = useIsWelcomeScreenEnabled()

  const salesCycleId = useNumParam("salesCycleId")
  const orderId = useNumParam("orderId")

  const {data, error, refetch} = useCheckoutPreviewQuery(orderId)

  return (
    <OnboardingLayout
      title={t("CreateSalesCycle_Benefits_Title")}
      step={3}
      total={3}
      subtitle={t("CreateSalesCycle_Benefits_Subtitle")}
      leftAction={
        <Link
          to={getCreateSellingLink({salesCycleId, page: ECreateSalesCyclePage.SALESPEOPLE})}
          flipUnderline
          variant={"neutral"}
          className={"inline-flex items-center gap-1"}
        >
          <ChevronLeftIcon className={"size-5"} />
          {t("CreateSalesCycle_Back")}
        </Link>
      }
      rightAction={
        <ButtonLink
          to={getCreateSellingLink({salesCycleId, page: ECreateSalesCyclePage.CHECKOUT, orderId})}
          onClick={() => {
            isWelcomeScreenEnabled && emitGTMEvent({event: EGTMEvent.COMPANY_CHECKOUT_STARTED})
          }}
        >
          {t("CreateSalesCycle_Benefits_SubmitButton")}
        </ButtonLink>
      }
    >
      <LayoutBlock>
        {error ? (
          <GenericErrorAlert retry={refetch} />
        ) : data ? (
          <div className={"flex flex-wrap-reverse gap-20 max-md:flex-col"}>
            <BenefitsInternal order={data} />
            <BenefitsCloseRocket order={data.order} />
          </div>
        ) : (
          <Loading fullSpace size={"xl"} />
        )}
      </LayoutBlock>
    </OnboardingLayout>
  )
}
