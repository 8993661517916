import React from "react"
import {useTranslation} from "react-i18next"
import {Outlet} from "react-router"

import {Loading} from "../../../components/Loading"
import Pagination from "../../../components/Pagination"
import {i18n} from "../../../i18n"
import {useAdminSalesPlansQuery} from "../../../queries/salesPlans.ts"
import {ASalesPlanQueryStatusValues} from "../../../services/types.generated"
import {useDocumentTitle, useParam, useRerouteDefault} from "../../../utils/hooks"
import {enumTranslKey} from "../../../utils/i18n"
import Tabs, {TTab} from "../Matching/Tabs" // TODO move to components
import {FilteringContext} from "./context.ts"
import {Table} from "./Table"
import {useSalesPlansFiltering} from "./utils.ts"

type TMatchingTab = TTab<ASalesPlanQueryStatusValues | "all"> & {rowNavigationDisabled?: boolean}

const tabs = [
  {
    name: i18n.t("SalesPlans_All"),
    href: "all",
  },
  {
    name: i18n.t(enumTranslKey("ASalesPlanStatus", ASalesPlanQueryStatusValues.Starting)),
    href: ASalesPlanQueryStatusValues.Starting,
  },
  {
    name: i18n.t(enumTranslKey("ASalesPlanStatus", ASalesPlanQueryStatusValues.InProgress)),
    href: ASalesPlanQueryStatusValues.InProgress,
  },
  {
    name: i18n.t(enumTranslKey("ASalesPlanStatus", ASalesPlanQueryStatusValues.Declined)),
    href: ASalesPlanQueryStatusValues.Declined,
  },
  {
    name: i18n.t(enumTranslKey("ASalesPlanStatus", ASalesPlanQueryStatusValues.Stopped)),
    href: ASalesPlanQueryStatusValues.Stopped,
  },
] satisfies readonly TMatchingTab[]

const Sellings = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("T_Sales plans"))

  const filters = FilteringContext.useProviderValue(useSalesPlansFiltering())
  const {searchString, countryId, orderBy} = filters

  const tabCurrentHref = useParam("tab") as ASalesPlanQueryStatusValues | "all"
  const {isPending, isPlaceholderData, data, pagination} = useAdminSalesPlansQuery(tabCurrentHref, {
    searchString: searchString.valueDebounced,
    countryId: countryId.valueDebounced,
    orderBy,
  })
  const rows = data?.data.sales_plans

  return (
    <FilteringContext.Provider value={filters}>
      <div className={"mx-auto my-16 w-full max-w-screen-xl px-8"}>
        <h1 className={"mb-4 text-3xl font-bold"}>{t("T_Sales plans")}</h1>

        <Tabs tabs={tabs} tabCurrentHref={tabCurrentHref} counts={data?.data.meta?.tabs ?? {}} />

        {isPending || !rows ? (
          <Loading containerClassName={"h-[200px]"} />
        ) : (
          <Table rows={rows} loading={isPlaceholderData} />
        )}
        <Pagination {...pagination} />
      </div>
    </FilteringContext.Provider>
  )
}

export const SellingsIndex = () => {
  useRerouteDefault("/admin/sellings/all")

  return (
    <React.Suspense fallback={<Loading size={"xl"} />}>
      <Outlet />
    </React.Suspense>
  )
}

export default Sellings
