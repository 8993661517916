import React, {FC, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {ChevronLeftIcon} from "@heroicons/react/20/solid"
import {zodResolver} from "@hookform/resolvers/zod"
import {z} from "zod"

import {ButtonForm, ButtonLink} from "../../components/Button"
import {InputField} from "../../components/fields/Input"
import {HeroLayout} from "../../components/HeroLayout.tsx"
import {LayoutCard} from "../../components/LayoutCard.tsx"
import {Link} from "../../components/Link.tsx"
import {i18n} from "../../i18n.ts"
import {TPasswordResetRequestValues, usePasswordResetRequestMutation} from "../../queries/user.ts"
import {useDocumentTitle} from "../../utils/hooks"
import {commonTransComponents} from "../../utils/i18n.tsx"
import {setFormErrorsFromAxios, validateNonemptyString} from "../../utils/validation"

const validationSchema = z.object({
  email: validateNonemptyString(),
})

export const PasswordResetRequest: FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("PasswordReset_Request_DocumentTitle"))

  const [sentToEmail, setSentToEmail] = useState<string | null>(null)

  return (
    <HeroLayout
      leftAction={
        <Link to={"/log-in"} variant={"neutral"} flipUnderline className={"inline-flex items-baseline gap-1"}>
          <ChevronLeftIcon className={"size-5 self-center"} />
          <span>{i18n.t("PasswordReset_Request_BackLink")}</span>
        </Link>
      }
    >
      {sentToEmail ? <RequestSent email={sentToEmail} /> : <RequestForm onSent={setSentToEmail} />}
    </HeroLayout>
  )
}

const RequestForm: React.FC<{onSent: (email: string) => void}> = ({onSent}) => {
  const {t} = useTranslation()

  const methods = useForm<TPasswordResetRequestValues>({mode: "onTouched", resolver: zodResolver(validationSchema)})
  const {getValues, setError} = methods
  const passwordRequestResetMutation = usePasswordResetRequestMutation()

  const handleSend = React.useCallback(async () => {
    try {
      const {email} = getValues()
      await passwordRequestResetMutation.mutateAsync({email})

      onSent(email)
    } catch (e) {
      setFormErrorsFromAxios(e, setError)
    }
  }, [getValues, passwordRequestResetMutation, onSent, setError])

  return (
    <FormProvider {...methods}>
      <LayoutCard title={t("PasswordReset_Request_Title")}>
        {t("PasswordReset_Request_Text")}
        <form onSubmit={methods.handleSubmit(handleSend)}>
          <InputField variant={"medium"} label={t("PasswordReset_Request_Email")} type={"email"} name={"email"} />
          <ButtonForm fullWidth>{t("PasswordReset_Request_SubmitButton")}</ButtonForm>
        </form>
      </LayoutCard>
    </FormProvider>
  )
}

const RequestSent: React.FC<{email: string}> = ({email}) => {
  const {t} = useTranslation()

  const passwordRequestResetMutation = usePasswordResetRequestMutation()

  const handleResend = React.useCallback(async () => {
    try {
      await passwordRequestResetMutation.mutateAsync({email})

      toast.success(t("PasswordReset_Request_Sent_ResentToast"))
    } catch {
      toast.error(t("PasswordReset_Request_Sent_ResentErrorToast"))
    }
  }, [email, passwordRequestResetMutation, t])

  return (
    <LayoutCard title={t("PasswordReset_Request_Sent_Title")}>
      <div>
        <Trans
          i18nKey={"PasswordReset_Request_Sent_Text"}
          values={{email}}
          components={{...commonTransComponents, highlight: <strong className={"font-semibold text-cr-blue"} />}}
        />
      </div>
      <div className={"flex items-center gap-4"}>
        {t("PasswordReset_Request_Sent_ResendText")}
        <ButtonLink
          isLoading={passwordRequestResetMutation.isPending}
          variant={"light"}
          size={"sm"}
          onClick={handleResend}
        >
          {t("PasswordReset_Request_Sent_ResendButton")}
        </ButtonLink>
      </div>
    </LayoutCard>
  )
}
