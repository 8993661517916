import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {ArrowUpTrayIcon} from "@heroicons/react/20/solid"
import {zodResolver} from "@hookform/resolvers/zod"
import {z} from "zod"

import Button, {ButtonForm} from "../../../../components/Button.tsx"
import {FileInputField} from "../../../../components/fields/File.tsx"
import {InputField} from "../../../../components/fields/Input.tsx"
import {DropdownField} from "../../../../components/formElements/Dropdown/Dropdown.tsx"
import {Link} from "../../../../components/Link.tsx"
import {useUploadProspectsForAssignmentMutation} from "../../../../queries/prospects.ts"
import {baseURL} from "../../../../services"
import {AOwnershipLevels} from "../../../../services/types.generated.ts"
import {enumToOptions} from "../../../../utils/i18n.tsx"
import {requiredFieldMessage, setFormErrorsFromAxios, validateNonemptyString} from "../../../../utils/validation.ts"

const validationSchema = z.object({
  source: validateNonemptyString(),
  file: z.custom<File>().refine(file => !!file, requiredFieldMessage),
  owner: validateNonemptyString(),
})

const ownershipLevels = enumToOptions("ProspectOwnershipLevel").filter(
  e => ([AOwnershipLevels.Company, AOwnershipLevels.CloseRocket] as string[]).includes(e.value) // don't display all options for now
)

export const XLSStep: React.FC<{assignmentId: number; onSuccess: () => void; onClose: () => void}> = ({
  assignmentId,
  onClose,
  onSuccess,
}) => {
  const {t} = useTranslation()

  const methods = useForm<{file: File; source: string; owner: AOwnershipLevels}>({
    resolver: zodResolver(validationSchema),
  })
  const {getValues, setError} = methods

  const uploadMutation = useUploadProspectsForAssignmentMutation()

  const handleSubmit = React.useCallback(async () => {
    const values = getValues()

    try {
      await uploadMutation.mutateAsync({
        assignmentId,
        data: {
          ownership_level: values.owner,
          prospects_file: values.file,
          source: values.source,
        },
      })
      onSuccess()
    } catch (e) {
      setFormErrorsFromAxios(e, setError, "prospect_import")
    }
  }, [assignmentId, getValues, onSuccess, setError, uploadMutation])

  return (
    <FormProvider {...methods}>
      <form className={"flex flex-col gap-10"} onSubmit={methods.handleSubmit(handleSubmit)}>
        <div className={"flex flex-col gap-3"}>
          <h1 className={"text-2xl font-semibold"}>{t("Prospects_ImportModal_UploadStep_Title")}</h1>
          <p className={"text-cr-grey-50"}>{t("Prospects_ImportModal_UploadStep_Description")}</p>
        </div>

        <div className={"flex flex-col gap-4"}>
          <div className={"flex flex-col gap-2"}>
            <FileInputField
              label={t("Prospects_ImportModal_UploadStep_FileLabel")}
              name={"file"}
              accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
              errorMessageProps={{reserveSpace: false}}
            />

            <div className={"text-right text-sm"}>
              <Trans
                i18nKey={"Prospects_ImportModal_UploadStep_Template"}
                components={{
                  templateLink: (
                    <Link
                      to={baseURL + "prospects-import-template-example.xlsx"}
                      download
                      flipUnderline
                      className={"font-semibold"}
                    />
                  ),
                }}
              />
            </div>
          </div>

          <DropdownField
            label={t("Prospects_ImportModal_UploadStep_OwnerLabel")}
            name={"owner"}
            options={ownershipLevels}
          />

          <InputField name={"source"} label={t("Prospects_ImportModal_UploadStep_SourceLabel")} />
        </div>

        <div className={"flex justify-end gap-4"}>
          <Button variant={"outlined"} color={"gray"} onClick={onClose}>
            {t("T_Cancel")}
          </Button>

          <ButtonForm iconLeft={<ArrowUpTrayIcon />}>{t("T_Import")}</ButtonForm>
        </div>
      </form>
    </FormProvider>
  )
}
