import React, {useState} from "react"

import {TOrderBy} from "../../../components/Table/shared.ts"
import {ASalesPlan} from "../../../services/types.generated.ts"
import {useFilterProperties, useMaybeFilterProperty, useStringFilterProperty} from "../../../utils/filters.ts"

export type TSalesPlansTableColumn = keyof ASalesPlan | "team" | "market" | "duration"

export type TSalesPlansFiltering = ReturnType<typeof useSalesPlansFiltering>

export const useSalesPlansFiltering = () => {
  const [orderBy, setOrderBy] = useState<TOrderBy<TSalesPlansTableColumn>>()

  const filterProperties = useFilterProperties({
    searchString: useStringFilterProperty(),
    countryId: useMaybeFilterProperty<number>(),
  })

  return React.useMemo(
    () => ({
      ...filterProperties,
      orderBy,
      setOrderBy,
    }),
    [filterProperties, orderBy]
  )
}
