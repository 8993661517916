import React from "react"
import {CheckIcon} from "@heroicons/react/24/solid"
import {twMerge} from "tailwind-merge"

import {UnstyledLink} from "../../../Link.tsx"
import {DropdownContext} from "../context.ts"
import {TDropdownOptionProps} from "../types.ts"

export const DefaultOption: React.FC<TDropdownOptionProps> = ({option, index}) => {
  const {
    activeOptionIndex,
    isOptionSelected,
    onChange,
    components: {OptionValue},
  } = DropdownContext.useContext()

  const isSelected = React.useMemo(() => isOptionSelected(option), [isOptionSelected, option])
  const isActive = activeOptionIndex === index

  return (
    <UnstyledLink
      onClick={() => onChange(option.value)}
      className={twMerge(
        "flex cursor-pointer items-center gap-2 bg-cr-white p-2 pl-4 text-sm",
        isActive && "bg-cr-blue text-cr-white",
        isSelected && "font-semibold",
        !isActive && isSelected && "bg-cr-blue-light hover:bg-cr-blue-light"
      )}
      role={"option"}
    >
      <div className={"min-w-0 grow break-all"}>
        <OptionValue option={option} index={index} />
      </div>
      <CheckIcon className={twMerge("size-4 shrink-0", isSelected ? "opacity-100" : "opacity-0")} />
    </UnstyledLink>
  )
}
