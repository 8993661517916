import React from "react"
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react"
import {ChevronDownIcon} from "@heroicons/react/16/solid"

import {CheckboxBase} from "./fields/Checkbox"
import {TOption} from "./formElements/Dropdown/types"
import {Badge} from "./Badge"
import {UnstyledLink} from "./Link"

type TContentProps<T> = {options: Array<TOption<T>>} & (
  | {
      multiple: true
      value: Array<TOption<T>>
      onChange: (newValue: Array<TOption<T>>) => void
    }
  | {
      multiple: false
      value: TOption<T> | null
      onChange: (newValue: TOption<T> | null) => void
    }
)
type TProps<T> = TContentProps<T> & {title: React.ReactNode}

export const EnumFilter = <T,>(props: TProps<T>) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const {title, multiple, value} = props

  const {context, refs, floatingStyles} = useFloating({
    placement: "bottom-end",
    middleware: [flip(), offset(10), shift({padding: 16})],
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)

  const {getReferenceProps, getFloatingProps} = useInteractions([click, dismiss])
  const {styles} = useTransitionStyles(context, {
    duration: {open: 100, close: 75},
    initial: {opacity: 0, transform: "scale(0.95)"},
    open: {opacity: 1, transform: "scale(1)"},
    common: ({side}) => ({
      transformOrigin: {
        top: "bottom",
        bottom: "top",
        left: "right",
        right: "left",
      }[side],
    }),
  })

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <UnstyledLink className={"flex cursor-pointer items-center gap-2"}>
          <span>{title}</span>
          {((multiple && value.length > 0) || (!multiple && value)) && (
            <Badge color={"blue"} className={"px-2.5 py-1 text-xs"}>
              {multiple ? value.length : 1}
            </Badge>
          )}
          <ChevronDownIcon className={"size-5"} />
        </UnstyledLink>
      </div>

      {isOpen && (
        <FloatingPortal>
          <div className={"absolute z-[100]"} ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            <div style={{...styles}} className={"floating-card flex max-w-80 flex-col gap-4"}>
              <EnumFilterContent {...props} />
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

export const EnumFilterContent = <T,>({options, onChange, multiple, value}: TContentProps<T>) => {
  const handleChangeOption = React.useCallback(
    (option: TOption<T>) => () => {
      if (!multiple) {
        return onChange(option.value === value?.value ? null : option)
      }

      const valueWithoutOption = value.filter(valueOption => valueOption.value !== option.value)

      if (valueWithoutOption.length === value.length) {
        return onChange([...value, option])
      }

      return onChange(valueWithoutOption)
    },
    [multiple, onChange, value]
  )

  return (
    <>
      {options.map(opt => (
        <CheckboxBase
          key={opt.key ?? String(opt.value)}
          onChange={handleChangeOption(opt)}
          checked={multiple ? value.some(val => val.value === opt.value) : value?.value === opt.value}
        >
          <span className={"line-clamp-1 break-all"}>{opt.title}</span>
        </CheckboxBase>
      ))}
    </>
  )
}
