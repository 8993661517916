import React from "react"
import {XMarkIcon} from "@heroicons/react/20/solid"

import {IconButton} from "../../../Button.tsx"
import {DropdownContext} from "../context.ts"

export const DefaultClearButton: React.FC = () => {
  const {onClear, multiple, value, clearable, mainButtonRef, disabled} = DropdownContext.useContext()

  const handleClear = React.useCallback<React.MouseEventHandler>(
    e => {
      e.stopPropagation()
      onClear()
      mainButtonRef.current?.focus()
    },
    [mainButtonRef, onClear]
  )

  if (!clearable || !value || (multiple && value.length === 0) || disabled) {
    return null
  }

  return (
    <IconButton onClickCapture={handleClear} noEdges>
      <XMarkIcon className={"size-5 text-cr-red"} />
    </IconButton>
  )
}
