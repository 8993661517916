import React from "react"
import {useTranslation} from "react-i18next"
import {ChevronRightIcon} from "@heroicons/react/20/solid"
import {twMerge} from "tailwind-merge"

import {Badge} from "../../../../components/Badge.tsx"
import {UnstyledLink} from "../../../../components/Link.tsx"
import {i18n} from "../../../../i18n.ts"
import {EFeature, EStep, featureToStep} from "./utils.ts"

const allRecommendedFeatures = [EFeature.XLS, EFeature.Single] satisfies EFeature[]
const allRestFeatures = [EFeature.Leads] satisfies EFeature[]

const featureConfig = {
  [EFeature.Single]: {
    title: i18n.t("Prospects_ImportModal_SingleStep_Button_Title"),
    subtitle: i18n.t("Prospects_ImportModal_SingleStep_Button_Subtitle"),
  },
  [EFeature.XLS]: {
    title: i18n.t("Prospects_ImportModal_UploadStep_Button_Title"),
    subtitle: i18n.t("Prospects_ImportModal_UploadStep_Button_Subtitle"),
  },
  [EFeature.Leads]: {
    title: i18n.t("Prospects_ImportModal_LeadsStep_Button_Title"),
    subtitle: i18n.t("Prospects_ImportModal_LeadsStep_Button_Subtitle"),
  },
} satisfies {[feature in EFeature]: {title: string; subtitle: string}}

export const InitialStep: React.FC<{
  onStepPick: (step: EStep) => void
  onClose: () => void
  enabledFeatures: EFeature[]
}> = ({onStepPick, enabledFeatures}) => {
  const {t} = useTranslation()

  const recommendedFeatures = React.useMemo(() => {
    return allRecommendedFeatures.filter(feature => enabledFeatures.includes(feature))
  }, [enabledFeatures])

  const restFeatures = React.useMemo(() => {
    return allRestFeatures.filter(feature => enabledFeatures.includes(feature))
  }, [enabledFeatures])

  return (
    <div className={"flex flex-col gap-8"}>
      <div className={"flex flex-col gap-3"}>
        <h1 className={"text-2xl font-semibold"}>{t("Prospects_ImportModal_InitialStep_Title")}</h1>
        <p className={"text-cr-grey-50"}>{t("Prospects_ImportModal_InitialStep_Description")}</p>
      </div>

      {recommendedFeatures.length > 0 && (
        <div className={"flex flex-col gap-4"}>
          {restFeatures.length > 0 && (
            <div className={"flex items-baseline justify-between gap-8"}>
              <h2 className={"font-bold"}>{t("Prospects_ImportModal_InitialStep_Recommended_Title")}</h2>
              <Badge color={"dark-blue"}>{t("Prospects_ImportModal_InitialStep_Recommended_Badge")}</Badge>
            </div>
          )}
          <div className={"flex flex-col gap-2"}>
            {recommendedFeatures.map(feature => (
              <SourceButton
                key={feature}
                recommended={restFeatures.length > 0}
                onSelect={() => onStepPick(featureToStep[feature])}
                title={featureConfig[feature].title}
                subtitle={featureConfig[feature].subtitle}
              />
            ))}
          </div>
        </div>
      )}
      {restFeatures.length > 0 && (
        <div className={"flex flex-col gap-4"}>
          {recommendedFeatures.length > 0 && (
            <h2 className={"text-cr-grey-50"}>{t("Prospects_ImportModal_InitialStep_Rest")}</h2>
          )}
          <div className={"flex flex-col gap-2"}>
            {restFeatures.map(feature => (
              <SourceButton
                key={feature}
                onSelect={() => onStepPick(featureToStep[feature])}
                title={featureConfig[feature].title}
                subtitle={featureConfig[feature].subtitle}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export const SourceButton: React.FC<{
  onSelect: () => void
  title: React.ReactNode
  subtitle: React.ReactNode
  recommended?: boolean
}> = ({onSelect, subtitle, title, recommended}) => {
  return (
    <UnstyledLink
      onClick={onSelect}
      className={twMerge(
        "group flex cursor-pointer gap-8 rounded-lg border px-6 py-4 transition-all",
        recommended
          ? "border-cr-blue-mid-light bg-cr-blue-light hover:border-cr-indigo-mid"
          : "border-cr-blue-light bg-cr-white hover:border-cr-blue-mid-light"
      )}
    >
      <div className={"flex grow flex-col"}>
        <h3 className={"font-semibold"}>{title}</h3>
        <span className={recommended ? "text-cr-black" : "text-cr-grey-50"}>{subtitle}</span>
      </div>
      <div
        className={"flex shrink-0 items-center justify-end text-cr-grey-65 transition-all group-hover:text-cr-black"}
      >
        <ChevronRightIcon className={"size-6"} />
      </div>
    </UnstyledLink>
  )
}
