import React, {ChangeEventHandler, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import {NavigateOptions, useNavigate, useOutlet, useParams, useSearchParams} from "react-router"

import {useUserSettingsOrLogout} from "../queries/user.ts"

export function useClickOutsideRef<T extends HTMLElement = HTMLDivElement>(
  callback: () => void
): MutableRefObject<T | null> {
  const ref = useRef<T>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node | null)) {
        return callback()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, callback])

  return ref
}

export function useRerouteDefault(defaultRoute: string): ReturnType<typeof useOutlet> {
  const outlet = useOutlet()
  const navigate = useNavigate()

  useEffect(() => {
    if (!outlet) {
      navigate(defaultRoute, {replace: true})
    }
  }, [defaultRoute, navigate, outlet]) // TODO navigate stable?

  return outlet
}

export function useFilePicker({
  onChange,
  accept,
  multiple,
}: {
  onChange?: (file: File[]) => void
  accept?: HTMLInputElement["accept"]
  multiple?: boolean
}) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const fileList = e.currentTarget.files

      if (!fileList || !fileList.length) {
        return
      }

      onChange?.([...fileList])
    },
    [onChange]
  )

  const inputElement: HTMLInputElement = useMemo(() => {
    const el = document.createElement("input")
    el.type = "file"
    if (accept != null) {
      el.accept = accept
    }
    if (handleChange != null) {
      // The native onchange handler has iffy typings
      el.onchange = handleChange as any
    }
    if (multiple) {
      el.multiple = multiple
    }

    return el
  }, [handleChange, accept, multiple])

  return useCallback(() => {
    inputElement.click()
  }, [inputElement])
}

export const makeDocumentTitle = (title = "") => (title ? `${title} 🚀 ` : "") + "CloseRocket"

export const useDocumentTitle = (title = "") => {
  useEffect(() => {
    document.title = makeDocumentTitle(title)

    return () => {
      document.title = makeDocumentTitle()
    }
  }, [title])
}

export const useNumParam = <TAllowUndefined extends boolean = false>(
  param: string,
  allowUndefined?: TAllowUndefined
): TAllowUndefined extends false ? number : number | undefined => {
  const paramStr = useParam(param, allowUndefined)

  const numberParamOrNaN = Number(paramStr)
  const numberParam = Number.isNaN(numberParamOrNaN) ? undefined : numberParamOrNaN

  if (numberParam === undefined && !allowUndefined) {
    throw new Error(`Getting the number param ${param} failed.`)
  }

  return numberParam as number
}

export const useParam = <TAllowUndefined extends boolean = false>(
  param: string,
  allowUndefined?: TAllowUndefined
): TAllowUndefined extends false ? string : string | undefined => {
  const queryParam = useParams<Record<string, string>>()[param]
  const searchParam = useSearchParams()[0].get(param)

  const paramValue = (queryParam ?? searchParam) || undefined

  if (paramValue === undefined && !allowUndefined) {
    throw new Error(`Getting the param ${param} failed.`)
  }

  return paramValue as ReturnType<typeof useParam<TAllowUndefined>>
}

export const useLoadPicture = (url: string): {isLoading: boolean; isError: boolean} => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    setIsError(false)

    const img = new Image()
    img.src = url
    img.onload = () => {
      setIsLoading(false)
    }
    img.onerror = () => {
      setIsLoading(false)
      setIsError(true)
    }

    return () => {
      img.onload = null
      img.onerror = null
    }
  }, [url])

  return {isLoading, isError}
}

export function useTimeout(): (handler: () => void, delay: number) => number {
  const timeoutRef = React.useRef<undefined | number>(undefined)

  const stopTimeout = React.useCallback(() => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = undefined
  }, [])

  React.useEffect(() => {
    return () => {
      // Stop on unmount
      stopTimeout()
    }
  }, [stopTimeout])

  return React.useCallback(
    (handler: () => void, delay: number) => {
      // Stop old before creating new
      stopTimeout()

      timeoutRef.current = window.setTimeout(() => {
        handler()
        stopTimeout()
      }, delay)

      return timeoutRef.current
    },
    [stopTimeout]
  )
}

export const usePromptNavigate = (shouldPrompt = true, promptBeforeUnload = true, message?: string) => {
  const {t} = useTranslation()

  const navigate = useNavigate()

  useEffect(() => {
    if (!shouldPrompt || !promptBeforeUnload) {
      return
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = message ?? ""
    }

    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => window.removeEventListener("beforeunload", handleBeforeUnload)
  }, [message, promptBeforeUnload, shouldPrompt])

  return (to: string, navigateOptions?: NavigateOptions) => {
    if (!shouldPrompt) {
      return navigate(to, navigateOptions)
    }

    const confirmed = window.confirm(message ?? t("T_Are you sure you want to navigate? Unsaved data might be lost."))
    if (confirmed) {
      return navigate(to, navigateOptions)
    }
  }
}

export function usePrevious<T>(value: T) {
  const ref = useRef<{value: T; prev: T | null}>({
    value: value,
    prev: null,
  })

  const current = ref.current.value

  if (value !== current) {
    ref.current = {
      value: value,
      prev: current,
    }
  }

  return ref.current.prev
}

export function useDebouncedValue<T>(value: T, debounceTime = 500): T {
  const [currentValue, setCurrentValue] = useState(value)

  React.useEffect(() => {
    const timeout = window.setTimeout(() => {
      setCurrentValue(value)
    }, debounceTime)

    return () => {
      window.clearTimeout(timeout)
    }
  }, [value, debounceTime])

  return currentValue
}

export function useCountries() {
  const {countries} = useUserSettingsOrLogout()

  return React.useMemo(() => [...countries].sort((A, B) => A.name.localeCompare(B.name)), [countries])
}

export function useCountryOptions() {
  const countries = useCountries()

  return React.useMemo(() => {
    return countries.map(e => ({value: e.id, title: e.name}))
  }, [countries])
}
