import React from "react"
import {FormState} from "react-hook-form"
import {useNavigate} from "react-router"
import isEqual from "fast-deep-equal"
import {z} from "zod"

import {useSalesCycleQuery} from "../../queries/salesCycles"
import {ASalesCycle} from "../../services/types.generated"
import {useNumParam} from "../../utils/hooks"
import {useAutoUpdateRef} from "../../utils/ref.ts"
import {usePreviousStep} from "../../utils/steps"
import {TSalesFormData} from "../SalesForm/types"
import {useSaveStepMutation} from "./api"
import {
  stepAdditionalInformation,
  stepBenefits,
  stepElevatorPitch,
  stepMarketPresence,
  stepReferences,
  stepUniqueness,
  stepWeb,
} from "./stepsConfig.ts"
import {EStepStatus} from "./types"

export const steps = [
  stepWeb,
  stepElevatorPitch,
  stepBenefits,
  stepUniqueness,
  stepReferences,
  stepMarketPresence,
  stepAdditionalInformation,
] as const

export const mergedValidationSchema = steps
  .map(step => step.validationSchema)
  .reduce((schema, stepSchema) => {
    return schema.merge(stepSchema)
  }, z.object({}))

export function getStepStatus({
  step,
  values,
  currentStepIndex,
  touchedFields,
  isSent,
}: {
  isSent: boolean
  step: (typeof steps)[number]
  currentStepIndex: number
  touchedFields: FormState<TSalesFormData>["touchedFields"]
  values: Partial<TSalesFormData>
}): EStepStatus {
  const stepIndex = steps.indexOf(step)

  if (stepIndex === currentStepIndex) {
    return EStepStatus.current
  }

  const isStepFieldTouched = step.fields.some(field => touchedFields[field])
  const hasStepValues = step.fields.some(field => {
    const value = values[field]

    if (value instanceof FileList || Array.isArray(value)) {
      return value.length > 0
    }

    return value != null && value !== ""
  })
  if (!isStepFieldTouched && !hasStepValues && !isSent) {
    return EStepStatus.upcoming
  }

  const areFieldsValid = step.validationSchema.safeParse(values).success
  return areFieldsValid ? EStepStatus.completed : EStepStatus.error
}

export const makeLink = (href: string, salesCycleId: number) => `/sales-strategy/${salesCycleId}/${href}`

export function useSaveOnStepChange(values: TSalesFormData) {
  const salesCycleId = useNumParam("salesCycleId")

  const navigate = useNavigate()

  const previousStep = usePreviousStep(steps)

  const hasStepChanged = useHasStepChanged(previousStep?.step, values, salesCycleId)

  const saveStepMutation = useSaveStepMutation()

  const isStepValid = previousStep?.step.validationSchema.safeParse(values).success ?? false

  const refDependencies = useAutoUpdateRef({saveStepMutation, hasStepChanged, values, isStepValid})

  React.useEffect(() => {
    const refs = refDependencies.current

    if (salesCycleId == null || !refs.hasStepChanged || !previousStep || !refs.isStepValid) {
      return
    }

    refs.saveStepMutation.reset()
    refs.saveStepMutation.mutateAsync({step: previousStep.step, values: refs.values, salesCycleId}).catch(() => {
      navigate(makeLink(previousStep.step.href, salesCycleId))
    })
  }, [previousStep, salesCycleId, navigate, refDependencies])

  return saveStepMutation
}

function useHasStepChanged(
  step: (typeof steps)[number] | undefined,
  values: TSalesFormData,
  salesCycleId: number
): boolean {
  const {data: salesCycle} = useSalesCycleQuery(salesCycleId)

  return React.useMemo(() => {
    if (!salesCycle || !step) {
      return false
    }

    const salesCycleAttributes = salesCycleToValues(salesCycle)

    if (step.href === stepWeb.href && values.appendable_attachments?.length) {
      return true
    }

    return step.fields.some(
      field => field !== "appendable_attachments" && !isEqual(salesCycleAttributes[field], values[field])
    )
  }, [salesCycle, step, values])
}

export function salesCycleToValues(salesCycle: ASalesCycle): TSalesFormData {
  return {
    ...salesCycle,
    country_id: salesCycle.country.id,
    website: salesCycle.website ?? "",
    successful_events: salesCycle.successful_events ?? [],
    appendable_attachments: undefined,
    additional_strategy_description: salesCycle.additional_strategy_description ?? undefined,
    sales_scripts: salesCycle.sales_scripts ?? undefined,
    products_attributes: salesCycle.products.map(product => ({
      ...product,
      currency_id: product.currency?.id,
    })),
  }
}
