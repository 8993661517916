import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {CheckCircleIcon, CogIcon, LightBulbIcon, PlayCircleIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import crPdfEn from "../../assets/CloseRocket-LearningKit01-How-to-start-selling.pdf"
import salesPdfEn from "../../assets/CloseRocket-LearningKit02-Sales-tips-and-tricks.pdf"
import kpiPdfEn from "../../assets/CloseRocket-LearningKit03-KPI-introduction.pdf"
import crPdfSk from "../../assets/CloseRocket-LearningKitSK01-Ako-zacat-predavat.pdf"
import salesPdfSk from "../../assets/CloseRocket-LearningKitSK02-Tipy-a-triky.pdf"
import kpiPdfSk from "../../assets/CloseRocket-LearningKitSK03-Uvod-do-KPI.pdf"
import youtubeLogo from "../../assets/youtubeLogo.svg"
import {ButtonLink} from "../../components/Button.tsx"
import {CheckboxConnected} from "../../components/fields/Checkbox"
import {Link, UnstyledLink} from "../../components/Link"
import {ELanguage, i18n} from "../../i18n"
import {useSalespersonQuery, useUpdateSalespersonLearningMaterialsMutation} from "../../queries/salespeople"
import {ASalesPersonProfile} from "../../services/types.generated"
import {useDocumentTitle, useNumParam} from "../../utils/hooks"
import {setFormErrorsFromAxios} from "../../utils/validation"

const features = [
  {
    name: i18n.t("SalesPersonLearning_SalesPersonEducationCard_Headline"),
    description: i18n.t("SalesPersonLearning_SalesPersonEducationCard_Description"),
    Actions: () => <DownloadPdfLink languageLink={{[ELanguage.EN]: kpiPdfEn, [ELanguage.SK]: kpiPdfSk}} />,
    Icon: CheckCircleIcon,
    checkboxLabel: i18n.t("SalesPersonLearning_HaveReadCheckbox"),
    nameInApi: "sales_person_education_finished",
  },
  {
    name: i18n.t("SalesPersonLearning_ClosingDealsEducationCard_Headline"),
    description: i18n.t("SalesPersonLearning_ClosingDealsEducationCard_Description"),
    Actions: () => <DownloadPdfLink languageLink={{[ELanguage.EN]: salesPdfEn, [ELanguage.SK]: salesPdfSk}} />,
    Icon: LightBulbIcon,
    checkboxLabel: i18n.t("SalesPersonLearning_HaveReadCheckbox"),
    nameInApi: "closing_deals_education_finished",
  },
  {
    name: i18n.t("SalesPersonLearning_OnboardingEducationCard_Headline"),
    description: i18n.t("SalesPersonLearning_OnboardingEducationCard_Description"),
    Actions: () => <DownloadPdfLink languageLink={{[ELanguage.EN]: crPdfEn, [ELanguage.SK]: crPdfSk}} />,
    Icon: CogIcon,
    checkboxLabel: i18n.t("SalesPersonLearning_HaveReadCheckbox"),
    nameInApi: "onboarding_education_finished",
  },
  {
    name: i18n.t("SalesPersonLearning_VideoUploadCard_Headline"),
    description: i18n.t("SalesPersonLearning_VideoUploadCard_Description"),
    Actions: () => (
      <div className={"flex items-center gap-4"}>
        <ButtonLink
          to={"https://docs.google.com/forms/d/e/1FAIpQLSfLYo9B-PvJTwR1rKqMRxuCLYp1GaGCANwzyrbBc1k6UWFO9g/viewform"}
          target={"_blank"}
        >
          {i18n.t("SalesPersonLearning_VideoUploadCard_UploadVideo")}
        </ButtonLink>
        <Link
          to={"https://youtu.be/8Z6iaj6nHRU"}
          className={"text-sm font-semibold"}
          target={"_blank"}
          flipUnderline
          rel={"noreferrer"}
        >
          {i18n.t("SalesPersonLearning_VideoUploadCard_ViewExample")}
        </Link>
      </div>
    ),
    Icon: PlayCircleIcon,
    checkboxLabel: i18n.t("SalesPersonLearning_HaveSentVideoCheckbox"),
    nameInApi: "video_upload_finished",
  },
] as const satisfies ReadonlyArray<{
  name: string
  description: string
  Actions: React.ComponentType
  Icon: React.ComponentType
  checkboxLabel: string
  nameInApi: keyof ASalesPersonProfile["learning_materials"]
}>

export const SalespersonLearning = () => {
  const {t} = useTranslation()
  const salespersonId = useNumParam("salespersonId")

  useDocumentTitle(t("SalesPersonLearning_DocumentTitle"))

  const {data, status} = useSalespersonQuery(salespersonId)

  const updateMutation = useUpdateSalespersonLearningMaterialsMutation()
  const isLoading = updateMutation.isPending || status === "pending"

  const methods = useForm<ASalesPersonProfile["learning_materials"]>()
  const {reset, setError} = methods

  React.useEffect(() => {
    if (!data) {
      return
    }
    reset(data.learning_materials)
  }, [data, reset])

  const handleSubmit = async (learningMaterials: ASalesPersonProfile["learning_materials"]) => {
    try {
      return await updateMutation.mutateAsync({
        salespersonId,
        learningMaterials,
      })
    } catch (e) {
      return setFormErrorsFromAxios(e, setError, "sales_person.learning_materials")
    }
  }

  return (
    <FormProvider {...methods}>
      <div className={"flex justify-center p-10"}>
        <form className={"card xl:max-w-screen-xl"} onChange={methods.handleSubmit(handleSubmit)}>
          <div className={"mt-5999 mb-20"}>
            <h1 className={"text-3xl font-semibold"}>{t("SalesPersonLearning_PageHeadline")}</h1>
            <p className={"mt-4 text-cr-grey-50"}>{t("SalesPersonLearning_PageDescription")}</p>
          </div>

          <div className={"mb-20"}>
            <h2 className={"text-2xl font-semibold"}>{t("SalesPersonLearning_Tutorial")}</h2>
            <UnstyledLink
              className={"card-shadow relative mt-8 inline-flex items-center justify-center rounded-xl"}
              to={"https://www.youtube.com/watch?v=0hMN7eF-mGk"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              <img
                src={"https://img.youtube.com/vi/0hMN7eF-mGk/maxresdefault.jpg"}
                className={"min-h-48 w-full min-w-80 max-w-[900px] rounded-xl"}
                alt={"How to set up PipeDrive"}
              />
              <img src={youtubeLogo} className={"absolute w-20"} />
            </UnstyledLink>
          </div>

          <div>
            <h2 className={"text-2xl font-semibold"}>{t("SalesPersonLearning_FinishTheseSteps")}</h2>
            <div className={"mt-8 flex w-full flex-wrap gap-x-8 gap-y-10"}>
              {features.map(feature => (
                <div key={feature.name} className={"card flex w-[440px] flex-col justify-between p-0"}>
                  <div className={"flex flex-col items-start gap-4 px-10 pb-4 pt-8"}>
                    <div className={"flex h-12 w-12 items-center justify-center rounded-md bg-cr-blue"}>
                      <feature.Icon className={"h-6 w-6 text-white"} aria-hidden={"true"} />
                    </div>
                    <div className={"flex flex-col items-start gap-1"}>
                      <h3 className={"font-semibold"}>{feature.name}</h3>
                      <div className={"text-sm text-cr-grey-50"}>{feature.description}</div>
                    </div>
                    <feature.Actions />
                  </div>

                  <div className={twMerge("bg-cr-grey-5 px-10 py-6", isLoading && "animate-pulse cursor-progress")}>
                    <div className={twMerge(isLoading && "pointer-events-none")}>
                      <CheckboxConnected name={feature.nameInApi} disabled={isLoading}>
                        {feature.checkboxLabel}
                      </CheckboxConnected>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  )
}

const DownloadPdfLink: React.FC<{languageLink: Record<ELanguage, string>}> = ({languageLink}) => {
  const {t} = useTranslation()

  return (
    <Link
      to={languageLink[i18n.language as ELanguage]}
      className={"mb-4 text-sm font-semibold"}
      target={"_blank"}
      flipUnderline
    >
      {t("SalesPersonLearning_DownloadPdf")}
    </Link>
  )
}
