import {FC} from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"

import {assignmentToSalesperson, Salespeople} from "../../../components/Salespeople"
import {Table as LayoutTable} from "../../../components/Table"
import {getCurrentLanguage} from "../../../i18n"
import {ASalesCycleIteration, ASalesCycleIterationsListData} from "../../../services/types.generated"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"

const Table: FC<{
  rows: ASalesCycleIterationsListData["sales_cycle_iterations"]
  loading?: boolean
  rowNavigation: boolean
}> = ({rows, loading, rowNavigation}) => {
  const {t} = useTranslation()

  const navigate = useNavigate()

  return (
    <LayoutTable<"salesPlanName" | "company_name" | "month" | "country" | "salesTalent", ASalesCycleIteration>
      loading={loading}
      className={"my-8"}
      columnsMeta={[
        {
          column: "salesPlanName",
          HeaderCellValue: () => t("T_Sales plan name"),
          CellValue: ({row}) => <span className={"font-medium"}>{row.sales_cycle.name}</span>,
        },
        {
          column: "company_name",
          HeaderCellValue: () => t("T_Company name"),
        },
        {
          column: "month",
          HeaderCellValue: () => t("T_Month"),
          CellValue: ({row}) =>
            apiDateToJS(row.start_date).toLocaleString(getCurrentLanguage(), {month: "short", year: "numeric"}),
        },
        {
          column: "country",
          HeaderCellValue: () => t("T_Country"),
          CellValue: ({row}) => row.sales_cycle.country.name,
        },
        {
          column: "salesTalent",
          HeaderCellValue: () => t("T_Salespeople"),
          CellValue: ({row}) => (
            <Salespeople small max={10} showReaches salesPeople={row.assignments.map(assignmentToSalesperson)} />
          ),
        },
      ]}
      data={rows}
    >
      {({data}) =>
        data.map(row => (
          <LayoutTable.Row
            key={row.id}
            onClick={rowNavigation ? () => navigate(`detail/${row.id}`, {state: {iteration: row}}) : undefined}
            row={row}
          />
        ))
      }
    </LayoutTable>
  )
}

export default Table
