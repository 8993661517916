import React from "react"
import {Navigate} from "react-router"

import {Loading} from "../../components/Loading"
import {useUserSettingsQuery} from "../../queries/user.ts"
import {RouterError} from "../../RouterError"
import {useNumParam} from "../../utils/hooks"
import {isCompanyUser} from "../../utils/types"

export const MyCompanyGate: React.FC<{
  Component: React.ComponentType
  redirectToCurrentIfUndef?: boolean // do a relative path redirect to the current user if salespersonId is undef
}> = ({Component, redirectToCurrentIfUndef}) => {
  const {status, data} = useUserSettingsQuery()

  const companyId = useNumParam("companyId", true)

  if (redirectToCurrentIfUndef && !companyId && data && isCompanyUser(data.user) && data.user.company.id) {
    return <Navigate to={String(data.user.company.id)} replace /> // relative redirect to the company of the current user
  }

  if (status === "pending") {
    return <Loading size={"xl"} />
  }
  if (status === "error") {
    return <Navigate to={"/log-in"} />
  }

  if (!data || !isCompanyUser(data.user) || data.user.company.id !== companyId) {
    return <RouterError force404 />
  }

  return <Component />
}
