import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"

import {usePaginatedQuery} from "../components/Pagination"
import {EOrderDirection, TOrderBy} from "../components/Table/shared"
import {TCompanyTableColumn} from "../routes/Prospects/Company/types"
import {TEditingProspect} from "../routes/Prospects/shared/context.ts"
import {TAdminTableColumn} from "../routes/Prospects/shared/DataTable.tsx"
import api, {multipartRequest, queryKey} from "../services"
import {
  AAdminsProspectsSalesCycle,
  ACompanyProspectSortByValues,
  ACompanyProspectsSalesCycle,
  AOwnershipLevels,
  AProspectAttributes,
  AProspectDisapproveReasons,
  AProspectQueryStatusValues,
  AProspectSortByValues,
  AProspectSortDirectionValues,
  ASaasCompanyProspectsSalesCycle,
  ASalesPersonProspectsSalesCycle,
  AUserTypes,
} from "../services/types.generated"
import {useValueByUserType} from "../utils/userTypes.ts"

export type TAdminsProspectsIteration = AAdminsProspectsSalesCycle["sales_cycle_iterations"][number]
export type TAdminsProspectsAssignment = TAdminsProspectsIteration["assignments"][number]

export type TCompanyProspectsIteration = ACompanyProspectsSalesCycle["sales_cycle_iterations"][number]
export type TCompanyProspectsAssignment = TCompanyProspectsIteration["assignments"][number]

export type TSaasCompanyProspectsIteration = ASaasCompanyProspectsSalesCycle["sales_cycle_iterations"][number]
export type TSaasCompanyProspectsAssignment = TSaasCompanyProspectsIteration["assignments"][number]

export type TSalespersonProspectsIteration = ASalesPersonProspectsSalesCycle["sales_cycle_iterations"][number]
export type TSalespersonProspectsAssignment = TSalespersonProspectsIteration["assignments"][number]

export const usePrioritizeProspectMutation = () =>
  useMutation({mutationFn: (id: number) => api.prospects.prioritizePartialUpdate(id)})

export const useDeprioritizeProspectMutation = () =>
  useMutation({mutationFn: (id: number) => api.prospects.deprioritizePartialUpdate(id)})

export const useBulkPrioritizeProspectsMutation = () =>
  useMutation({
    mutationFn: (ids: number[]) => api.bulkActions.prospectsPrioritizePartialUpdate({ids}),
  })

export const useBulkDeprioritizeProspectsMutation = () =>
  useMutation({
    mutationFn: (ids: number[]) => api.bulkActions.prospectsDeprioritizePartialUpdate({ids}),
  })

export const useBulkDisapproveProspectsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: {
      ids: number[]
      disapprove_reason: AProspectDisapproveReasons
      disapprove_reason_text: string | undefined
    }) => api.bulkActions.prospectsDisapprovePartialUpdate(params),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}

export const useBulkDeleteProspectsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (ids: number[]) => api.bulkActions.prospectsDelete({ids}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}

export const useProspectsSalesCycleQuery = (salesCycleId: number) => {
  const queryFn = useValueByUserType({
    [AUserTypes.Admin]: api.admins.prospectsSalesCyclesDetail,
    [AUserTypes.SalesPerson]: api.salesPeople.prospectsSalesCyclesDetail,
    [AUserTypes.CompanyUser]: api.companyUsers.prospectsSalesCyclesDetail,
    [AUserTypes.SaasCompanyUser]: api.saasCompanyUsers.prospectsSalesCyclesDetail,
    [AUserTypes.SaasSalesPerson]: api.saasSalesPeople.prospectsSalesCyclesDetail,
  })

  return useQuery({
    queryKey: queryKey.prospectsSalesCycle(salesCycleId),
    queryFn: async () => (await queryFn(salesCycleId)).data.sales_cycle,
  })
}

const adminSortColumnToApi = (column: TAdminTableColumn | undefined): AProspectSortByValues | undefined => {
  switch (column) {
    case "company":
      return AProspectSortByValues.OrganizationName
    case "position":
      return AProspectSortByValues.Position
    case "city":
      return AProspectSortByValues.City
    case "email":
      return AProspectSortByValues.Email
    default:
      return undefined
  }
}

const companySortColumnToApi = (column: TCompanyTableColumn | undefined): ACompanyProspectSortByValues | undefined => {
  switch (column) {
    case "company":
      return ACompanyProspectSortByValues.OrganizationName
    case "city":
      return ACompanyProspectSortByValues.City
    case "priority":
      return ACompanyProspectSortByValues.Prioritized
    default:
      return undefined
  }
}

export const directionToApi = (direction: EOrderDirection | undefined): AProspectSortDirectionValues | undefined => {
  switch (direction) {
    case EOrderDirection.ASC:
      return AProspectSortDirectionValues.Asc
    case EOrderDirection.DESC:
      return AProspectSortDirectionValues.Desc
    default:
      return undefined
  }
}

export const useDraftTableQuery = ({
  assignmentId,
  salesCycleId,
  searchString,
  pageSize,
  orderBy,
  status,
}: {
  salesCycleId: number
  assignmentId: number
  searchString: string
  pageSize: number
  orderBy: TOrderBy<TAdminTableColumn>
  status: AProspectQueryStatusValues | null
}) => {
  const queryFn = useValueByUserType({
    [AUserTypes.Admin]: api.admins.prospectsAssignmentsProspectsPrepareToApproveDetail,
    [AUserTypes.SalesPerson]: api.salesPeople.prospectsAssignmentsProspectsPrepareToApproveDetail,
  })

  return usePaginatedQuery(
    queryKey.prospectsDraftTable({orderBy, searchString, assignmentId, salesCycleId, status}),
    query =>
      queryFn(assignmentId, {
        search: searchString.trim(),
        sort_by: adminSortColumnToApi(orderBy?.column),
        sort_direction: directionToApi(orderBy?.direction),
        status: status ?? undefined,
        ...query,
      }),
    {pageSize}
  )
}

export const useWaitingForApprovalTableQuery = ({
  assignmentId,
  salesCycleId,
  searchString,
  pageSize,
  orderBy,
  status,
}: {
  salesCycleId: number
  assignmentId: number
  searchString: string
  pageSize: number
  orderBy: TOrderBy<TAdminTableColumn>
  status: AProspectQueryStatusValues | null
}) => {
  const queryFn = useValueByUserType({
    [AUserTypes.Admin]: api.admins.prospectsAssignmentsProspectsWaitingToApproveDetail,
    [AUserTypes.SalesPerson]: api.salesPeople.prospectsAssignmentsProspectsWaitingToApproveDetail,
  })

  return usePaginatedQuery(
    queryKey.prospectsWaitingForApprovalTable({
      orderBy,
      searchString,
      assignmentId,
      salesCycleId,
      status,
    }),
    query =>
      queryFn(assignmentId, {
        search: searchString.trim(),
        sort_by: adminSortColumnToApi(orderBy?.column),
        sort_direction: directionToApi(orderBy?.direction),
        status: status ?? undefined,
        ...query,
      }),
    {pageSize}
  )
}

export const useFinishedTableQuery = ({
  assignmentId,
  salesCycleId,
  searchString,
  pageSize,
  orderBy,
  status,
}: {
  salesCycleId: number
  assignmentId: number
  searchString: string
  pageSize: number
  orderBy: TOrderBy<TAdminTableColumn>
  status: AProspectQueryStatusValues | null
}) => {
  const queryFn = useValueByUserType({
    [AUserTypes.Admin]: api.admins.prospectsAssignmentsProspectsFinishedDetail,
    [AUserTypes.SalesPerson]: api.salesPeople.prospectsAssignmentsProspectsFinishedDetail,
  })

  return usePaginatedQuery(
    queryKey.prospectsFinishedTable({orderBy, searchString, assignmentId, salesCycleId, status}),
    query =>
      queryFn(assignmentId, {
        search: searchString.trim(),
        sort_by: adminSortColumnToApi(orderBy?.column),
        sort_direction: directionToApi(orderBy?.direction),
        status: status ?? undefined,
        ...query,
      }),
    {pageSize}
  )
}

export const useCompanyWaitingToApproveTableQuery = ({
  assignmentId,
  salesCycleId,
  searchString,
  pageSize,
  orderBy,
}: {
  salesCycleId: number
  assignmentId: number
  searchString: string
  pageSize: number
  orderBy: TOrderBy<TCompanyTableColumn>
}) =>
  usePaginatedQuery(
    queryKey.prospectsWaitingForApprovalTable({
      orderBy,
      searchString,
      assignmentId,
      salesCycleId,
      status: null,
    }),
    query =>
      api.companyUsers.prospectsAssignmentsProspectsWaitingToApproveDetail(assignmentId, {
        search: searchString.trim(),
        sort_by: companySortColumnToApi(orderBy?.column),
        sort_direction: directionToApi(orderBy?.direction),
        ...query,
      }),
    {pageSize}
  )

export const useCompanyFinishedTableQuery = ({
  assignmentId,
  salesCycleId,
  searchString,
  pageSize,
  orderBy,
}: {
  salesCycleId: number
  assignmentId: number
  searchString: string
  pageSize: number
  orderBy: TOrderBy<TCompanyTableColumn>
}) =>
  usePaginatedQuery(
    queryKey.prospectsFinishedTable({
      orderBy,
      searchString,
      assignmentId,
      salesCycleId,
      status: null,
    }),
    query =>
      api.companyUsers.prospectsAssignmentsProspectsFinishedDetail(assignmentId, {
        search: searchString.trim(),
        sort_by: companySortColumnToApi(orderBy?.column),
        sort_direction: directionToApi(orderBy?.direction),
        ...query,
      }),
    {pageSize}
  )

export const useSaasCompanyFinishedTableQuery = ({
  assignmentId,
  salesCycleId,
  searchString,
  pageSize,
  orderBy,
}: {
  salesCycleId: number
  assignmentId: number
  searchString: string
  pageSize: number
  orderBy: TOrderBy<TAdminTableColumn>
}) =>
  usePaginatedQuery(
    queryKey.prospectsFinishedTable({
      orderBy,
      searchString,
      assignmentId,
      salesCycleId,
      status: null,
    }),
    query =>
      api.saasCompanyUsers.prospectsAssignmentsProspectsFinishedDetail(assignmentId, {
        search: searchString.trim(),
        sort_by: adminSortColumnToApi(orderBy?.column),
        sort_direction: directionToApi(orderBy?.direction),
        ...query,
      }),
    {pageSize}
  )

export const useProspectsLifetimeDetailQuery = ({
  salesCycleId,
  searchString,
  pageSize,
  orderBy,
  status,
}: {
  salesCycleId: number
  searchString: string
  pageSize: number
  orderBy: TOrderBy<TAdminTableColumn>
  status: AProspectQueryStatusValues | null
}) => {
  return usePaginatedQuery(
    queryKey.prospectsLifetimeTable({orderBy, searchString, salesCycleId, status}),
    query =>
      api.admins.prospectsSalesCyclesLifetimeDetail(salesCycleId, {
        search: searchString.trim(),
        sort_by: adminSortColumnToApi(orderBy?.column),
        sort_direction: directionToApi(orderBy?.direction),
        status: status ?? undefined,
        ...query,
      }),
    {pageSize}
  )
}

export const useProspectActivitiesQuery = ({prospectId, iterationId}: {prospectId?: number; iterationId?: number}) => {
  return useQuery({
    queryKey: queryKey.activityFeedProspect(prospectId, iterationId),
    queryFn: () =>
      api.prospects.activitiesDetail(prospectId ?? Number.NaN, {
        sales_cycle_iteration_id: iterationId,
      }),
    enabled: prospectId != null,
  })
}

export const useDeleteProspectMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id: number) => api.prospects.prospectsDelete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}

const valuesToApi = (values: TEditingProspect): AProspectAttributes => ({
  organization_name: values.organization_name,
  organization_number: values.organization_number,
  segment: values.segment,
  city: values.city,
  country_id: values.country?.id,
  website: values.website,
  organization_phone_number: values.organization_phone_number,

  contact_person_first_name: values.contact_person_first_name,
  contact_person_last_name: values.contact_person_last_name,
  position: values.position,
  phone_number: values.phone_number,
  email: values.email,

  additional_phone_number: values.additional_phone_number,
  notes: values.notes,
  retired: values.retired,
})

export const useUpdateProspectMutation = () => {
  const queryClient = useQueryClient()
  const queryFn = useValueByUserType({
    [AUserTypes.Admin]: api.prospects.prospectsPartialUpdate,
    [AUserTypes.SaasCompanyUser]: api.prospects.saasUpdatePartialUpdate,
    [AUserTypes.SaasSalesPerson]: api.prospects.saasUpdatePartialUpdate,
    [AUserTypes.SalesPerson]: api.prospects.prospectsPartialUpdate,
  })

  return useMutation({
    mutationFn: (prospect: TEditingProspect) => queryFn(prospect.id, {prospect: valuesToApi(prospect)}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
    },
  })
}

export const useUploadProspectsForAssignmentMutation = () => {
  const getPath = useValueByUserType({
    [AUserTypes.Admin]: (assignmentId: number) => `admins/prospects/assignments/${assignmentId}/upload_prospects`,
    [AUserTypes.SaasCompanyUser]: (assignmentId: number) =>
      `saas_company_users/prospects/assignments/${assignmentId}/upload_prospects`,
  })

  return useMutation({
    mutationFn: ({
      assignmentId,
      data,
    }: {
      assignmentId: number
      data: {
        ownership_level: AOwnershipLevels
        source: string
        prospects_file: File
      }
    }) =>
      multipartRequest({
        path: getPath(assignmentId),
        method: "POST",
        data: {prospect_import: data},
      }),
  })
}

export const useUploadSingleProspectMutation = () => {
  const queryClient = useQueryClient()

  const mutationFn = useValueByUserType({
    [AUserTypes.Admin]: api.prospects.prospectsCreate,
    [AUserTypes.SalesPerson]: api.prospects.prospectsCreate,
    [AUserTypes.SaasSalesPerson]: api.prospects.saasCreateCreate,
    [AUserTypes.SaasCompanyUser]: api.prospects.saasCreateCreate,
  })

  return useMutation({
    mutationFn: ({prospect, assignmentId}: {prospect: AProspectAttributes; assignmentId: number}) =>
      mutationFn({assignment_id: assignmentId}, {prospect}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}

export const useSendProspectsForApprovalMutation = () => {
  const queryClient = useQueryClient()

  const mutationFn = useValueByUserType({
    [AUserTypes.Admin]: api.admins.prospectsAssignmentsSendForApprovalCreate,
    [AUserTypes.SalesPerson]: api.salesPeople.prospectsAssignmentsSendForApprovalCreate,
  })

  return useMutation({
    mutationFn: (assignmentId: number) => mutationFn(assignmentId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}

export const useApproveProspectsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (assignmentId: number) => api.companyUsers.prospectsAssignmentsApproveProspectsCreate(assignmentId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}

export const useAutoApproveProspectsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (assignmentId: number) => api.admins.prospectsAssignmentsFinishApprovalCreate(assignmentId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}

export const useSkipNotificationApproveProspectsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (assignmentId: number) => api.admins.prospectsAssignmentsApproveProspectsCreate(assignmentId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}

export const useDisapproveProspectMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      assignmentId,
      prospect,
    }: {
      assignmentId: number
      prospect: {id: number; disapprove_reason: AProspectDisapproveReasons; disapprove_reason_text?: string}
    }) => api.companyUsers.prospectsAssignmentsProspectDisapprovePartialUpdate(assignmentId, {prospect}),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}

export const useRemoveDisapproveProspectMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (prospectId: number) => api.prospects.removeDisapprovePartialUpdate(prospectId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}

export const useSyncWithCrmMutation = () => {
  const queryClient = useQueryClient()
  const queryFn = useValueByUserType({
    [AUserTypes.Admin]: api.admins.prospectsAssignmentsSyncWithCrmCreate,
    [AUserTypes.SaasCompanyUser]: api.saasCompanyUsers.prospectsAssignmentsSyncWithCrmCreate,
  })

  return useMutation({
    mutationFn: queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}

export const useAcceptProspectWarningsMutation = () => {
  const queryClient = useQueryClient()
  const queryFn = useValueByUserType({
    [AUserTypes.Admin]: api.admins.prospectsAssignmentsAcceptWarningsCreate,
  })

  return useMutation({
    mutationFn: ({assignmentId, prospectId}: {assignmentId: number; prospectId: number}) =>
      queryFn(assignmentId, prospectId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: queryKey.prospectsTables})
      queryClient.invalidateQueries({queryKey: queryKey.prospectsSalesCycle()})
    },
  })
}
