import React from "react"

import {AutocompleteFilterContent} from "../../components/AutocompleteFilter.tsx"
import {EnumFilterContent} from "../../components/EnumFilter.tsx"
import {
  getLeadsAutocompleteQuery,
  useLeadsCountryAutocompleteQuery,
  useLeadsSegmentAutocompleteQuery,
} from "../../queries/leads.ts"
import {ALeadAutocompleteFields} from "../../services/types.generated.ts"
import {enumToOptions} from "../../utils/i18n.tsx"
import {FilteringContext} from "./context.ts"
import {TCompanySizeOption} from "./useLeadsFiltering.ts"

const companySizeOptions = enumToOptions("CompanySizeFilter") as TCompanySizeOption[]
export const CompanySizeFilter: React.FC = () => {
  const {companySizes} = FilteringContext.useContext()

  return (
    <EnumFilterContent
      options={companySizeOptions}
      multiple
      value={companySizes.value}
      onChange={companySizes.setValue}
    />
  )
}

export const SegmentFilter: React.FC = () => {
  const {segments} = FilteringContext.useContext()

  return (
    <AutocompleteFilterContent
      useAutocompleteQuery={useLeadsSegmentAutocompleteQuery}
      onChange={segments.setValue}
      value={segments.value}
    />
  )
}

export const CountryFilter: React.FC = () => {
  const {countries} = FilteringContext.useContext()

  return (
    <AutocompleteFilterContent
      useAutocompleteQuery={useLeadsCountryAutocompleteQuery}
      onChange={countries.setValue}
      value={countries.value}
    />
  )
}

export const PositionFilter: React.FC = () => {
  const {positions} = FilteringContext.useContext()
  const autocompleteQuery = getLeadsAutocompleteQuery(ALeadAutocompleteFields.Position)

  return (
    <AutocompleteFilterContent
      useAutocompleteQuery={autocompleteQuery}
      onChange={positions.setValue}
      value={positions.value}
    />
  )
}
