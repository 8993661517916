import React from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {DocumentMagnifyingGlassIcon} from "@heroicons/react/24/outline"

import {GenericErrorAlert} from "../../components/Alert"
import {LayoutBlock} from "../../components/Layout/LayoutBlock"
import {Loading} from "../../components/Loading"
import {useReportsAssignmentQuery, useReportsIterationQuery, useReportsSalesCycleQuery} from "../../queries/reports"
import {useUserSettingsOrLogout} from "../../queries/user.ts"
import {useDocumentTitle, useParam} from "../../utils/hooks"
import {AssignmentsSection} from "./sections/AssignmentsSection"
import {GraphSection} from "./sections/GraphSection"
import {MetricsSection} from "./sections/MetricsSection"
import {NewsAndInsightsSection} from "./sections/NewsAndInsightsSection"
import {PeriodSection} from "./sections/PeriodSection"
import {ProspectsSection} from "./sections/ProspectsSection/ProspectsSection.tsx"
import {useCurrentAssignmentId, useCurrentSelectionState} from "./hooks"
import {buildUrl} from "./utils"

export const Reports: React.FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {user} = useUserSettingsOrLogout()

  useDocumentTitle(t("Reports_DocumentTitle"))

  const assignmentId = useCurrentAssignmentId()

  const {value: currentSelection} = useCurrentSelectionState()
  const {salesCycleId, iterationId} = currentSelection

  const metric = useParam("metric", true)

  React.useEffect(() => {
    if (!metric) {
      navigate(buildUrl(currentSelection), {replace: true})
    }
  }, [currentSelection, metric, navigate, user])

  const salesCycleQuery = useReportsSalesCycleQuery(salesCycleId)
  const iterationQuery = useReportsIterationQuery(salesCycleId, iterationId)
  const assignmentQuery = useReportsAssignmentQuery(salesCycleId, iterationId, assignmentId)

  const refetch = (() => {
    if (salesCycleQuery.error) {
      return salesCycleQuery.refetch
    }
    if (iterationQuery.error) {
      return iterationQuery.refetch
    }
    if (assignmentQuery.error) {
      return assignmentQuery.refetch
    }
  })()

  if (refetch) {
    return (
      <LayoutBlock outerClassName={"py-16"}>
        <GenericErrorAlert retry={refetch} />
      </LayoutBlock>
    )
  }

  if (!salesCycleQuery.data) {
    return <Loading size={"xl"} />
  }

  if (salesCycleQuery.data.sales_cycle_iterations.length === 0) {
    return (
      <LayoutBlock outerClassName={"py-16 grow"} innerClassName={"flex flex-col gap-9"}>
        <div className={"flex grow items-center justify-center"}>
          <div className={"my-14 flex flex-col items-center gap-8"}>
            <DocumentMagnifyingGlassIcon className={"w-12 text-cr-grey-30"} />
            <div className={"flex flex-col items-center gap-4"}>
              <h1 className={"text-2xl font-semibold"}>{t("Reports_NoIterations_Title")}</h1>
              <h2 className={"text-cr-grey-50"}>{t("Reports_NoIterations_Subtitle")}</h2>
              <h2 className={"text-cr-grey-50"}>{t("Reports_NoIterations_SubtitleCTA")}</h2>
            </div>
          </div>
        </div>
      </LayoutBlock>
    )
  }

  return (
    <LayoutBlock outerClassName={"py-16 grow"} innerClassName={"flex flex-col gap-9"}>
      <PeriodSection />
      <AssignmentsSection />
      <MetricsSection />
      <GraphSection />
      <NewsAndInsightsSection />
      <ProspectsSection />
    </LayoutBlock>
  )
}
