import React from "react"
import {useTranslation} from "react-i18next"

import {Accordion} from "../../../../components/Accordion.tsx"
import {AutocompleteFilterContent} from "../../../../components/AutocompleteFilter.tsx"
import {useLeadsCountryAutocompleteQuery} from "../../../../queries/leads.ts"
import {FilteringContext} from "../../../Leads/context.ts"
import {FilterTitle} from "./utilityComponents/FilterTitle.tsx"

export const Country: React.FC = () => {
  const {t} = useTranslation()

  const {countries} = FilteringContext.useContext()

  return (
    <Accordion
      title={<FilterTitle property={countries}>{t("Prospects_ImportModal_LeadsStep_Filter_Country")}</FilterTitle>}
      defaultOpen={false}
      listClassName={"flex flex-col gap-4"}
    >
      <AutocompleteFilterContent
        useAutocompleteQuery={useLeadsCountryAutocompleteQuery}
        onChange={countries.setValue}
        value={countries.value}
        valueOnTop
      />
    </Accordion>
  )
}
