import React from "react"
import {twMerge} from "tailwind-merge"

import {DropdownContext} from "../context.ts"
import {getOptionKey} from "../types.ts"

export const DefaultSelectedValue: React.FC = () => {
  const {multiple, value, placeholder} = DropdownContext.useContext()

  const isEmpty = !value || (multiple && value.length === 0)

  const displayValue = React.useMemo(() => {
    if (!value || isEmpty) {
      return placeholder ?? <>&nbsp;</>
    }

    if (multiple) {
      return (
        <>
          {value.map((opt, index) => (
            <React.Fragment key={getOptionKey(opt)}>
              {index !== 0 && <>, </>}
              {opt.title}
            </React.Fragment>
          ))}
        </>
      )
    }

    return value.title
  }, [value, isEmpty, multiple, placeholder])

  return (
    <div
      className={twMerge(
        "w-full overflow-hidden text-ellipsis whitespace-nowrap text-cr-black",
        isEmpty && "text-cr-grey-65"
      )}
    >
      {displayValue}
    </div>
  )
}
