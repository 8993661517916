import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {GenericErrorAlert} from "../../../../components/Alert.tsx"
import {Badge} from "../../../../components/Badge.tsx"
import Button from "../../../../components/Button.tsx"
import {SearchBoxFilter} from "../../../../components/filters/SearchBoxFilter.tsx"
import {Loading} from "../../../../components/Loading.tsx"
import Pagination from "../../../../components/Pagination.tsx"
import {ProfileImage} from "../../../../components/ProfileImage.tsx"
import {RowCountPickerLS, usePageSize} from "../../../../components/RowCountPicker.tsx"
import {getCurrentLanguage} from "../../../../i18n.ts"
import {useLeadsForAssignmentQuery} from "../../../../queries/leads.ts"
import {getFullName} from "../../../../utils"
import {apiDateToJS} from "../../../../utils/dateArithmetics.ts"
import {commonTransComponents} from "../../../../utils/i18n.tsx"
import {DeleteModal} from "../../../Leads/components/DeleteModal.tsx"
import {DeletingContext, FilteringContext} from "../../../Leads/context.ts"
import {useLeadsFiltering} from "../../../Leads/useLeadsFiltering.ts"
import {DataTable} from "../../ProspectsFromLeads/DataTable.tsx"
import {FilterSidebar} from "../../ProspectsFromLeads/Filters/utilityComponents/FilterSidebar.tsx"
import {AssignmentContext} from "../context.ts"

const paginationKey = "add prospects from leads"

export const LeadsStep: React.FC<{onClose: () => void}> = ({onClose}) => {
  const {t} = useTranslation()

  const leadsFiltering = FilteringContext.useProviderValue(useLeadsFiltering())
  const {searchString, clearRows, isFiltersOpen, allFilters} = leadsFiltering

  const {
    value: {assignment, iteration, salesCycle},
  } = AssignmentContext.useContextOrDie()

  if (assignment == null || iteration == null) {
    throw new Error("Error: assignment or iteration not set")
  }

  const {data, isFetching, error, refetch, pagination} = useLeadsForAssignmentQuery({
    assignmentId: assignment.id,
    pageSize: usePageSize(paginationKey, 25),
    ...leadsFiltering,
  })

  const deleteContextValue = DeletingContext.useProviderValue(null)

  if (!data?.data?.meta?.pagination) {
    return <Loading containerClassName={"my-10"} size={"xl"} delayShow={false} />
  }

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  const assignmentSalesPerson = "sales_person" in assignment ? assignment.sales_person : null

  return (
    <FilteringContext.Provider value={leadsFiltering}>
      <DeletingContext.Provider value={deleteContextValue}>
        <div className={"flex min-h-full grow flex-col gap-10"}>
          <div className={"flex flex-col gap-4"}>
            <div className={"flex items-center justify-between gap-8"}>
              <h1 className={"text-3xl font-semibold text-cr-blue"}>{t("Prospects_ImportModal_LeadsStep_Title")}</h1>
              <div className={"flex items-center gap-4"}>
                <Badge color={"light-blue"} className={"font-semibold"}>
                  <Trans
                    i18nKey={"Prospects_ImportModal_LeadsStep_Badge"}
                    components={commonTransComponents}
                    values={{added: data.data.meta.added_prospects_count, total: assignment.prospects_mandatory_count}}
                  />
                </Badge>

                <Button onClick={onClose} variant={"outlined"}>
                  {t("Prospects_ImportModal_LeadsStep_Done")}
                </Button>
              </div>
            </div>

            <div className={"flex items-center gap-4"}>
              <ProfileImage
                src={assignmentSalesPerson?.profile_picture_thumbnail_url ?? undefined}
                alt={getFullName(assignmentSalesPerson)}
                className={"size-10"}
              />
              <span>
                {[
                  <span key={"name"} className={"font-bold"}>
                    {getFullName(assignmentSalesPerson) ||
                      ("package_name" in assignment ? assignment.package_name : null)}
                  </span>,
                  salesCycle.company?.name,
                  salesCycle.name,
                  apiDateToJS(iteration.start_date).toLocaleString(getCurrentLanguage(), {
                    month: "long",
                    year: "numeric",
                  }),
                ]
                  .filter(Boolean)
                  .reduce<React.ReactNode[]>((acc, value) => {
                    if (acc.length === 0) {
                      return [value]
                    }
                    return [...acc, " - ", value]
                  }, [])
                  .map((value, i) => (
                    <React.Fragment key={i}>{value}</React.Fragment>
                  ))}
              </span>
            </div>
          </div>

          <div className={"flex grow flex-col gap-4"}>
            <SearchBoxFilter
              property={searchString}
              borderClassName={twMerge("shrink-0 transition-all", isFiltersOpen ? "w-96" : "w-60")}
            />

            <div className={"relative flex min-h-96 grow items-stretch gap-4 transition-all"}>
              <div className={"absolute bottom-0 left-0 top-0 shrink-0"}>
                <FilterSidebar />
              </div>
              <div className={twMerge("shrink-0 transition-all", isFiltersOpen ? "w-96" : "w-10")} />
              <div className={twMerge("flex min-w-0 grow flex-col gap-4 transition-all")}>
                {allFilters.isActive && data.data.leads.length > 0 && (
                  <>
                    <DataTable
                      total={data.data.meta.pagination.total_count ?? 0}
                      data={data.data.leads}
                      isLoading={isFetching}
                    />
                    <RowCountPickerLS
                      defaultValue={25}
                      total={pagination.total}
                      autoHide
                      paginationKey={paginationKey}
                      openUp
                    />
                    <Pagination {...pagination} autoHide />
                  </>
                )}
                {allFilters.isActive &&
                  data.data.leads.length === 0 &&
                  (isFetching || !allFilters.isActiveDebounced ? (
                    <Loading size={"xl"} delayShow={false} />
                  ) : (
                    <NoFilterMatch />
                  ))}
                {!allFilters.isActive && <NoFilter />}
              </div>
            </div>
          </div>
          <DeleteModal onDelete={clearRows} />
        </div>
      </DeletingContext.Provider>
    </FilteringContext.Provider>
  )
}

export const NoFilterMatch: React.FC = () => {
  const {t} = useTranslation()

  const {allFilters} = FilteringContext.useContext()

  return (
    <div className={"flex grow items-center justify-center rounded-lg border border-cr-blue-light p-10"}>
      <div className={"flex animate-delay-show flex-col items-center gap-8"}>
        <div className={"relative"}>
          <MagnifyingGlassIcon className={"size-32 stroke-1 text-cr-blue-mid"} />
          <Badge color={"red"} className={"absolute right-7 top-1 px-2.5 py-0.5 text-lg"}>
            0
          </Badge>
        </div>
        <h2 className={"text-lg font-bold"}>{t("Prospects_ImportModal_LeadsStep_NoResults_Title")}</h2>
        <div>
          <Trans i18nKey={"Prospects_ImportModal_LeadsStep_NoResults_Text"} components={commonTransComponents} />
        </div>
        <div>
          <Button onClick={allFilters.clear}>{t("Prospects_ImportModal_LeadsStep_NoResults_ResetButton")}</Button>
        </div>
      </div>
    </div>
  )
}

export const NoFilter: React.FC = () => {
  const {t} = useTranslation()

  return (
    <div className={"flex grow items-center justify-center rounded-lg border border-cr-blue-light p-10"}>
      <div className={"flex flex-col items-center gap-8"}>
        <MagnifyingGlassIcon className={"size-32 stroke-1 text-cr-blue-mid"} />
        <h2 className={"text-lg"}>{t("Prospects_ImportModal_LeadsStep_NoFilter")}</h2>
      </div>
    </div>
  )
}
