import React from "react"
import {twMerge} from "tailwind-merge"

import {combineRef} from "../../../utils/ref.ts"
import {DropdownContext} from "./context.ts"
import {TDropdownOptionProps} from "./types.ts"

export const MainButtonWrapper = React.forwardRef<HTMLElement>((_, ref) => {
  const {
    disabled,
    setIsOpen,
    mainButtonRef,
    components: {MainButton},
    floating: {refs, getReferenceProps},
  } = DropdownContext.useContext()

  const wrapperRef = React.useRef<HTMLDivElement | null>(null)

  const handleKeyDown = React.useCallback<React.KeyboardEventHandler>(
    e => {
      if (![mainButtonRef.current, wrapperRef.current].includes(e.target as any)) {
        return
      }

      if (["Enter", "Space"].includes(e.code)) {
        e.preventDefault()
        setIsOpen(v => !v)
      }

      if (["ArrowUp", "ArrowDown"].includes(e.code)) {
        e.preventDefault()
        setIsOpen(true)
      }
    },
    [mainButtonRef, setIsOpen]
  )

  return (
    <div
      className={twMerge("cursor-default justify-stretch outline-none", disabled && "cursor-not-allowed")}
      ref={combineRef(wrapperRef, refs.setReference)}
      {...getReferenceProps()}
      onKeyDown={handleKeyDown}
    >
      <MainButton ref={ref} />
    </div>
  )
})

export const OptionWrapper: React.FC<TDropdownOptionProps> = ({option, index}) => {
  const {
    activeOptionIndex,
    setActiveOptionIndex,
    isOpen,
    components: {Option},
    floating: {transitionStatus},
  } = DropdownContext.useContext()

  const ref = React.useRef<HTMLDivElement>(null)
  const hoveredRef = React.useRef<boolean>(false)

  const isActive = activeOptionIndex === index

  const handleActivate = React.useCallback(() => {
    setActiveOptionIndex(index)
  }, [index, setActiveOptionIndex])

  const handleMouseEnter = React.useCallback(() => {
    if (hoveredRef.current) {
      return
    }

    handleActivate()
    hoveredRef.current = true
  }, [handleActivate])
  const handleMouseLeave = React.useCallback(() => {
    hoveredRef.current = false
  }, [])

  React.useLayoutEffect(() => {
    if (!isActive || transitionStatus.status !== "open") {
      return
    }

    ref.current?.scrollIntoView({block: "nearest", behavior: "smooth"})
  }, [isActive, isOpen, transitionStatus.status])

  return (
    <div
      className={"select-none outline-none"}
      tabIndex={-1}
      onMouseMove={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={ref}
    >
      <Option option={option} index={index} />
    </div>
  )
}
