import React from "react"
import {Outlet} from "react-router"

import {Loading} from "../../components/Loading.tsx"
import {useRerouteDefault} from "../../utils/hooks"

export const AdminIndex: React.FC = () => {
  useRerouteDefault("/admin/sellings")

  return (
    <React.Suspense fallback={<Loading size={"xl"} />}>
      <Outlet />
    </React.Suspense>
  )
}
