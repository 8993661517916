import React, {RefObject} from "react"
import {twMerge} from "tailwind-merge"

import {DropdownContext} from "../context.ts"

export const DefaultMainButton = React.forwardRef<HTMLElement>((_, ref) => {
  const {
    isOpen,
    name,
    disabled,
    readOnly,
    hasError,
    setIsOpen,
    clearable,
    classNames: {mainButton: className},
    components: {SelectedValue, Caret, ClearButton},
  } = DropdownContext.useContext()

  return (
    <div
      onClick={() => !disabled && setIsOpen(v => !v)}
      className={twMerge(
        "input-border flex w-full items-center gap-2 px-3 py-2 outline-none",
        hasError && "input-border-error",
        disabled && "input-border-disabled pointer-events-none contrast-[0.9]",
        readOnly && "input-border-readonly",
        className
      )}
      ref={ref as RefObject<HTMLDivElement>}
      tabIndex={0}
      role={"listbox"}
      data-open-state={isOpen ? "open" : "closed"}
      data-testid={name ? `dropdown-button-${name}` : undefined}
    >
      <div className={"relative grow overflow-hidden text-left"}>
        <SelectedValue />
      </div>
      {clearable && (
        <div className={"shrink-0"}>
          <ClearButton />
        </div>
      )}
      <div className={"shrink-0"}>
        <Caret />
      </div>
    </div>
  )
})
