import React, {useEffect} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Outlet, useNavigate} from "react-router"
import {toast} from "react-toastify"
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/20/solid"
import {zodResolver} from "@hookform/resolvers/zod"
import {useQueryClient} from "@tanstack/react-query"

import {Alert} from "../../components/Alert"
import Button, {ButtonForm} from "../../components/Button"
import {Link} from "../../components/Link"
import {Loading} from "../../components/Loading"
import {useSalesCycleQuery} from "../../queries/salesCycles"
import {queryKey} from "../../services"
import {useDocumentTitle, useNumParam, useRerouteDefault} from "../../utils/hooks"
import {useCurrentStep, usePreviousStep} from "../../utils/steps"
import {setFormErrorsFromAxios} from "../../utils/validation"
import Layout from "../SalesForm/Layout"
import {TSalesFormData} from "../SalesForm/types"
import {useFinalizeMutation, useSaveStepMutation} from "./api"
import {FinalizeModal} from "./FinalizeModal"
import {Step} from "./Step"
import {EStepStatus} from "./types"
import {getStepStatus, makeLink, mergedValidationSchema, salesCycleToValues, steps, useSaveOnStepChange} from "./utils"

const SalesStrategy: React.FC = () => {
  const {t} = useTranslation()

  const salesCycleId = useNumParam("salesCycleId")
  const {data, isPending, error} = useSalesCycleQuery(salesCycleId)

  const navigate = useNavigate()
  const canUpdate = data?.permissions?.["update?"]
  useEffect(() => {
    if (canUpdate === false) {
      toast.warning(t("SalesStrategy_This sales plan was already finished and can no longer be edited."))
      navigate("/")
    }
  }, [t, canUpdate, navigate])

  const formData = React.useMemo(() => {
    return data && salesCycleToValues(data)
  }, [data])

  if (isPending) {
    return <Loading size={"xl"} />
  }

  if (error || !formData) {
    return (
      <Alert title={t("SalesStrategy_There was an error loading your sales strategy.")} variant={"error"}>
        {t("T_Try refreshing the page or contact support if the problem persists.")}
      </Alert>
    )
  }

  return <SalesStrategyLoaded formData={formData} />
}

const SalesStrategyLoaded: React.FC<{formData: TSalesFormData}> = ({formData}) => {
  const queryClient = useQueryClient()

  const {t} = useTranslation()

  const [isSavingDraft, setIsSavingDraft] = React.useState(false)
  const [isFinalizeModalVisible, setIsFinalizeModalVisible] = React.useState(false)

  const {step: currentStep, stepIndex: currentStepIndex} = useCurrentStep(steps)
  const {step: previousStep} = usePreviousStep(steps) ?? {}

  useDocumentTitle(currentStep.name)

  const salesCycleId = useNumParam("salesCycleId")
  const finalizeMutation = useFinalizeMutation()
  const navigate = useNavigate()

  const methods = useForm<TSalesFormData>({
    mode: "onTouched",
    resolver: zodResolver(mergedValidationSchema),
  })
  const {reset, setValue, setError} = methods

  const saveStepMutation = useSaveStepMutation()

  // This causes a rerender on every value change.
  // In case of performance issues in the form, start your investigation here.
  const values = methods.watch()

  React.useEffect(() => {
    reset(formData, {keepTouched: true, keepDirty: true, keepIsSubmitted: true})
  }, [formData, reset])

  useRerouteDefault("/sales-form")
  const {error, isPending: isSavingStep} = useSaveOnStepChange(values)

  const isLastStep = currentStepIndex === steps.length - 1

  const handleSend = async () => {
    try {
      await finalizeMutation.mutateAsync({step: currentStep, values, salesCycleId})

      await queryClient.refetchQueries({queryKey: queryKey.userSettings})
      navigate(`/sales-strategy-completed`)
    } catch (e) {
      setFormErrorsFromAxios(e, setError)
    }
  }

  const validate = () => {
    currentStep.fields.forEach(field => {
      if (values[field] instanceof FileList || values[field] instanceof File) {
        // React hook form cannot set a File value and ends up clearing the field instead
        return
      }

      setValue(field, values[field], {shouldValidate: true, shouldTouch: true})
    })

    return currentStep.validationSchema.safeParse(values).success
  }

  const handleNextStep = () => {
    if (!validate()) {
      return
    }

    navigate(makeLink(steps[currentStepIndex + 1].href, salesCycleId))
  }

  const handleSaveDraft = async () => {
    if (!validate()) {
      return
    }

    setIsSavingDraft(true)

    await saveStepMutation.mutateAsync({step: currentStep, values, salesCycleId})

    setIsSavingDraft(false)

    navigate("/")
  }

  const handleFinishClick = async () => {
    if (!mergedValidationSchema.safeParse(values).success) {
      return
    }

    await saveStepMutation.mutateAsync({step: currentStep, values, salesCycleId})

    setIsFinalizeModalVisible(true)
  }

  return (
    <Layout
      heading={t("SalesStrategy_Strategy information")}
      paragraph={t(
        "SalesStrategy_Describe your company value and what is special about your business. This will help your sales talent to understand your uniqueness and sell it to your potential customers. The better information we receive from you here the better conversion rates."
      )}
    >
      <FormProvider {...methods}>
        <form
          className={"mx-auto grid max-w-screen-xl grid-cols-4 py-12"}
          onSubmit={methods.handleSubmit(handleFinishClick)}
        >
          <nav aria-label={t("T_Progress")} className={"px-6"}>
            <ol className={"overflow-hidden"}>
              {steps.map(step => (
                <Step
                  key={step.name}
                  step={step}
                  stepStatus={
                    isSavingStep && previousStep?.name === step.name
                      ? EStepStatus.loading
                      : getStepStatus({
                          step,
                          currentStepIndex,
                          touchedFields: methods.formState.touchedFields,
                          values,
                          isSent: methods.formState.isSubmitted,
                        })
                  }
                />
              ))}
            </ol>
          </nav>
          <div className={"col-span-3 pr-4"}>
            <div className={"card text-center"}>
              {!!error && <Alert title={t("T_An error has occurred while saving this step.")} variant={"error"} />}
              <React.Suspense fallback={<Loading size={"xl"} />}>
                <Outlet />
              </React.Suspense>

              <div className={"flex items-center justify-between pt-4"}>
                {currentStepIndex > 0 ? (
                  <Link
                    to={makeLink(steps[currentStepIndex - 1].href, salesCycleId)}
                    variant={"neutral"}
                    flipUnderline
                    className={"inline-flex items-center gap-4 font-semibold"}
                  >
                    <ArrowLeftIcon className={"mr-1 h-5 w-5"} />
                    {t("T_Previous")}
                  </Link>
                ) : (
                  <div></div>
                )}
                {isLastStep ? (
                  <div className={"flex items-center gap-4 whitespace-nowrap"}>
                    <Link
                      onClick={handleSaveDraft}
                      variant={"neutral"}
                      className={"relative font-semibold"}
                      loading={isSavingDraft}
                      disabled={isSavingDraft}
                      flipUnderline
                    >
                      {t("T_Save as a draft")}
                    </Link>
                    <ButtonForm>{t("T_Finish")}</ButtonForm>
                  </div>
                ) : (
                  <div>
                    <Button onClick={handleNextStep} iconRight={<ArrowRightIcon />}>
                      {t("T_Next")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className={"mr-3 mt-4 text-right"}>
              {!isLastStep && ( // TODO add check...
                <Link
                  to={makeLink(steps[currentStepIndex + 1].href, salesCycleId)}
                  className={"text-sm text-cr-grey-50 transition-colors hover:text-cr-grey-80"}
                >
                  <u>{t("T_Skip this step")}</u>
                </Link>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
      <FinalizeModal
        isOpen={isFinalizeModalVisible}
        onClose={() => setIsFinalizeModalVisible(false)}
        onSubmit={handleSend}
      />
    </Layout>
  )
}

export default SalesStrategy
