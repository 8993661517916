import {TModalProps} from "../../../../components/Modal.tsx"

export enum EFeature {
  XLS,
  Leads,
  Single,
}

export enum EStep {
  Initial,
  XLS,
  Leads,
  Single,
  FinishedXLS,
  FinishedSingle,
}

export const featureToStep = {
  [EFeature.XLS]: EStep.XLS,
  [EFeature.Leads]: EStep.Leads,
  [EFeature.Single]: EStep.Single,
} as const satisfies {[feature in EFeature]: EStep}

export const modalPropsByStep = {
  [EStep.Initial]: {size: "lg"},
  [EStep.XLS]: {size: "lg"},
  [EStep.Single]: {size: "xl"},
  [EStep.Leads]: {size: "xxl", disableCloseButton: true},
  [EStep.FinishedXLS]: {size: "sm"},
  [EStep.FinishedSingle]: {size: "sm"},
} satisfies {[step in EStep]: Partial<TModalProps>}
