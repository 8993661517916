import React from "react"
import {createBrowserRouter, RouterProvider} from "react-router"

import {LoginGate, LogoutGate, UserTypeCrossroads} from "./components/authGates"
import Layout from "./components/Layout"
import {AdminIndex} from "./routes/Admin/index.tsx"
import MatchingDetails from "./routes/Admin/Matching/Details/index.tsx"
import Matching, {MatchingIndex} from "./routes/Admin/Matching/index.tsx"
import Sellings, {SellingsIndex} from "./routes/Admin/Sellings/index.tsx"
import {CompanyPreview} from "./routes/Company/CompanyPreview.tsx"
import {CompanyProfile} from "./routes/Company/CompanyProfile.tsx"
import {CompanyTransactions} from "./routes/Company/CompanyTransactions.tsx"
import {Company} from "./routes/Company/index.tsx"
import {MyCompanyGate} from "./routes/Company/MyCompanyGate.tsx"
import {stepCompanyProfile, stepCompanyTransactions} from "./routes/Company/stepsConfig.ts"
import {CookiesPage} from "./routes/Cookies/CookiesPage.tsx"
import {CreateSalesCycle} from "./routes/CreateSalesCycle/CreateSalesCycle.tsx"
import {DashboardCompany} from "./routes/DashboardCompany/DashboardCompany.tsx"
import {WelcomeScreen} from "./routes/DashboardCompany/WelcomeScreen/WelcomeScreen.tsx"
import {DashboardSaaS} from "./routes/DashboardSaaS/DashboardSaaS.tsx"
import DashboardSalesperson from "./routes/DashboardSalesperson/index.tsx"
import {Flags} from "./routes/Flags.tsx"
import {PasswordResetRequest} from "./routes/ForgottenPassword/PasswordResetRequest.tsx"
import {PasswordResetVerification} from "./routes/ForgottenPassword/PasswordResetVerification.tsx"
import {Deleted} from "./routes/GDPR/Deleted.tsx"
import {GDPR} from "./routes/GDPR/index.tsx"
import {HowItWorks} from "./routes/HowItWorks/index.tsx"
import Landing from "./routes/Landing"
import {Leads} from "./routes/Leads/index.tsx"
import {LogIn} from "./routes/LogIn.tsx"
import {AdminProspects} from "./routes/Prospects/Admin/index.tsx"
import {CompanyProspects} from "./routes/Prospects/Company/index.tsx"
import {SaasCompanyProspects} from "./routes/Prospects/SaasCompany/index.tsx"
import {SaasSalespersonProspects} from "./routes/Prospects/SaasSalesperson"
import {SalespersonProspects} from "./routes/Prospects/Salesperson/index.tsx"
import {AdminReports} from "./routes/Reports/AdminReports.tsx"
import {Reports} from "./routes/Reports/index.tsx"
import {SaaSUserManagement} from "./routes/SaaSUserManagement/SaaSUserManagement.tsx"
import {SalesForm} from "./routes/SalesForm/index.tsx"
import {Salesperson} from "./routes/Salesperson/index.tsx"
import {MySalespersonGate} from "./routes/Salesperson/MySalespersonGate.tsx"
import {SalespersonLearning} from "./routes/Salesperson/SalespersonLearning.tsx"
import {SalespersonPreview} from "./routes/Salesperson/SalespersonPreview.tsx"
import {AdditionalInformation} from "./routes/SalesStrategy/AdditionalInformation.tsx"
import Benefits from "./routes/SalesStrategy/Benefits.tsx"
import Completed from "./routes/SalesStrategy/Completed.tsx"
import ElevatorPitch from "./routes/SalesStrategy/ElevatorPitch.tsx"
import SalesStrategy from "./routes/SalesStrategy/index.tsx"
import MarketPresence from "./routes/SalesStrategy/MarketPresence.tsx"
import References from "./routes/SalesStrategy/References.tsx"
import {
  stepAdditionalInformation,
  stepBenefits,
  stepElevatorPitch,
  stepMarketPresence,
  stepReferences,
  stepUniqueness,
  stepWeb,
} from "./routes/SalesStrategy/stepsConfig.ts"
import Uniqueness from "./routes/SalesStrategy/Uniqueness.tsx"
import Web from "./routes/SalesStrategy/Web.tsx"
import {SellingDetails as SellingDetailsCompanyAndAdmin} from "./routes/SellingDetails/CompanyAndAdmin/SellingDetails.tsx"
import {SellingDetails as SellingDetailsSalesperson} from "./routes/SellingDetails/Salesperson/SellingDetails.tsx"
import {DetailsTab} from "./routes/SellingDetails/shared/tabs/DetailsTab.tsx"
import {NewsTab} from "./routes/SellingDetails/shared/tabs/NewsTab.tsx"
import {SignUpPick} from "./routes/SignUp"
import {AccountCreatePage as CompanyAccountCreatePage} from "./routes/SignUp/Company/AccountCreatePage.tsx"
import {EmailFormPage as CompanyEmailFormPage} from "./routes/SignUp/Company/EmailFormPage.tsx"
import {AccountCreatePage as SalespersonAccountCreatePage} from "./routes/SignUp/Salesperson/AccountCreatePage.tsx"
import {EmailFormPage as SalespersonEmailFormPage} from "./routes/SignUp/Salesperson/EmailFormPage.tsx"
import {AUserTypes} from "./services/types.generated"
import {RouterError} from "./RouterError"

const router = createBrowserRouter([
  {path: "/flags", element: <LoginGate Component={Flags} />},
  {
    path: "/",
    element: <Layout />,
    errorElement: <RouterError />,
    children: [
      {
        index: true,
        element: <Landing />,
      },
      {
        path: "cookies",
        Component: CookiesPage,
      },
      {
        path: "log-in",
        element: <LogoutGate Component={LogIn} />,
      },
      {
        path: "sign-up",
        children: [
          {
            index: true,
            element: <LogoutGate Component={SignUpPick} />,
          },
          {
            path: "company",
            element: <LogoutGate Component={CompanyEmailFormPage} />,
          },
          {
            path: "sales",
            element: <LogoutGate Component={SalespersonEmailFormPage} />,
          },
        ],
      },
      {
        path: "verification",
        children: [
          {
            path: "company",
            element: <LogoutGate Component={CompanyAccountCreatePage} />,
          },
          {
            path: "sales",
            element: <LogoutGate Component={SalespersonAccountCreatePage} />,
          },
        ],
      },
      {
        path: "forgotten-password",
        children: [
          {
            index: true,
            element: <PasswordResetRequest />,
          },
          {
            path: "verification",
            element: <PasswordResetVerification />,
          },
        ],
      },
      {
        path: "privacy-request",
        children: [
          {
            index: true,
            Component: GDPR,
          },
          {path: "deleted", Component: Deleted},
        ],
      },
      {
        path: "create-selling/:salesCycleId/:page?/:orderId?",
        element: <LoginGate Component={CreateSalesCycle} userTypes={[AUserTypes.CompanyUser]} />,
      },
      {
        path: "dashboard/:tab?",
        element: (
          <UserTypeCrossroads
            signpost={{
              [AUserTypes.CompanyUser]: DashboardCompany,
              [AUserTypes.SalesPerson]: DashboardSalesperson,
              [AUserTypes.SaasCompanyUser]: DashboardSaaS,
              [AUserTypes.SaasSalesPerson]: DashboardSaaS,
            }}
          />
        ),
      },
      {path: "welcome", element: <LoginGate Component={WelcomeScreen} userTypes={[AUserTypes.CompanyUser]} />},
      {
        path: "how-it-works",
        element: <LoginGate Component={HowItWorks} userTypes={[AUserTypes.CompanyUser]} />,
      },
      {
        path: "prospects/:salesCycleId",
        element: <LoginGate Component={CompanyProspects} userTypes={[AUserTypes.CompanyUser]} />,
      },
      {
        path: "leads",
        element: <LoginGate Component={Leads} userTypes={[AUserTypes.Admin, AUserTypes.SalesPerson]} />,
      },
      {
        path: "sellings/:salesCycleId",
        element: (
          <UserTypeCrossroads
            signpost={{
              [AUserTypes.CompanyUser]: SellingDetailsCompanyAndAdmin,
              [AUserTypes.SaasCompanyUser]: SellingDetailsCompanyAndAdmin,
              [AUserTypes.SaasSalesPerson]: SellingDetailsSalesperson,
              [AUserTypes.SalesPerson]: SellingDetailsSalesperson,
              [AUserTypes.Admin]: SellingDetailsCompanyAndAdmin,
            }}
          />
        ),
        children: [
          {
            path: "news",
            element: (
              <LoginGate
                Component={NewsTab}
                userTypes={[
                  AUserTypes.CompanyUser,
                  AUserTypes.SaasCompanyUser,
                  AUserTypes.SaasSalesPerson,
                  AUserTypes.SalesPerson,
                  AUserTypes.Admin,
                ]}
              />
            ),
          },
          {
            path: "reports/:metric?",
            element: (
              <LoginGate
                Component={Reports}
                userTypes={[AUserTypes.CompanyUser, AUserTypes.SaasCompanyUser, AUserTypes.Admin]}
              />
            ),
          },
          {
            path: "prospects",
            element: (
              <UserTypeCrossroads
                signpost={{
                  [AUserTypes.CompanyUser]: CompanyProspects,
                  [AUserTypes.Admin]: AdminProspects,
                  [AUserTypes.SaasCompanyUser]: SaasCompanyProspects,
                  [AUserTypes.SaasSalesPerson]: SaasSalespersonProspects,
                  [AUserTypes.SalesPerson]: SalespersonProspects,
                }}
              />
            ),
          },
          {
            path: "details",
            element: (
              <LoginGate
                Component={DetailsTab}
                userTypes={[AUserTypes.CompanyUser, AUserTypes.Admin, AUserTypes.SalesPerson]}
              />
            ),
          },
        ],
      },
      {
        path: "sales-form/:salesCycleId?",
        element: <LoginGate Component={SalesForm} userTypes={[AUserTypes.CompanyUser]} />,
      },
      {
        path: "sales-strategy-completed",
        element: <LoginGate Component={Completed} userTypes={[AUserTypes.CompanyUser]} />,
      },
      {
        path: "sales-strategy/:salesCycleId",
        element: <LoginGate Component={SalesStrategy} userTypes={[AUserTypes.CompanyUser]} />,
        children: [
          {
            path: stepWeb.href,
            element: <Web />,
          },
          {
            path: stepElevatorPitch.href,
            element: <ElevatorPitch />,
          },
          {
            path: stepBenefits.href,
            element: <Benefits />,
          },
          {
            path: stepUniqueness.href,
            element: <Uniqueness />,
          },
          {
            path: stepReferences.href,
            element: <References />,
          },
          {
            path: stepMarketPresence.href,
            element: <MarketPresence />,
          },
          {
            path: stepAdditionalInformation.href,
            element: <AdditionalInformation />,
          },
        ],
      },
      {
        path: "company/:companyId",
        element: <CompanyPreview />,
      },
      {
        path: "company/:companyId?",
        element: <MyCompanyGate Component={Company} redirectToCurrentIfUndef />,
        children: [
          {
            path: stepCompanyProfile.href,
            element: <CompanyProfile />,
          },
          {
            path: stepCompanyTransactions.href,
            element: <CompanyTransactions />,
          },
        ],
      },
      {
        path: "salesperson/:salespersonId",
        element: <SalespersonPreview />,
      },
      {
        path: "salesperson/:salespersonId?",
        children: [
          {
            path: "learning-materials",
            element: <MySalespersonGate Component={SalespersonLearning} redirectToCurrentIfUndef />,
          },
          {
            path: ":tab?",
            element: <MySalespersonGate Component={Salesperson} redirectToCurrentIfUndef />,
          },
        ],
      },
      {
        path: "admin",
        element: <LoginGate Component={AdminIndex} userTypes={[AUserTypes.Admin]} />,
        children: [
          {
            path: "prospects/:salesCycleId",
            Component: AdminProspects,
          },
          {
            path: "matching",
            Component: MatchingIndex,
            children: [
              {
                path: ":tab",
                children: [
                  {
                    index: true,
                    element: <Matching />,
                  },
                  {
                    path: "detail/:id",
                    children: [
                      {
                        index: true,
                        element: <MatchingDetails />,
                      },
                      {
                        path: "assignment/:assignmentId",
                        element: <MatchingDetails />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "sellings",
            Component: SellingsIndex,
            children: [
              {
                path: ":tab",
                children: [
                  {
                    index: true,
                    element: <Sellings />,
                  },
                ],
              },
            ],
          },
          {
            path: "sellings/:salesCycleId/reports/:metric?",
            Component: AdminReports,
          },
        ],
      },
      {
        path: "user-management/:tab?",
        element: <LoginGate Component={SaaSUserManagement} userTypes={[AUserTypes.SaasCompanyUser]} />,
      },
    ],
  },
])

const Router = () => <RouterProvider router={router} />

export default Router
