import React from "react"

import {DropdownContext} from "../context.ts"
import {TDropdownOptionProps} from "../types.ts"

export const DefaultOptionValue: React.FC<TDropdownOptionProps> = ({option}) => {
  const {
    classNames: {optionValue: className},
  } = DropdownContext.useContext()

  return <div className={className}>{option.title}</div>
}
