import React from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {PlusCircleIcon} from "@heroicons/react/24/outline"

import {GenericErrorAlert} from "../../components/Alert.tsx"
import {Badge} from "../../components/Badge.tsx"
import {ButtonLink} from "../../components/Button.tsx"
import {LayoutBlock} from "../../components/Layout/LayoutBlock.tsx"
import {Loading} from "../../components/Loading.tsx"
import {SalespeopleShowcase} from "../../components/SalespeopleShowcase.tsx"
import {TabItem, TabsContainer} from "../../components/Tabs.tsx"
import {useCompanyDashboardQuery} from "../../queries/companyDashboard.ts"
import {useUserSettingsOrLogout} from "../../queries/user.ts"
import {useDocumentTitle, useParam} from "../../utils/hooks.tsx"
import {getCreateSellingLink} from "../CreateSalesCycle/utils.ts"
import {ActiveTab} from "./tabs/ActiveTab.tsx"
import {DraftTab} from "./tabs/DraftTab.tsx"
import {FinishedTab} from "./tabs/FinishedTab.tsx"
import {PausedTab} from "./tabs/PausedTab.tsx"
import {WelcomeScreen} from "./WelcomeScreen/WelcomeScreen.tsx"
import {ETab, getDesiredTab} from "./utils.ts"

export const DashboardCompany: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("Dashboard_Company_DocumentTitle"))

  const {welcome_screen} = useUserSettingsOrLogout()
  const navigate = useNavigate()

  const {data, isSuccess, error, refetch} = useCompanyDashboardQuery()

  const activeTab = useParam("tab", true) as ETab
  const desiredTab = getDesiredTab(
    activeTab,
    data?.active_sales_cycles_count,
    data?.drafted_sales_cycles_count,
    data?.paused_sales_cycles_count,
    data?.finished_sales_cycles_count
  )

  React.useEffect(() => {
    if (!isSuccess) {
      return
    }

    navigate(`/dashboard/${desiredTab ?? ""}`, {replace: true})
  }, [desiredTab, navigate, isSuccess])

  const handleChangeTab = React.useCallback(
    (tab: ETab) => () => {
      navigate(`/dashboard/${tab}`)
    },
    [navigate]
  )

  if (welcome_screen) {
    return <WelcomeScreen />
  }

  return (
    <LayoutBlock outerClassName={"py-16 grow bg-cr-blue-super-light"} innerClassName={"flex flex-col gap-14"}>
      <h1 className={"text-4xl font-bold text-cr-blue"}>{t("Dashboard_Company_Title")}</h1>
      <div className={"flex gap-8 border-b border-b-cr-blue-light"}>
        <TabsContainer className={"border-none"}>
          {!!data?.active_sales_cycles_count && (
            <TabItem isActive={activeTab === ETab.ACTIVE} onClick={handleChangeTab(ETab.ACTIVE)}>
              <h2>{t("Dashboard_Company_Active_Title")}</h2>
            </TabItem>
          )}
          {!!data?.drafted_sales_cycles_count && (
            <TabItem isActive={activeTab === ETab.DRAFT} onClick={handleChangeTab(ETab.DRAFT)} className={"gap-2"}>
              <h2>{t("Dashboard_Company_Draft_Title")}</h2>
              <Badge color={"red"} className={"px-3 py-1"}>
                {data.drafted_sales_cycles_count}
              </Badge>
            </TabItem>
          )}
          {!!data?.paused_sales_cycles_count && (
            <TabItem isActive={activeTab === ETab.PAUSED} onClick={handleChangeTab(ETab.PAUSED)} className={"gap-2"}>
              <h2>{t("Dashboard_Company_Paused_Title")}</h2>
            </TabItem>
          )}
          {!!data?.finished_sales_cycles_count && (
            <TabItem
              disabled={!data?.finished_sales_cycles_count}
              isActive={activeTab === ETab.FINISHED}
              onClick={handleChangeTab(ETab.FINISHED)}
            >
              <h2>{t("Dashboard_Company_Finished_Title")}</h2>
            </TabItem>
          )}
        </TabsContainer>
        <ButtonLink
          to={getCreateSellingLink()}
          variant={"outlined"}
          className={"self-center"}
          buttonClassName={"bg-transparent whitespace-nowrap"}
          iconLeft={<PlusCircleIcon className={"size-5"} />}
          size={"sm"}
        >
          {t("Dashboard_Company_CreationButton")}
        </ButtonLink>
      </div>
      {error ? (
        <GenericErrorAlert retry={refetch} />
      ) : data ? (
        <>
          {activeTab === ETab.ACTIVE && <ActiveTab />}
          {activeTab === ETab.DRAFT && <DraftTab />}
          {activeTab === ETab.PAUSED && <PausedTab />}
          {activeTab === ETab.FINISHED && <FinishedTab />}
          <SalespeopleShowcase salesPeople={data?.sales_people} />
        </>
      ) : (
        <Loading size={"xl"} fullSpace />
      )}
    </LayoutBlock>
  )
}
