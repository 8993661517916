import React from "react"
import {useTranslation} from "react-i18next"

import {Accordion} from "../../../../components/Accordion.tsx"
import {InputFilter} from "../../../../components/filters/InputFilter.tsx"
import {FilteringContext} from "../../../Leads/context.ts"
import {FilterTitle} from "./utilityComponents/FilterTitle.tsx"

export const Name: React.FC = () => {
  const {t} = useTranslation()

  const {name} = FilteringContext.useContext()

  return (
    <Accordion
      title={<FilterTitle property={name}>{t("Prospects_ImportModal_LeadsStep_Filter_Name")}</FilterTitle>}
      defaultOpen={false}
    >
      <InputFilter property={name} />
    </Accordion>
  )
}
