import React from "react"
import {Controller, FieldPath, FieldValues} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {PlusIcon, XMarkIcon} from "@heroicons/react/20/solid"
import {twMerge} from "tailwind-merge"

import {TIconComponent} from "../../types.ts"
import {EMPTY_ARRAY} from "../../utils"
import {pickProps} from "../../utils/types.ts"
import {TBaseSharedProps, TConnectedField, useGetFieldVisibleError} from "../fields/components.tsx"
import {FieldLabel, pickFieldLabelProps, TFieldLabelProps} from "../fields/FieldLabel.tsx"
import {InputAction} from "../InputAction.tsx"
import {UnstyledLink} from "../Link.tsx"

export type TMultiStringTokenRendererProps = {
  value: string
  onRemove: () => void
  readOnly?: boolean
  disabled?: boolean
  hasError?: boolean
}

export type TMultiStringInputRendererProps = {
  ref?: React.ForwardedRef<any>
  onAdd: (value: string) => void | false
  checkValueValid?: (newValue: string) => boolean
  placeholder?: string
  readOnly?: boolean
  disabled?: boolean
  hasError?: boolean
}

export type TMultiStringRendererProps = {
  Token: React.ComponentType<TMultiStringTokenRendererProps>
  Input: React.ComponentType<TMultiStringInputRendererProps>
  tokenProps: (tokenValue: string) => TMultiStringTokenRendererProps
  inputProps: TMultiStringInputRendererProps
  value: readonly string[]
}

export type TMultiStringInputSharedProps = TBaseSharedProps & {
  placeholder?: string
  Token?: React.ComponentType<TMultiStringTokenRendererProps>
  Input?: React.ComponentType<TMultiStringInputRendererProps>
  Renderer?: React.ComponentType<TMultiStringRendererProps>
}

export type TMultiStringInputConnectedProps<
  T extends FieldValues,
  N extends FieldPath<T>,
> = TMultiStringInputSharedProps & TConnectedField<T, N>

export type TMultiStringInputBaseProps = TMultiStringInputSharedProps & {
  value?: string[]
  onChange?: (newValue: string[]) => void
}

const sanitizeValue = (value: string) => value.trim()

export const MultiStringInputBase = React.forwardRef<HTMLInputElement, TMultiStringInputBaseProps>(
  (
    {
      Token = DefaultToken,
      Input = DefaultInput as NonNullable<TMultiStringInputBaseProps["Input"]>,
      Renderer = DefaultRenderer,
      value = EMPTY_ARRAY as readonly string[],
      placeholder,
      onChange,
      readOnly,
      disabled,
      hasError,
    },
    ref
  ) => {
    const checkValueValid = React.useCallback(
      (newValue: string) => {
        return !value.includes(sanitizeValue(newValue))
      },
      [value]
    )

    const handleAdd = React.useCallback(
      (newValue: string) => {
        const sanitizedValue = sanitizeValue(newValue)

        if (!checkValueValid(sanitizedValue) || sanitizedValue === "" || readOnly || disabled) {
          return
        }

        onChange?.([...value, sanitizedValue])
      },
      [checkValueValid, disabled, onChange, readOnly, value]
    )

    const handleRemove = React.useCallback(
      (valueToRemove: string) => {
        const valueIndex = value.indexOf(valueToRemove)

        if (readOnly || disabled || valueIndex === -1) {
          return
        }

        onChange?.(value.toSpliced(valueIndex, 1))
      },
      [disabled, onChange, readOnly, value]
    )

    const tokenProps = React.useCallback(
      (tokenValue: string) => ({
        value: tokenValue,
        onRemove: () => handleRemove(tokenValue),
        readOnly,
        disabled,
        hasError,
      }),
      [disabled, handleRemove, hasError, readOnly]
    )

    const inputProps = React.useMemo(
      () => ({
        ref,
        onAdd: handleAdd,
        checkValueValid: checkValueValid,
        placeholder,
        readOnly,
        disabled,
        hasError,
      }),
      [checkValueValid, disabled, handleAdd, hasError, placeholder, readOnly, ref]
    )

    return <Renderer Token={Token} Input={Input} tokenProps={tokenProps} inputProps={inputProps} value={value} />
  }
)

export function MultiStringInputConnected<T extends FieldValues, N extends FieldPath<T>>({
  name,
  options: _options,
  ...props
}: TMultiStringInputConnectedProps<T, N>) {
  const hasError = !!useGetFieldVisibleError(name)

  return (
    <Controller
      name={name}
      render={({field}) => {
        return <MultiStringInputBase {...field} {...props} hasError={hasError} />
      }}
    />
  )
}

export function MultiStringInputField<T extends FieldValues, N extends FieldPath<T>>(
  props: TFieldLabelProps & TMultiStringInputConnectedProps<T, N>
) {
  const fieldLabelProps = pickFieldLabelProps(props)
  const inputProps = pickMultiStringInputConnectedProps(props)

  return (
    <FieldLabel {...fieldLabelProps}>
      <MultiStringInputConnected {...inputProps} />
    </FieldLabel>
  )
}

function pickMultiStringInputConnectedProps<T extends FieldValues, N extends FieldPath<T>>(
  props: React.ComponentProps<typeof MultiStringInputField<T, N>>
) {
  return pickProps<TMultiStringInputConnectedProps<T, N>>({
    name: true,
    hasError: true,
    options: true,
    placeholder: true,
    readOnly: true,
    disabled: true,
    Input: true,
    Token: true,
    Renderer: true,
  })(props)
}

export const DefaultToken: React.FC<TMultiStringTokenRendererProps> = ({value, onRemove, disabled, readOnly}) => {
  return (
    <UnstyledLink
      className={twMerge(
        "group/token flex items-center gap-1",
        "select-none rounded px-2 py-1 text-sm",
        "bg-cr-grey-5 transition-all",
        !disabled && "hover:bg-cr-grey-15",
        !readOnly && !disabled && "cursor-pointer"
      )}
      onClick={onRemove}
      title={value}
      data-testid={"token"}
    >
      <span className={"line-clamp-1 break-all"}>{value}</span>
      {!disabled && (
        <span className={"shrink-0"}>
          <XMarkIcon className={"size-4 group-hover/token:text-cr-red"} />
        </span>
      )}
    </UnstyledLink>
  )
}

export const DefaultInput = React.forwardRef<HTMLInputElement, TMultiStringInputRendererProps>(
  ({placeholder, onAdd, checkValueValid = () => true, hasError, readOnly, disabled}, ref) => {
    return (
      <InputAction
        ref={ref}
        onAction={onAdd}
        checkValueValid={checkValueValid}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        hasError={hasError}
        Icon={AddButton}
      />
    )
  }
)
export const AddButton: TIconComponent = ({className}) => {
  const {t} = useTranslation()

  return <PlusIcon className={className} title={t("T_Add")} />
}

export const DefaultRenderer: React.FC<TMultiStringRendererProps> = ({Token, Input, tokenProps, inputProps, value}) => {
  return (
    <div className={"flex flex-col gap-4"}>
      {value.length > 0 && (
        <div className={"flex flex-wrap gap-1"}>
          {value.map(tokenValue => (
            <Token key={tokenValue} {...tokenProps(tokenValue)} />
          ))}
        </div>
      )}

      <div>
        <Input {...inputProps} />
      </div>
    </div>
  )
}

export const TagsOnBottomRenderer: React.FC<TMultiStringRendererProps> = ({
  Token,
  Input,
  tokenProps,
  inputProps,
  value,
}) => {
  return (
    <div className={"flex flex-col gap-4"}>
      <div>
        <Input {...inputProps} />
      </div>

      {value.length > 0 && (
        <div className={"flex flex-wrap gap-1"}>
          {value.map(tokenValue => (
            <Token key={tokenValue} {...tokenProps(tokenValue)} />
          ))}
        </div>
      )}
    </div>
  )
}
