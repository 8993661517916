import React from "react"

import {TIconComponent} from "../types.ts"
import {InputBase, TInputSharedProps} from "./fields/Input"
import Button, {IconButton} from "./Button.tsx"

type TInputActionProps = TInputSharedProps & {
  onAction: (value: string) => void
  initialValue?: string
  disableClearOnSubmit?: boolean
  allowSubmitEmpty?: boolean
  checkValueValid?: (newValue: string) => boolean
} & ({actionLabel: string; Icon?: never} | {actionLabel?: never; Icon: TIconComponent})

export const InputAction = React.forwardRef<HTMLInputElement, TInputActionProps>(
  (
    {
      actionLabel,
      onAction,
      disableClearOnSubmit,
      allowSubmitEmpty,
      initialValue = "",
      checkValueValid = () => true,
      Icon,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = React.useState(initialValue)
    const wrapperRef = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    const isValueValid = React.useMemo(() => {
      return checkValueValid(value)
    }, [checkValueValid, value])

    const isSubmitDisabled =
      !isValueValid || props.disabled || props.readOnly || (value.trim() === "" && !allowSubmitEmpty)

    const handleSubmit = React.useCallback(() => {
      const trimmed = value.trim()

      if (isSubmitDisabled) {
        return
      }

      onAction(trimmed)
      !disableClearOnSubmit && setValue("")

      wrapperRef.current?.querySelector("input")?.focus()
    }, [disableClearOnSubmit, isSubmitDisabled, onAction, value])

    const handleKeyDown = React.useCallback<React.KeyboardEventHandler<HTMLInputElement>>(
      e => {
        if (e.key !== "Enter") {
          return
        }

        e.preventDefault() // Pressing enter when an input is focused submits the form by default
        handleSubmit()
      },
      [handleSubmit]
    )

    return (
      <div className={"contents"} ref={wrapperRef}>
        <InputBase
          {...props}
          ref={ref}
          value={value}
          onChange={e => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
          hasError={!isValueValid || props.hasError}
        >
          {Icon ? (
            <IconButton
              className={"mr-1 text-cr-blue disabled:text-cr-grey-50"}
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
              type={"button"}
            >
              <Icon className={"size-5"} />
            </IconButton>
          ) : (
            <Button
              disabled={isSubmitDisabled}
              variant={"outlined"}
              size={"xs"}
              className={"m-2 w-auto px-2 py-1"}
              onClick={handleSubmit}
              type={"button"}
            >
              {actionLabel}
            </Button>
          )}
        </InputBase>
      </div>
    )
  }
)
