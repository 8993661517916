import React from "react"
import {useTranslation} from "react-i18next"
import {PlusIcon} from "@heroicons/react/20/solid"

import {InputAction} from "../../../InputAction.tsx"
import {DropdownContext} from "../context.ts"

export const DefaultAddCustom: React.FC = () => {
  const {t} = useTranslation()

  const {addCustom, onChange, isCustomValueValid, disabled, readOnly} = DropdownContext.useContext()

  if (!addCustom) {
    return null
  }

  return (
    <div className={"bg-cr-white p-2"}>
      <InputAction
        onAction={onChange}
        checkValueValid={isCustomValueValid}
        placeholder={t("Dropdown_AddCustomPlaceholder")}
        Icon={PlusIcon}
        disabled={disabled}
        readOnly={readOnly}
      />
    </div>
  )
}
