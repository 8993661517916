import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {XMarkIcon} from "@heroicons/react/20/solid"
import {InformationCircleIcon} from "@heroicons/react/24/solid"
import {twMerge} from "tailwind-merge"

import {commonTransComponents} from "../utils/i18n.tsx"
import {IconButton} from "./Button.tsx"
import {Link} from "./Link.tsx"

export const PPBanner: React.FC = () => {
  const {t} = useTranslation()

  const [isOpen, setIsOpen] = React.useState(true)

  return (
    <div
      className={twMerge(
        "fixed bottom-4 left-8 isolate z-20 flex items-start gap-3 p-4",
        "sm:max-w-[440px] max-sm:bottom-0 max-sm:left-0 max-sm:right-0 max-sm:rounded-b-none",
        "cr-shadow rounded-lg bg-cr-blue-light transition-all",
        isOpen ? "opacity-100" : "pointer-events-none -translate-x-[150%] opacity-0 duration-500"
      )}
    >
      <InformationCircleIcon className={"size-6 shrink-0 text-cr-blue"} />
      <div className={"flex flex-col gap-2"}>
        <h2 className={"font-bold text-cr-blue"}>{t("PPBanner_Title")}</h2>
        <div className={"text-sm"}>
          <Trans
            i18nKey={"PPBanner_Text"}
            components={{
              ppLink: <Link to={t("Url_PrivacyPolicyNew")} flipUnderline target={"_blank"} />,
              ...commonTransComponents,
            }}
          />
        </div>
      </div>
      <IconButton onClick={() => setIsOpen(false)} className={"-mt-2 shrink-0"}>
        <XMarkIcon className={"size-7"} />
      </IconButton>
    </div>
  )
}
