import React from "react"
import {useTranslation} from "react-i18next"

import {EnumFilterContent} from "../../../components/EnumFilter.tsx"
import {InputBase} from "../../../components/fields/Input.tsx"
import {TOption} from "../../../components/formElements/Dropdown/types.ts"
import {enumToOptions} from "../../../utils/i18n.tsx"
import {FilteringContext} from "../context.ts"
import {EStatus} from "../fakeTypes.ts"

export const SearchBox: React.FC = () => {
  const {t} = useTranslation()

  const {searchString} = FilteringContext.useContext()

  const handleChangeSearchString = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      searchString.setValue(e.target.value)
    },
    [searchString]
  )

  return (
    <InputBase
      value={searchString.value}
      onChange={handleChangeSearchString}
      placeholder={t("SearchPlaceholder")}
      autoFocus
    />
  )
}

const statusFilterOptions = enumToOptions("SaaSUserStatus") as Array<TOption<EStatus>>
export const StatusFilter: React.FC = () => {
  const {status} = FilteringContext.useContext()

  return <EnumFilterContent options={statusFilterOptions} multiple value={status.value} onChange={status.setValue} />
}
