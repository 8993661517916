import React from "react"

import {TFilterProperty} from "../../utils/filters.ts"
import {InputBase, TInputBaseProps} from "../fields/Input.tsx"

export const InputFilter: React.FC<{property: TFilterProperty<string>} & Omit<TInputBaseProps, "property">> = ({
  property,
  ...inputProps
}) => {
  // When there is a lot of filters, setting the value through the property is slow.
  // Therefore, we manage the value locally and then just sync the state

  const [value, setValue] = React.useState(property.value)

  React.useEffect(() => {
    setValue(property.valueDebounced)
  }, [property.valueDebounced])

  // Clearing needs to be immediate
  React.useEffect(() => {
    if (property.value !== "") {
      return
    }
    setValue("")
  }, [property.value])

  const setProperty = property.setValue
  React.useEffect(() => {
    setProperty(value)
  }, [value, setProperty])

  return <InputBase value={value} onChange={e => setValue(e.target.value)} {...inputProps} />
}
