import React, {ChangeEventHandler, useState} from "react"
import {useTranslation} from "react-i18next"
import {useLocation, useNavigate} from "react-router"
import {toast} from "react-toastify"
import {CalendarIcon} from "@heroicons/react/24/solid"
import {useInfiniteQuery, useMutation, useQuery} from "@tanstack/react-query"

import Button, {ButtonLoading} from "../../../../components/Button"
import {InputBase} from "../../../../components/fields/Input"
import {Link} from "../../../../components/Link"
import {Loading} from "../../../../components/Loading"
import {getCurrentLanguage} from "../../../../i18n"
import api, {queryKey} from "../../../../services"
import requestError from "../../../../services/requestError"
import {ASalesCycleIteration} from "../../../../services/types.generated"
import {apiDateToJS} from "../../../../utils/dateArithmetics.ts"
import {useDebouncedValue, useDocumentTitle, useParam, usePromptNavigate} from "../../../../utils/hooks"
import {enumTranslKey} from "../../../../utils/i18n"
import Package from "./Package"
import Person from "./Person"

const PAGE_SIZE = 10

export type TSelected = Record<string, number>

const Details = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("Matching_Matching details"))

  const id = useParam("id")
  const assignmentId = useParam("assignmentId", true)
  const [selected, setSelected] = useState<TSelected>({})
  const [searchString, setSearchString] = useState("")
  const searchStringDebounced = useDebouncedValue(searchString)

  const handleChangeSearchString = React.useCallback<ChangeEventHandler<HTMLInputElement>>(
    e => {
      setSearchString(e.target.value)
    },
    [setSearchString]
  )

  const navigate = useNavigate()
  const promptNavigate = usePromptNavigate(Object.keys(selected).length > 0, true)
  const iterationFromLocation: ASalesCycleIteration | undefined = useLocation().state?.iteration
  const {isPending: isLoadingIteration, data: iteration} = useQuery({
    queryKey: queryKey.salesCycleIteration(id),
    queryFn: async () =>
      (await api.salesCycleIterations.salesCycleIterationsDetail(parseInt(id))).data.sales_cycle_iteration,
    initialData: iterationFromLocation,
  })
  const {
    isPending: isLoadingMatchable,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: queryKey.salesCycleIterationMatchable(id, searchStringDebounced),
    queryFn: ({pageParam: page}) =>
      api.salesCycleIterations.matchableSalesPeopleDetail(parseInt(id), {
        page,
        per_page: PAGE_SIZE,
        search: searchStringDebounced,
      }),
    initialPageParam: 1,
    getNextPageParam: ({
      data: {
        meta: {pagination},
      },
    }) =>
      pagination.total_count > pagination.current_page * pagination.per_page ? pagination.current_page + 1 : undefined,
    getPreviousPageParam: ({
      data: {
        meta: {pagination},
      },
    }) => (pagination.current_page === 1 ? pagination.current_page - 1 : undefined),
  })
  const matchable = data?.pages.map(e => e.data.sales_people ?? []).flat()

  const assignOffersMutation = useMutation({
    mutationFn: async (selected: TSelected) => {
      return Promise.all(
        Object.entries(selected).map(([assignment_id, sales_person_id]) =>
          api.assignmentOffers.assignmentOffersCreate({
            assignment_offer: {assignment_id: parseInt(assignment_id), sales_person_id},
          })
        )
      )
    },
  })
  const handleSave = () => {
    return assignOffersMutation
      .mutateAsync(selected)
      .then(() => {
        toast.success(t("Matching_Matching saved successfully"))
        navigate("../../")
      })
      .catch(requestError)
  }

  if (isLoadingIteration || !iteration) {
    return <Loading size={"xl"} />
  }
  const currentAssignment = assignmentId != null && iteration.assignments.find(a => a.id === parseInt(assignmentId))
  if (assignmentId && !currentAssignment) {
    return <>{t("Matching_Assignment with ID {{assignmentId}} not found", {assignmentId})}</>
  }

  const pendingPeople =
    currentAssignment && !currentAssignment.sales_person
      ? currentAssignment.pending_assignment_offers?.map(e => e.sales_person)
      : []

  return (
    <div className={"mx-auto my-16 max-w-screen-xl px-8"}>
      <div className={"mb-10"}>
        <div className={"block text-center md:flex md:justify-between md:text-left"}>
          <h2 className={"mb-4 text-2xl font-bold text-cr-blue"}>
            <CalendarIcon className={"mr-2 inline-block h-8 w-8 align-top"} />{" "}
            {apiDateToJS(iteration.start_date).toLocaleString(getCurrentLanguage(), {month: "long", year: "numeric"})}
          </h2>
          <div className={"flex flex-row items-baseline gap-8"}>
            <Link flipUnderline onClick={() => promptNavigate(currentAssignment ? "../.." : "..")}>
              {t("Matching_Back to list of sales plans")}
            </Link>
            <ButtonLoading disabled={!Object.keys(selected).length} onClick={handleSave}>
              {t("T_Save changes")}
            </ButtonLoading>
          </div>
        </div>
        <h1 className={"mb-2 text-3xl font-bold"}>{iteration.sales_cycle.name}</h1>
        <div className={"text-cr-grey-50"}>
          {iteration.company_name} | {iteration.sales_cycle.products[0]?.name ?? t("Matching_Missing product")} |{" "}
          {t(enumTranslKey("Segment", iteration.sales_cycle.segment))} | {iteration.sales_cycle.country.name}
        </div>
      </div>
      <div className={"flex gap-8"}>
        <div>
          <h2 className={"mb-2 mr-4 inline-block text-xl font-bold"}>{t("Matching_Packages")}</h2>
          <span className={"font-medium text-cr-blue"}>
            {t("Matching_{{matched}}/{{total}} matched", {
              matched: iteration.assignments.reduce(
                (acc, cur) =>
                  cur.sales_person || cur.pending_assignment_offers.length || selected[cur.id] ? acc + 1 : acc,
                0
              ),
              total: iteration.assignments.length,
            })}
          </span>

          <div className={"card mt-4 px-3 py-0"}>
            {iteration.assignments.map(assignment => (
              <Package
                key={assignment.id}
                selectedPerson={
                  selected[assignment.id] == null ? undefined : matchable?.find(e => e.id === selected[assignment.id])
                }
                assignment={assignment}
                highlighted={assignmentId != null && assignment.id === parseInt(assignmentId)}
                navigateRelative={!!currentAssignment}
              />
            ))}
          </div>
        </div>
        <div className={"grow"}>
          {assignmentId && currentAssignment ? (
            <>
              {(currentAssignment.sales_person || !!pendingPeople.length) && (
                <>
                  <h2 className={"mb-2 inline-block text-xl font-bold"}>
                    {currentAssignment.sales_person
                      ? t("Matching_Confirmed salesperson")
                      : t("Matching_Salesperson waiting for acceptance")}
                  </h2>

                  <div className={"card mb-8 mt-4 w-[48em] px-4 py-0"}>
                    {currentAssignment.sales_person ? (
                      <Person person={currentAssignment.sales_person} startDate={iteration.start_date} />
                    ) : (
                      pendingPeople.map(person => (
                        <Person key={person.id} person={person} startDate={iteration.start_date} />
                      ))
                    )}
                  </div>
                </>
              )}

              {!currentAssignment.sales_person && !pendingPeople.length && (
                <>
                  <div className={"relative mb-2 inline-block w-full"}>
                    <h2 className={"text-xl font-bold"}>{t("Matching_Salespeople available to match")}</h2>
                    <div className={"absolute right-0 top-1/2 -translate-y-1/2"}>
                      <InputBase
                        value={searchString}
                        onChange={handleChangeSearchString}
                        placeholder={t("SearchPlaceholder")}
                      />
                    </div>
                  </div>

                  <div className={"card mt-4 w-[48em] px-4 py-0"}>
                    {isLoadingMatchable || !matchable ? (
                      <Loading size={"xl"} containerClassName={"h-[200px]"} />
                    ) : !matchable.length ? (
                      <div className={"my-4 text-center text-cr-grey-50"}>
                        {searchStringDebounced
                          ? t("Matching_NoSalespeople_Filtered")
                          : t("Matching_NoSalespeople_Total")}
                      </div>
                    ) : (
                      (matchable ?? [])
                        // .filter(e => !pendingPeople.find(({id}) => id === e.id))
                        // .filter(e => e.capacity >= currentAssignment.reaches)
                        .map(e => (
                          <Person
                            key={e.id}
                            person={e}
                            startDate={iteration.start_date}
                            selected={selected[assignmentId] === e.id}
                            onSelect={id =>
                              setSelected(selectedOld => {
                                const selectedNew = {...selectedOld}
                                if (id == null) {
                                  delete selectedNew[assignmentId]
                                } else {
                                  selectedNew[assignmentId] = id
                                }
                                return selectedNew
                              })
                            }
                          />
                        ))
                    )}

                    {hasNextPage && (
                      <div className={"my-4 flex justify-center"}>
                        <Button className={"w-48"} isLoading={isFetchingNextPage} onClick={() => fetchNextPage()}>
                          {t("T_Load more")}
                        </Button>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <div className={"w-[48em]"}>{t("Matching_Select a package")}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Details
