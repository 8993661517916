import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {zodResolver} from "@hookform/resolvers/zod"
import {z} from "zod"

import Button, {ButtonForm} from "../../../../components/Button.tsx"
import {InputField} from "../../../../components/fields/Input.tsx"
import {TextareaField} from "../../../../components/fields/Textarea.tsx"
import {DropdownField} from "../../../../components/formElements/Dropdown/Dropdown.tsx"
import {useUploadSingleProspectMutation} from "../../../../queries/prospects.ts"
import {useCountryOptions} from "../../../../utils/hooks.tsx"
import {enumToOptions} from "../../../../utils/i18n.tsx"
import {requiredFieldMessage, setFormErrorsFromAxios, validateNonemptyString} from "../../../../utils/validation.ts"
import {AssignmentContext} from "../context.ts"

const validationSchema = z.object({
  organization_name: validateNonemptyString(),
  city: z.string(),
  contact_person_first_name: z.string(),
  contact_person_last_name: z.string(),
  position: z.string(),
  segment: z.array(validateNonemptyString()).or(z.undefined()),
  phone_number: z.string(),
  organization_phone_number: z.string(),
  additional_phone_number: z.string(),
  website: z.string(),
  organization_number: z.string(),
  email: z.string(),
  notes: z.string(),
  country_id: z.number({required_error: requiredFieldMessage}).positive(),
})

export const SingleStep: React.FC<{onSuccess: () => void; onClose: () => void}> = ({onClose, onSuccess}) => {
  const {t} = useTranslation()
  const countryOptions = useCountryOptions()
  const {
    value: {assignment},
  } = AssignmentContext.useContextOrDie()

  const methods = useForm<z.infer<typeof validationSchema>>({
    resolver: zodResolver(validationSchema),
  })
  const {getValues, setError} = methods

  const uploadMutation = useUploadSingleProspectMutation()

  const handleSubmit = React.useCallback(async () => {
    const values = getValues()

    if (!assignment) {
      return
    }

    try {
      await uploadMutation.mutateAsync({prospect: values, assignmentId: assignment.id})
      onSuccess()
    } catch (e) {
      setFormErrorsFromAxios(e, setError)
    }
  }, [assignment, getValues, onSuccess, setError, uploadMutation])

  return (
    <FormProvider {...methods}>
      <form className={"flex flex-col gap-10"} onSubmit={methods.handleSubmit(handleSubmit)}>
        <div className={"flex justify-between gap-8"}>
          <h1 className={"text-2xl font-semibold"}>{t("Prospects_ImportModal_SingleStep_Title")}</h1>
        </div>

        <div className={"grid auto-rows-auto grid-cols-2 gap-4 max-md:grid-cols-1"}>
          <h2 className={"col-span-full font-bold"}>{t("Prospects_ImportModal_SingleStep_Section_Organization")}</h2>

          <InputField
            required
            name={"organization_name"}
            label={t("Prospects_ImportModal_SingleStep_Field_OrganizationName")}
            labelClassName={"text-sm font-normal"}
            errorMessageProps={{reserveSpace: false}}
          />
          <InputField
            name={"organization_number"}
            label={t("Prospects_ImportModal_SingleStep_Field_OrganizationNumber")}
            labelClassName={"text-sm font-normal"}
            errorMessageProps={{reserveSpace: false}}
          />
          <InputField
            name={"city"}
            label={t("Prospects_ImportModal_SingleStep_Field_City")}
            labelClassName={"text-sm font-normal"}
            errorMessageProps={{reserveSpace: false}}
          />
          <DropdownField
            required
            name={"country_id"}
            label={<span className={"font-normal"}>{t("Prospects_ImportModal_SingleStep_Field_Country")}</span>}
            options={countryOptions}
            labelClassName={"text-sm font-normal"}
            errorMessageProps={{reserveSpace: false}}
          />
          <InputField
            name={"organization_phone_number"}
            label={t("Prospects_ImportModal_SingleStep_Field_OrganizationPhone")}
            labelClassName={"text-sm font-normal"}
            errorMessageProps={{reserveSpace: false}}
          />
          <InputField
            name={"website"}
            label={t("Prospects_ImportModal_SingleStep_Field_Website")}
            labelClassName={"text-sm font-normal"}
            errorMessageProps={{reserveSpace: false}}
          />
          <DropdownField
            name={"segment"}
            label={t("Prospects_ImportModal_SingleStep_Field_Segment")}
            labelClassName={"text-sm font-normal"}
            errorMessageProps={{reserveSpace: false}}
            options={enumToOptions("Segment")}
            addCustom
            multiple
          />

          <div className={"col-span-full"} />
          <h2 className={"col-span-full font-bold"}>{t("Prospects_ImportModal_SingleStep_Section_ContactPerson")}</h2>

          <InputField
            name={"contact_person_first_name"}
            label={t("Prospects_ImportModal_SingleStep_Field_FirstName")}
            labelClassName={"text-sm font-normal"}
            errorMessageProps={{reserveSpace: false}}
          />
          <InputField
            name={"contact_person_last_name"}
            label={t("Prospects_ImportModal_SingleStep_Field_LastName")}
            labelClassName={"text-sm font-normal"}
            errorMessageProps={{reserveSpace: false}}
          />
          <InputField
            name={"position"}
            label={t("Prospects_ImportModal_SingleStep_Field_Position")}
            labelClassName={"text-sm font-normal"}
            errorMessageProps={{reserveSpace: false}}
          />
          <InputField
            name={"email"}
            label={t("Prospects_ImportModal_SingleStep_Field_Email")}
            labelClassName={"text-sm font-normal"}
            errorMessageProps={{reserveSpace: false}}
          />
          <InputField
            name={"phone_number"}
            label={t("Prospects_ImportModal_SingleStep_Field_Phone")}
            labelClassName={"text-sm font-normal"}
            errorMessageProps={{reserveSpace: false}}
          />
          <InputField
            name={"additional_phone_number"}
            label={t("Prospects_ImportModal_SingleStep_Field_AdditionalPhone")}
            labelClassName={"text-sm font-normal"}
            errorMessageProps={{reserveSpace: false}}
          />

          <div className={"col-span-full"} />
          <h2 className={"col-span-full font-bold"}>{t("Prospects_ImportModal_SingleStep_Section_Note")}</h2>

          <TextareaField
            name={"notes"}
            label={t("Prospects_ImportModal_SingleStep_Field_Note")}
            labelClassName={"text-sm font-normal"}
            containerClassName={"col-span-full"}
            rows={5}
          />
        </div>

        <div className={"flex justify-end gap-4"}>
          <Button variant={"outlined"} color={"gray"} onClick={onClose}>
            {t("T_Cancel")}
          </Button>

          <ButtonForm>{t("Prospects_ImportModal_SingleStep_SubmitButton")}</ButtonForm>
        </div>
      </form>
    </FormProvider>
  )
}
