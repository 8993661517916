import React from "react"
import {useTranslation} from "react-i18next"
import {Outlet} from "react-router"

import {Loading} from "../../../components/Loading"
import Pagination, {usePaginatedQuery} from "../../../components/Pagination"
import {i18n} from "../../../i18n"
import api, {queryKey} from "../../../services"
import {ASalesCycleIterationsListParamsStatusEnum} from "../../../services/types.generated"
import {useDocumentTitle, useParam, useRerouteDefault} from "../../../utils/hooks"
import Table from "./Table"
import Tabs, {TTab} from "./Tabs"

type TMatchingTab = TTab<ASalesCycleIterationsListParamsStatusEnum> & {rowNavigationDisabled?: boolean}

const tabs = [
  {
    name: i18n.t("Matching_Waiting for acceptance"),
    href: ASalesCycleIterationsListParamsStatusEnum.WaitingForAcceptance,
    rowNavigationDisabled: true,
  },
  {name: i18n.t("Matching_Waiting for matching"), href: ASalesCycleIterationsListParamsStatusEnum.WaitingForMatching},
  {name: i18n.t("Matching_Ready to start"), href: ASalesCycleIterationsListParamsStatusEnum.ReadyToStart},
  {name: i18n.t("Matching_Active"), href: ASalesCycleIterationsListParamsStatusEnum.Active},
  {name: i18n.t("Matching_Declined"), href: ASalesCycleIterationsListParamsStatusEnum.Declined},
  {name: i18n.t("Matching_Finished"), href: ASalesCycleIterationsListParamsStatusEnum.Finished},
] satisfies readonly TMatchingTab[]

const useIterationsQuery = (tabCurrentHref: ASalesCycleIterationsListParamsStatusEnum) =>
  usePaginatedQuery(queryKey.salesCycleIterations(tabCurrentHref), paginationQuery =>
    api.salesCycleIterations.salesCycleIterationsList({...paginationQuery, status: tabCurrentHref})
  )

const Matching = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("T_Matching"))

  const tabCurrentHref = useParam("tab") as ASalesCycleIterationsListParamsStatusEnum
  const {isPending, isPlaceholderData, data, pagination} = useIterationsQuery(tabCurrentHref)
  const rows = data?.data.sales_cycle_iterations

  return (
    <div className={"mx-auto my-16 w-full max-w-screen-xl px-8"}>
      <h1 className={"mb-4 text-3xl font-bold"}>{t("Matching_List of sales plans")}</h1>
      <Tabs tabs={tabs} tabCurrentHref={tabCurrentHref} counts={{}} />
      {isPending || !rows ? (
        <Loading containerClassName={"h-[200px]"} />
      ) : (
        <Table
          rows={rows}
          loading={isPlaceholderData}
          rowNavigation={!tabs.find(e => e.href === tabCurrentHref)?.rowNavigationDisabled}
        />
      )}
      <Pagination {...pagination} />
    </div>
  )
}

export const MatchingIndex: React.FC = () => {
  useRerouteDefault("/admin/matching/" + ASalesCycleIterationsListParamsStatusEnum.WaitingForAcceptance)

  return (
    <React.Suspense fallback={<Loading size={"xl"} />}>
      <Outlet />
    </React.Suspense>
  )
}

export default Matching
