import React, {useEffect} from "react"
import {useFormContext} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {z} from "zod"

import {InputField} from "../../../components/fields/Input.tsx"
import {DropdownField} from "../../../components/formElements/Dropdown/Dropdown.tsx"
import {ACountry, AOrder, APaymentMethods, ASubscriptionBillingTypes} from "../../../services/types.generated.ts"
import {useCountries, useCountryOptions} from "../../../utils/hooks.tsx"
import {requiredFieldMessage, validateNativeEnum, validateNonemptyString} from "../../../utils/validation.ts"
import {CheckoutSummary} from "./CheckoutSummary.tsx"

export const validationSchema = (countries: ACountry[]) =>
  z
    .object({
      company_name: validateNonemptyString(),
      country_id: z.number({required_error: requiredFieldMessage}).positive(),
      customer_name: validateNonemptyString(),
      customer_email: validateNonemptyString(),
      city: validateNonemptyString(),
      postal_code: validateNonemptyString(),
      state: z.string().nullish(),
      company_vat_number: z.string().nullish(),
      address: validateNonemptyString(),
      company_number: validateNonemptyString(),
      terms_of_service: z.literal(true, {
        errorMap: () => ({message: requiredFieldMessage}),
      }),
      subscription_billing_type: z.literal(ASubscriptionBillingTypes.Month),
      payment_method: validateNativeEnum(APaymentMethods),
    })
    .refine(
      ({country_id, state}) => {
        const country = countries.find(country => country.id === country_id)
        const isStateVisible = !!country?.states?.length

        return !isStateVisible || !!state?.trim()
      },
      {message: requiredFieldMessage, path: ["state"]}
    )
    .refine(
      ({country_id, company_vat_number}) => {
        const country = countries.find(country => country.id === country_id)

        return !country?.require_vat_number || !!company_vat_number?.trim()
      },
      {message: requiredFieldMessage, path: ["company_vat_number"]}
    )

export type TFormData = z.infer<ReturnType<typeof validationSchema>>

export const CheckoutForm: React.FC<{
  order: AOrder
}> = ({order}) => {
  const {t} = useTranslation()
  const {watch, setValue, clearErrors, trigger} = useFormContext<TFormData>()

  const countries = useCountries()

  const countryId = watch("country_id")
  const country = countries.find(country => country.id === countryId)
  const isStateVisible = !!country?.states?.length
  const isVatIdVisible = !!country?.visible_vat_number

  const countryOptions = useCountryOptions()

  useEffect(() => {
    if (!isStateVisible) {
      setValue("state", undefined)
      clearErrors("state")
    }
  }, [isStateVisible, setValue, clearErrors])

  useEffect(() => {
    if (!isVatIdVisible) {
      setValue("company_vat_number", undefined)
      clearErrors("company_vat_number")
    }
  }, [isVatIdVisible, setValue, clearErrors])

  useEffect(() => {
    trigger(["state", "company_vat_number"])
  }, [countryId, trigger])

  return (
    <div className={"grid grid-cols-1 gap-x-12 gap-y-10 lg:grid-cols-2 lg:gap-y-16"}>
      <div>
        <h1 className={"mb-8 text-2xl font-semibold"}>{t("CreateSalesCycle_Checkout_Billing_Title")}</h1>
        <InputField
          variant={"small"}
          label={t("CreateSalesCycle_Checkout_Billing_CompanyName")}
          name={"company_name"}
        />
        <InputField
          variant={"small"}
          label={t("CreateSalesCycle_Checkout_Billing_CustomerName")}
          name={"customer_name"}
        />
        <InputField
          variant={"small"}
          label={t("CreateSalesCycle_Checkout_Billing_Email")}
          type={"email"}
          name={"customer_email"}
          readOnly
          Icon={false}
        />
        <InputField variant={"small"} label={t("CreateSalesCycle_Checkout_Billing_Address")} name={"address"} />
        <div className={"flex w-full flex-col gap-x-8 md:flex-row"}>
          <InputField
            variant={"small"}
            label={t("CreateSalesCycle_Checkout_Billing_City")}
            containerClassName={"grow"}
            name={"city"}
          />
          <InputField
            variant={"small"}
            label={t("CreateSalesCycle_Checkout_Billing_ZIP")}
            containerClassName={"grow"}
            name={"postal_code"}
          />
        </div>
        <div className={"grid grid-cols-1 gap-x-8 md:grid-cols-2"}>
          <DropdownField
            variant={"small"}
            label={t("CreateSalesCycle_Checkout_Billing_Country")}
            options={countryOptions}
            name={"country_id"}
          />
          {isStateVisible && (
            <DropdownField
              variant={"small"}
              label={t("CreateSalesCycle_Checkout_Billing_State")}
              options={country.states ?? []}
              name={"state"}
            />
          )}
        </div>
        <InputField
          variant={"small"}
          label={t("CreateSalesCycle_Checkout_Billing_CompanyId")}
          name={"company_number"}
        />
        {isVatIdVisible && (
          <InputField
            variant={"small"}
            label={
              <>
                {t("CreateSalesCycle_Checkout_Billing_VatId")}{" "}
                {!country?.require_vat_number && (
                  <span className={"text-xs text-cr-grey-50"}>{t("CreateSalesCycle_Checkout_Billing_Optional")}</span>
                )}
              </>
            }
            name={"company_vat_number"}
          />
        )}
      </div>
      <div>
        <h1 className={"mb-8 text-2xl font-semibold"}>{t("CreateSalesCycle_Checkout_Summary_Title")}</h1>
        <CheckoutSummary order={order} />
        <div className={"mx-8 mt-3 text-xs text-cr-grey-50"}>{t("CreateSalesCycle_Checkout_Disclaimer")}</div>
      </div>
    </div>
  )
}
