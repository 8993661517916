import React from "react"

import {TFilterProperty} from "../../../../../utils/filters.ts"
import {FilterBadge} from "./FilterBadge.tsx"

export function FilterTitle<Property extends Pick<TFilterProperty<unknown>, "activeNum" | "clear" | "isActive">>({
  property,
  children,
}: {
  property: Property
  children: React.ReactNode
}): React.ReactNode {
  return (
    <span className={"flex items-center gap-2"}>
      <span>{children}</span>
      <FilterBadge property={property} />
    </span>
  )
}
